import React from 'react';
import { CmsData } from '../Data/CmsData';
import { Redirect } from 'react-router';

export const Season: React.FC<CmsData> = ({ path }) => {
    if (!path || path.length < 3) {
        return <Redirect to="/" />
    }
    else {
        const node = path[path.length - 2];
        return <Redirect to={node.url} />
    }
}