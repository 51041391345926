import React, { useContext } from "react";

/**
 * Data for a modal to be shown
 */
export interface ModalEntry {
    key?: string;
    title?: string;
    body?: any;
    text?: string;
    button?: string | string[];
    url?: string;
    className?: string;
    /**
     * must explicitly set to false to prevent dismissing.
     */
    allowClose?: boolean;
}
export interface ModalResult {
    result?: any;
    modal: ModalEntry;
}
/**
 * Provides mechanism for showing a ModalEntry, dismissing, or setting the result to be returned
 */
export interface ModalService {
    /**
     * the current showing modal, or undefined if none. 
     */
    current?: ModalEntry;

    /**
     * Return a promise that indicates the result of the modal
     */
    show: (entry: ModalEntry | string) => Promise<ModalResult>;

    /** 
     * Return a promise that indicates true/false
     */
    confirm: (entry: string) => Promise<boolean>;

    /**
     * null or undefined when dismissed; url when link clicked; anything else for result
     */
    setResult: (result: any) => void;


    /**
     * will check current & hide if dismissable.
     */
    dismiss: () => void;
}



/** 
 * ModalServicecontext to be Provided by ModalContext
 * implelmentation details set in ModalContext
 */
export const ModalServiceContext = React.createContext<ModalService>({
    current: undefined,
    show: (a: ModalEntry | string) => new Promise<any>(() => null),
    confirm: (message: string) => new Promise<boolean>(() => false),
    setResult: (result: any) => { },
    dismiss: () => { }
});
/** 
 * to create a modal, 
 * const svc = useModalService();
 * svc.show({
 *  text: 'this is a test of the emergency modal service. this is only a test.';
 * })
 */
export const useModalService = () => useContext<ModalService>(ModalServiceContext);
