import React, { useState } from "react"
import { FormFieldPropTypes } from "../Types";
import ReCAPTCHA from "react-google-recaptcha";

export const Recaptcha2: React.FC<FormFieldPropTypes> = (props) => {
    const [val, setVal] = useState<string | null>(null);
    const { formContext, field } = props;
    return (
        <div className="recaptcha">
            {
                formContext.errors['g-recaptcha-response'] && <p className="err mt-4">{field.settings?.errorMessage}</p>
            }
            <ReCAPTCHA sitekey={props.formContext.form.recaptchaPublicKey} onChange={setVal} />
            <input type="hidden"
                name="g-recaptcha-response"
                value={val || ''}
                ref={props.formContext.registerField(field)}
                placeholder={field.settings?.placeholder}
            />
        </div>
    );
}
/*


        */