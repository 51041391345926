import React from "react"
import { useHistory } from "react-router";
interface SearchFieldPropTypes {
    query: string;
}
export const SearchField: React.FC<SearchFieldPropTypes> = (props) => {
    const history = useHistory();
    return (
        <div className="form-group text search-field">
            <label>Search</label>
            <input type="text"
                name="search"
                value={props.query}
                placeholder="Search Term"
                autoFocus={true}
                onChange={(event) => history.push(`/search/?query=${event.target.value}`)}
            />
        </div>
    );
}