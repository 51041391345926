
import React from "react";
import { useHistory } from "react-router";
import { TessInventory, TessProduction } from "Tess/Data/TessInventory";


import { usePopup } from "Util/Popup/PopupContext";
import { ticketsBaseUrl } from "../TicketsApp";
import { PerfSelectPopup } from "./PerfSelectPopup";

const getProductionNumber = (baseUrl: string, href: string) => {
    const regex = new RegExp(`${baseUrl}/([0-9]*)$`, "gi");
    const matches = regex.exec(href);
    return (matches && matches.length > 1)
        ? parseInt(matches[1])
        : undefined;
}

/** 
 * this will get a mouseClick handler to trigger the PerfSelectPopup 
 * 
 * */
export const usePerfSelectPopup = (inventory: TessInventory | undefined | null, baseUrl: string = ticketsBaseUrl, production?: TessProduction, title?: string) => {
    const popupService = usePopup();

    return (e: React.MouseEvent) => {
        if (inventory) {
            const a = e.currentTarget as HTMLAnchorElement;

            if (!production) {
                const prodNumber = getProductionNumber(baseUrl, a?.href);
                if (prodNumber) {
                    production = inventory?.findProduction(prodNumber);
                }
            }
            if (production) {
                popupService.show(
                    <PerfSelectPopup
                        prod={production}
                        target={e.currentTarget}
                        baseUrl={baseUrl}
                        title={title}

                    />
                )
                e.preventDefault();
            }
        }
    }
}