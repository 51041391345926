import React from "react"
import { FormFieldPropTypes } from "../Types";

export const HiddenField: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    return (
        <input type="hidden"
            name={field.id}
            defaultValue={props.formContext.formResponses[field.id]}
            ref={props.formContext.registerField(field)}
            placeholder={field.settings?.placeholder}
        />
    );
}