import React from "react"
import { FormFieldPropTypes } from "../Types";
import { RawHtml } from "Util/RawHtml";

export const Text: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    return (

        <div>
            <h4>{field.settings?.caption}</h4>
            <RawHtml>{field.settings?.bodyText}</RawHtml>
        </div>

    );
}