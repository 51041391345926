import { useMediaQuery as mediaQuery } from 'react-responsive'

export class Responsive {
    static XXL: number = 1366;
    static XL: number = 1200;
    static LG: number = 1040;
    static MD: number = 768;
    static SM: number = 480;
    static XS: number = 240;

    static All: number[] = [Responsive.XXL, Responsive.XL, Responsive.LG, Responsive.MD, Responsive.SM, Responsive.XS];
    static Large: number[] = [Responsive.XXL, Responsive.XL, Responsive.LG];
    static NotLarge: number[] = [Responsive.MD, Responsive.SM, Responsive.XS];

    static isScreenXL = () => mediaQuery({ minWidth: Responsive.XL });
    static isScreenLG = () => mediaQuery({ minWidth: Responsive.LG });
    static isScreenMD = () => mediaQuery({ minWidth: Responsive.MD });
    static isScreenSM = () => mediaQuery({ minWidth: Responsive.SM });
}
