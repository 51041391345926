import React, { useState, useRef } from "react";

import { CmsData } from "Cms/Data/CmsData";
import { GridSection } from "../../../Util/Grid/GridSection";
import { GridRow } from "../../../Util/Grid/GridRow";
import Rte from "../Rte/Rte";
import { GridArea } from "../../../Util/Grid/GridArea";


import './production.scss';
import { Accordion, AccordionEntryData } from "../../../Util/Accordion/Accordion";
import { CmsGrid } from "../Grid/CmsGrid";
import { Cast } from "./Cast";
import { motion } from "framer-motion";

export default function ProductionBody(props: CmsData) {
    return (
        <>
            <GridSection>
                <GridRow className="row-standard">
                    <GridArea>
                        <div>
                            <Rte text={props.properties.shortDescription} />
                            <Rte text={props.properties.credits} />
                            <ReadMore {...props.properties} />
                        </div>
                    </GridArea>

                </GridRow>
            </GridSection>
            {props.properties.additionalContent && <CmsGrid grid={props.properties.additionalContent} />}
            {props.properties.castMembers &&
                <GridSection>
                    <GridRow className="row-standard">
                        <GridArea>
                            <div>
                                <Cast cast={props.properties.castMembers} />
                            </div>
                        </GridArea>
                    </GridRow>
                </GridSection>
            }
        </>
    );
}
const variants = {
    closed: { height: 0 },
    open: { height: 'auto' }
};

const ReadMore = ({ readMore, contentAdvisories }: { readMore: string, contentAdvisories: AccordionEntryData[] }) => {
    let [isOpen, setIsOpen] = useState(false);
    let ref = useRef<HTMLButtonElement>(null);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    if (readMore || (contentAdvisories && contentAdvisories.length)) {
        const buttonText = isOpen ? <span>Read Less <i className="fifth-icon-up-arrow-small" /></span> : <span>Read More <i className="fifth-icon-down-arrow-small" /></span>
        return (
            <div>
                <motion.div className="collapsable" variants={variants} animate={isOpen ? 'open' : 'closed'}>
                    <div>
                        {readMore && <Rte text={readMore} />}
                        {contentAdvisories && contentAdvisories.length && (
                            <div>
                                <h3>Content Advisories</h3>
                                <Accordion entries={contentAdvisories} />
                            </div>
                        )}
                    </div>
                </motion.div>
                <button onClick={toggle} className="btn" ref={ref}>{buttonText}</button>
            </div>
        );
    }
    return null;
}