import React, { useState } from "react"
import { PerfSelect } from "./PerfSelect";
import { useWindowEventListener } from "Util/useEventListener";
import { Responsive } from "Util/Responsive";
import { animateScroll } from "react-scroll";
import { TessPerformance, TessProduction } from "Tess/Data/TessInventory";
import { ticketsBaseUrl } from "../TicketsApp";

interface PropTypes {
    baseUrl?: string;
    prod: TessProduction;
    perf?: TessPerformance;
    target: Element;
    title?: string;

}
export const PerfSelectPopup: React.FC<PropTypes> = ({ baseUrl = ticketsBaseUrl, title, ...props }) => {

    const { prod, perf, target } = props;
    const [style, setStyle] = useState<React.CSSProperties>({})
    const calculatePosition = () => {
        const isXs = window.innerWidth < Responsive.SM;
        if (isXs) {
            // let the styles from css place it. 
            return;
        }


        const targetWidth = 30 * 16;

        if (target) {
            const buttonRect = target.getBoundingClientRect();
            console.log(buttonRect);
            const newStyles = {
                left: '',
                top: buttonRect.top,
                right: document.getElementById('mainFrame')!.clientWidth - buttonRect.right,
                width: `${targetWidth}px`
            }

            if (newStyles.top + 600 > window.innerHeight) {
                animateScroll.scrollTo(window.scrollY + newStyles.top + 600 - window.innerHeight);
                newStyles.top -= newStyles.top + 600 - window.innerHeight;
            }
            if (newStyles.left !== style.left || newStyles.top !== style.top || newStyles.right !== style.right) {
                setStyle(newStyles);
            }

        }
    }

    calculatePosition();
    useWindowEventListener('resize', calculatePosition);

    return (
        <PerfSelect prod={prod} perf={perf} baseUrl={baseUrl} style={style} title={title} />
    );
}