import React from "react";

import './half-slash.scss';
import { Figure } from "../Figure/Figure";
import { AppRawHtml } from "App/AppRawHtml";
import { AppLink } from "App/AppLink";
import { FigureData } from "Util/FigureData";
import { labels } from "Util/Labels";


interface PropTypes {
    imagePosition: string;
    title: string;
    text?: string;
    image: FigureData;
    url?: string;
    buttonText?: string;
    background: string;
}

export default function HalfSlashHero(props: PropTypes) {
    let { imagePosition, background, title = '', text, url, buttonText, image } = props;
    buttonText = buttonText || labels.getString('global.defaultLinkButtonText') || 'More...';
    return (
        <div className={`half-slash hero ${imagePosition} bg-${background.toLowerCase()}`}>
            <div className="half-slash-image">
                <Figure {...image}>
                    <div className="half-slash-text">
                        <div>
                            <div><AppRawHtml tagName="h2">{title}</AppRawHtml></div>
                            <AppRawHtml className="text text-inner">{text}</AppRawHtml>
                            {
                                url ? <div><AppLink className="btn" to={url}>{buttonText}</AppLink></div> : ""
                            }
                        </div>
                    </div>
                </Figure>
            </div>
            <AppRawHtml className="text text-below">{text}</AppRawHtml>
        </div>
    );
}
