import { EventClickArg, EventInput } from "@fullcalendar/react";
import { TessPerformance } from "Tess/Data/TessInventory";

import { labels } from "Util/Labels";
import { ticketsBaseUrl } from "../TicketsApp";


export interface CalendarPropTypes {
    perfs?: TessPerformance[];
    eventClick?: (ev: EventClickArg) => void;
    baseUrl?: string;
}
const sortPerfs = (him: TessPerformance, her: TessPerformance) => her.date.getDate() - him.date.getDate();

interface CalendarKeyword {
    icon: string,
    title: string,
    description: string
}

const calendarKeywords: Record<string, CalendarKeyword> = labels.getObject("performance.calendarKeywords");
export const getCalendarKeyword = (keyword: string) => calendarKeywords[keyword] as CalendarKeyword;

export function getCalendarEvents(perfs: TessPerformance[] | undefined, includeTitle: boolean, baseUrl: string = ticketsBaseUrl): EventInput[] {
    return perfs ? perfs.sort(sortPerfs).map(perf => ({
        url: `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}${perf.perfNo}`,
        title: includeTitle ? perf.production?.title : '',
        start: perf.date,
        extendedProps: perf,
    })) : [];
}
export const getValidRange = (events: EventInput[]) => {
    if (events.length > 0) {
        let first = events[0].start as Date;
        let last = events[events.length - 1].start as Date;
        return {
            start: new Date(first.getFullYear(), first.getMonth(), 1),
            end: (new Date(last.getFullYear(), last.getMonth() + 1, 32)).setDate(0)
        }
    }
    return {}
}