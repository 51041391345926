import React, { useEffect, useState } from "react";

import { FigureData } from '../Figure/Figure';
import './image-gallery.scss';
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import ReactPlayer from "react-player";
import { useWindowEventListener } from "Util/useEventListener";
import { AppRawHtml } from "App/AppRawHtml";

/** ImageGallery component. Ideas from https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?file=/src/Example.tsx:123-156 */

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

interface ImageGalleryProps {
    title: string;
    images: FigureData[]
}

export default function ImageGallery(props: ImageGalleryProps) {
    const { images } = props;
    const [[page, direction], setPage] = useState([0, 0]);
    const [videoPlaying, setVideoPlaying] = useState(false);

    const [opacityOverride, setOpacityOverride] = useState<number | undefined>();
    const ref = React.useRef<HTMLDivElement>(null);

    const parent = ref.current;

    const [sizes, setSizes] = useState({ parentWidth: 0, windowHeight: 0, gallery: {} });

    const recalculateSizes = () => {
        const windowHeight = window.innerHeight - 80;
        if (parent) {
            const parentWidth = parent.clientWidth;
            const aspectRatio = 624 / 1000;
            if (parentWidth !== sizes.parentWidth || windowHeight !== sizes.windowHeight) {
                const width = Math.min(parentWidth || 0, windowHeight / aspectRatio, 1000);
                const height = width * aspectRatio
                setSizes({ parentWidth, windowHeight, gallery: { height } });
            }
        }

    }
    setTimeout(() => recalculateSizes(), 500);

    useWindowEventListener('resize', recalculateSizes);
    useEffect(recalculateSizes, [parent, sizes.parentWidth, sizes.windowHeight])
    if (!images.length) {
        return null;
    }

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(0, images.length, page);
    const image = images[imageIndex];
    const youTubeLink = image.link && image.link.indexOf("youtube.com") !== -1 ? image.link : undefined;

    return (
        <div className="image-gallery" style={sizes.gallery} ref={ref}>

            <AnimatePresence initial={false} custom={direction}>
                <div style={{ opacity: opacityOverride || 1 }}>
                    <motion.img key={page}
                        src={image.src} alt={image.title}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        onAnimationStart={() => setVideoPlaying(false)}
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDrag={(e, { offset }) => {
                            console.log(offset.x + '/' + sizes.parentWidth);
                            if (typeof offset.x == 'number' && offset.x) {
                                setOpacityOverride(1 - Math.abs(offset.x) / sizes.parentWidth);
                            }
                        }}
                        onDragEnd={(e, { offset, velocity }) => {
                            setOpacityOverride(1)
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                setPage([imageIndex + 1, 1]);
                            } else if (swipe > swipeConfidenceThreshold) {
                                setPage([imageIndex - 1, -1]);
                            }
                        }}
                    />
                    {
                        youTubeLink &&
                        <ReactPlayer
                            url={youTubeLink}
                            width="100%"
                            height="100%"
                            style={{ position: 'absolute', top: 0, left: 0, zIndex: 5, display: videoPlaying ? 'block' : 'none' }}
                            playing={videoPlaying}

                            controls
                        />

                    }
                    {
                        youTubeLink &&
                        <motion.button className="play-button"
                            title={youTubeLink}
                            key={`play_${page}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: youTubeLink ? 0.8 : 0, transition: { duration: .2 } }}
                            exit={{
                                opacity: 0, transition: { duration: 0.2 }
                            }}
                            onClick={_ => setVideoPlaying(true)}
                        ><i className="fifth-icon-right-arrow-filled" /></motion.button>
                    }
                    <div className="chicklets">
                        {
                            images.map((img, index) =>
                                <button key={index} className={index === imageIndex ? 'at' : ''} onClick={() => setPage([index, index < imageIndex ? -1 : 1])} />
                            )
                        }
                    </div>
                    <motion.figcaption
                        key={`caption_${page}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: .4 } }}
                        exit={{ opacity: 0, transition: { duration: 0.8 } }}>
                        <AppRawHtml tagName="span">
                            {image.title + (image.photoCredit ? `<br />Photo Credit: ${image.photoCredit}` : '')}
                        </AppRawHtml>
                    </motion.figcaption>
                </div>


            </AnimatePresence>

            {videoPlaying || <div className='overlay-left' />}
            {videoPlaying || <div className='overlay-right' />}


            <button className="btn-prev" onClick={e => { setPage([imageIndex - 1, -1]); }}><i className="fifth-icon-left-arrow"></i></button>
            <button className="btn-next" onClick={e => { setPage([imageIndex + 1, 1]); }}><i className="fifth-icon-right-arrow"></i></button>
        </div>
    );
}


/*

            <div className="image-strip">
                {
                    images.map((img, index) =>
                        <Figure key={index} {...img}>
                            <figcaption><span>{img.title}</span></figcaption>
                        </Figure>
                    )
                }
            </div>

        </div >

            */