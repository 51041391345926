import React from "react";

import './half-slash.scss';
import { Figure } from "../Figure/Figure";
import { AppRawHtml } from "App/AppRawHtml";
import { AppLink } from "App/AppLink";
import { FigureData } from "Util/FigureData";


interface PropTypes {
    title: string;
    text?: string;
    image: FigureData;
    url?: string;
    buttonText?: string;
    index: number;
}

export default function HalfSlashListEntry(props: PropTypes) {
    const { title = '', text, url, buttonText = "Learn More", image, index } = props;
    const direction = index % 2 ? 'up' : 'down';
    return (
        <div className={`half-slash left ${direction} `}>
            <div className="half-slash-image">
                <Figure {...image}>
                    <div className="half-slash-text">
                        <div>
                            <div><AppRawHtml tagName="h2">{title}</AppRawHtml></div>
                            <AppRawHtml className="text text-inner">{text}</AppRawHtml>
                            {
                                url ? <div><AppLink className="btn" to={url}>{buttonText}</AppLink></div> : ""
                            }
                        </div>
                    </div>
                </Figure>
            </div>
            <AppRawHtml className="text text-below">{text}</AppRawHtml>
        </div>
    );
}
