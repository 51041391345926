import React, { useCallback, useState } from "react";
import { ModalEntry, ModalServiceContext, ModalResult } from "./Types";
import { Modal } from "./Modal";


export const ModalProvider: React.FC = (props) => {
    const [current, setCurrentState] = useState<ModalEntry>();

    const setCurrent = (entry?: ModalEntry) => {
        setCurrentState(entry);
        if (entry) {
            document.querySelector("body")?.classList.add('with-modal');
        }
        else {
            document.querySelector("body")?.classList.remove('with-modal');
        }
    }

    /**
     * save the promise info for later
     */
    const [resultPromise, setResultPromise] = useState<{
        resolve: (value?: ModalResult | PromiseLike<ModalResult> | undefined) => void,
        reject: (reason?: any) => void
    } | undefined>(undefined);


    /**
     * create a modal with the given ModalEntry or string html value
     * @param value the entry or html text to show
     */
    const show = (value: ModalEntry | string) => {
        const modal: ModalEntry = (typeof value === 'string')
            ? {
                key: value,
                text: value
            }
            : value;
        if (!modal.key) {
            modal.key = modal.text;
        }
        if (modal.allowClose === undefined) {
            modal.allowClose = true;
        }
        // if there's an existing modal, dismiss it.
        if (current && resultPromise && modal.key !== current.key) {
            resultPromise.resolve({
                modal: current,
                result: false
            });
        }
        setCurrent(modal);
        return new Promise<ModalResult>((resolve, reject) => {
            setResultPromise({ resolve, reject });
        });
    };
    const confirm = (message: string) => {
        const modal: ModalEntry = {
            key: message,
            text: message,
            button: ['Cancel', 'OK']
        }
        return show(modal).then(mr => mr.result === "OK")
    }
    /**
     * Set the result of the current modal, and dismiss the modal
     * @param result the result created by the modal
     */
    const setResult = useCallback((result: any) => {
        if (current) {
            setCurrent(undefined);
            resultPromise?.resolve({
                modal: current,
                result: result
            });
        }
    }, [current, resultPromise]);

    const dismiss = useCallback(() => {
        console.log('dismiss', current);
        current && current.allowClose && setResult(false);
    }, [current, setResult]);
    const modalService = { current, show, setResult, dismiss, confirm };

    return (
        <ModalServiceContext.Provider value={modalService}>
            {props.children}
            <Modal />
        </ModalServiceContext.Provider>
    );
}