import React from "react";

import './page-link.scss';
import { FigureData, Figure } from "../Figure/Figure";

import { AppLink } from "App/AppLink";
import { Button } from "Util/Forms/Button";
import { useAppConfig } from "App/AppConfig";
import { labels } from "Util/Labels";



export interface PageLinkData {
    url: string;
    text: string;
    buttonText: string;
    grid: number;
    image: FigureData;


}

export default function PageLink(props: PageLinkData) {
    const { url, text: headlineText, image, grid } = props;
    const buttonText = (props.buttonText?.length) ? props.buttonText : labels.getString("global.defaultLinkButtonText");

    const appConfig = useAppConfig();
    const className = 'fifth-page-link bg-dark'
        + (image ? ' with-image' : ' without-image')
        + (url ? ' with-link' : ' without-link');
    return (
        <div className={className}>
            <AppLink to={url} target={url?.indexOf(appConfig.siteUrl) === 0 || url.indexOf("/") === 0 ? undefined : "_blank"}>
                {
                    image
                        ? (
                            <Figure {...image} grid={grid} >
                                <figcaption>
                                    <h4>{headlineText}</h4>
                                    {url && buttonText && <Button className={grid < 6 ? 'btn-aside' : 'btn'}>{buttonText}</Button>}
                                </figcaption>
                            </Figure>
                        ) : (
                            <div>
                                <h4>{headlineText}</h4>
                                {url && buttonText && <Button className="btn">{buttonText}</Button>}
                            </div>)
                }
            </AppLink>
        </div>
    );
}
