import { PopupEntry, PopupService, PopupOptions } from "./Types";

import React, { useContext, useState, useEffect } from "react";
import { Popup } from "./Popup";
import { useHistory } from "react-router";
const rootService: PopupService = {
    get: () => undefined,
    show: (a: PopupEntry, options?: PopupOptions) => { /* will be filled by context */ },
    hide: () => { /* will be filled by context */ }
}
const PopupServiceContext = React.createContext<PopupService>(rootService);

export const usePopup = () => useContext<PopupService>(PopupServiceContext);
export const PopupProvider: React.FC = (props) => {
    const [current, setCurrent] = useState<PopupEntry>();
    rootService.get = () => current;
    rootService.show = (value: PopupEntry, options?: PopupOptions) => {
        setCurrent({ ...value, options });
    }
    rootService.hide = () => {
        if (current) {
            setCurrent(undefined);
        }
    }

    const history = useHistory();
    useEffect(() => {
        history.listen(_ => {
            rootService.hide();
        });
    }, [history])

    return (
        <PopupServiceContext.Provider value={rootService}>
            {props.children}
            <Popup popup={current} popupService={rootService} />

        </PopupServiceContext.Provider>
    );
}