import { FigureData } from "Util/FigureData";

// https://app.quicktype.io?share=FA9ZAqXz83KGJJQ3CbYo
// To parse this data:
//
//   import { Convert, Tess } from "./file";
//
//   const tess = Convert.toTess(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export class TessInventory {
    modeOfSale: number;
    prods: Array<TessProduction>;
    constructor(mos: number, data: Array<TessProduction>) {
        this.modeOfSale = mos;
        this.prods = data;
    }
    findProduction(prodNumber: number) {
        return this.prods.find(pr => pr.prodNumber === prodNumber);
    }
    find(id: string | undefined) {
        let prod: TessProduction | undefined;
        let perf: TessPerformance | undefined;
        if (id) {
            let prodNumber: number = parseInt(id);
            if (!isNaN(prodNumber)) {
                prod = this.findProduction(prodNumber);
            }
            if (!prod) {
                prod = this.prods.find(pd => {
                    perf = pd.performances.find(pf => pf.perfCode === id);
                    return perf ? true : false;
                })
            }
        }
        return { prod, perf };
    }
    getAllPerformances(): TessPerformance[] {
        let perfs: TessPerformance[] = [];
        this.prods.forEach(prod => {
            perfs.push(...prod.performances);
        });

        return perfs;
    }
}


export interface TessProduction {
    title: string;
    url?: string;
    image?: FigureData;
    startDate: Date;
    endDate: Date;
    description: string;
    specialDates?: string;
    prodNumber: number;
    season?: string;
    seasonNumber: number;
    facility: string;
    seatMethods: string[];
    isOnSale: boolean;
    performances: TessPerformance[];
    allDayEvent: boolean;
}

export interface TessPerformance {
    perfNo: number;
    perfCode: string;
    date: Date;
    isOnSale: boolean;
    availability?: { [key: string]: number };
    onSaleStart?: Date;
    onSaleEnd?: Date;

    production?: TessProduction;

    isSoldOut?: boolean;
    isLimited?: boolean;
    keywords: string[];
}

export interface PerfDetail extends TessPerformance {
    production: TessProduction;
    priceTypes: PriceType[];
    zones: Zone[];
    seatmapData: string;
}

export interface PriceType {
    id: number;
    title: string;
    maxSeats: number;
}

export interface Zone {
    zoneNumbers: number[];
    title: string;
    color: string;
    prices: number[]; // these are in the same order as pricetypes
    seats?: number[];
}


export type SeatMethodOption = 'section' | 'syos';

export type SubmitSeatRequest = (seatRequest: SeatRequest) => Promise<boolean>;

export interface SeatRequest {
    perfNo: number;
    zone?: number;
    priceTypes: number[];
    seats?: number[];
    isUnseated?: boolean;
    errorMessage?: string; // returned when the SeatRequest fails to build
}
export interface SeatResult {
    success: boolean;
    message?: string;
}
