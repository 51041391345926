export const devLabels = {
    tickets: {
        buyTickets: 'Buy Tickets'
    },
    performance: {
        chooseBySection: 'Choose by Section',
        chooseBySyos: 'Choose your own Seats',
        addToCart: 'Add to Cart&nbsp;<i class="fifth-icon-right-arrow-small" />',
        loadingButton: '...loading performance info...',
        noSeatsAvailable: "<h3><i class='fifth-icon-warning'></i> No Seats Found</h3><p>We're sorry, but no seats could be found for the selected performance. Please choose a different performance to continue.</p>",
        seatsReserved: {
            key: 'seatsReserved',
            title: 'Success',
            text: 'Your seats have been reserved. You may continue to your cart or continue shopping.',
            button: 'View Cart',
            url: '/cart'
        },
        seatsNotReserved: {
            title: 'Fail',
            text: "Unable to reserve seats. Please try again. If you continue to have trouble, try multiple requests with fewer seats requested, or call the box office for assistance."
        },
        wheelchairError: "There was a problem loading the availble wheelchair seats for the performance. Please try again. If you continue to have trouble, try multiple requests with fewer seats requested, or call the box office for assistance.",
        wheelchairFail: "Unable to reserve wheelchair seats to match your request. Please try again. If you continue to have trouble, try multiple requests with fewer seats requested, or call the box office for assistance.",
        inCart: {
            title: 'Performance in Cart',
            body: 'You have seats for this performance in your cart. Please do not reserve additional seats unintentionally.'
        },
        syos: {
            seatTrayIntro: 'Choose your seats above. Please try not to leave single seats.',
            seatTrayIntroSeats: '{num} seat{s} selected.',
            seatTrayInstructions: '<span>Your seats are not reserved until you click “Add to Cart” to reserve.</span>',
        },
        seatTypeValidation: {
            wheelchair: '<h3>Wheelchair Space</h3><p>You are reserving one or more wheelchair spaces with your order. I understand I am purchasing a Wheelchair Accessible location, where there is an empty space for the wheelchair to occupy.</p>',
            companion: '<h3>Wheelchair Companion Seats</h3><p>You are reserving one or more wheelchair companion seats with your order. Please be aware that these seats are reserved for those patrons accompanying wheelchair patrons.</p>',
        },
        calendarKeywords: {
            "audio-described": {
                icon: "audio-described",
                title: "Audio Described",
                description: "These performances are audio described, which describes the audio."
            }
        }
    },
    user: {
        username: {
            label: 'Email Address',
            invalid: 'Please enter a valid email address.',
            placeholder: 'sweeney@todd.com,maria@west-side.com,deloris@sisteract.com,frankie@4-seasons.com'
        },
        password: {
            label: 'Password',
            invalid: 'Please enter your password.'
        },
        usernameConfirm: {
            label: 'Confirm Email Address',
            invalid: 'Please confirm your email address.',
            placeholder: 'sweeney@todd.com,maria@west-side.com,deloris@sisteract.com,frankie@4-seasons.com'
        },
        passwordConfirm: {
            label: 'Confirm Password',
            invalid: 'Please confirm your password.'
        },
        loginButton: 'Login',
        loginSuccess_cart: {
            text: '<h2>Success</h2><p>You may now proceed to checkout.</p>',
            url: '/cart/checkout',
            button: 'OK'
        },
        loginSuccess_noCart: {
            text: '<h2>Success</h2><p>You may now continue browsing the site.</p>',
            url: '/user',
            button: 'OK'
        },
        resetSent: '<h2>Password Reset</h2><p>A code has been sent to your email address to provide access to the site. Please allow 1-2 minutes for delivery.</p>',
        loginFailed: 'Your email address or password is incorrect. Please try again or reset your password to continue.',
        resetSuccess: {
            text: '<h2>Success</h2><p>You may now set a permanent password.</p>',
            url: '/user',
            button: 'OK'
        },
        resetFail: '<h2>Code Failed</h2><p>The code you provided did not work. Please try again, or cancel and return to the previous screen.</p>',
        promoNotFound: '<h2>Promo Code Not Found</h2><p>We’re sorry, that promotion code was not found in our system. Please check your link. You may still get tickets to one of our <a href="/shows">Shows</a></p>',
        oldUsername: {
            label: 'Current Email Address',
            invalid: 'Please enter your current email address.',
            placeholder: 'sweeney@todd.com,maria@west-side.com,deloris@sisteract.com,frankie@4-seasons.com'
        },
        oldPassword: {
            label: 'Current Password',
            invalid: 'Please enter your current password.'
        },
        newUsername: {
            label: 'New Email Address',
            invalid: 'Please enter your new email address.',
            placeholder: 'sweeney@todd.com,maria@west-side.com,deloris@sisteract.com,frankie@4-seasons.com'
        },
        newPassword: {
            label: 'New Password',
            invalid: 'Please enter your new password.'
        },
        newUsernameConfirm: {
            label: 'Confirm New Email Address',
            invalid: 'Please confirm your new email address.',
            placeholder: 'sweeney@todd.com,maria@west-side.com,deloris@sisteract.com,frankie@4-seasons.com'
        },
        newPasswordConfirm: {
            label: 'Confirm New Password',
            invalid: 'Please confirm your new password.'
        },
        nextButton: 'Next',
        registerButton: 'Register',
        registerFailed: {
            text: '<h2>Register Failed</h2><p>There is already an account registered with this email address. Please <a href="/user/login">Login</a> or <a href="/user/reset">Reset</a> your password</p>',
            button: 'OK'
        },
        firstName: {
            label: 'First Name',
            invalid: 'Please enter your first name.'
        },
        lastName: {
            label: 'Last Name',
            invalid: 'Please enter your last name.'
        },
        street1: {
            label: 'Address',
            invalid: 'Please enter your address.'
        },
        street2: {
            label: 'Apt, Suite',
            invalid: 'Please enter your apt, suite.'
        },
        city: {
            label: 'City',
            invalid: 'Please enter your city.'
        },
        state: {
            label: 'State',
            invalid: 'Please enter your state.'
        },
        country: {
            label: 'Country',
            invalid: 'Please enter your country.'
        },
        postalCode: {
            label: 'Zip Code',
            invalid: 'Please enter your zip code.'
        },
        phone: {
            label: 'Phone',
            invalid: 'Please enter your phone.'
        },
        dateOfBirth: {
            label: 'Date of Birth',
            invalid: 'Please enter your date of birth.'
        },
        updateLoginButton: 'Update Login',
        updateSuccess: {
            text: '<h2>Success</h2><p>Your login has been updated.</p>',
            button: 'OK'
        },
        updateFailed: {
            text: '<h2>Update Failed</h2><p>Your login has not been updated.</p>',
            button: 'OK'
        }

    },
    // cart: {
    //     emptyCart: '<h2>Empty Cart</h2><p>You do not have any items in your cart. To provide the best available seats for all of our patrons, tickets may be held online for no more than 15 minutes.</p>',
    //     membership: {
    //         title: 'Donation',
    //         subtitle: 'Thank you for your support!',
    //         amountLabel: 'Contribution',
    //         body: 'As a non-profit organization, The 5th works to serve our community. Your generosity opens the door to educational experiences for nearly 75,000 students annually and provides tickets to families who otherwise could not afford to attend live theater.'
    //     },
    //     donation: {
    //         title: 'Donation',
    //         subtitle: 'Thank you for your support!',
    //         amountLabel: 'Contribution',
    //         body: 'As a non-profit organization, The 5th works to serve our community. Your generosity opens the door to educational experiences for nearly 75,000 students annually and provides tickets to families who otherwise could not afford to attend live theater.'
    //     },
    //     completeThankYou: '<h3>Thank you for your order.</h3><p>Please print or save this page for your records.<br />A confirmation email will be sent to {email}.</p>',
    //     completeFooter: '<p>If you have any questions about your order, please contact us at <a href="tel:2066251900">206.625.1900</a> or <a href="mailto:guestservices@5thavenue.org">guestservices@5thavenue.org</a></p><p>All sales are final</p><p>Late seating is at the discretion of management. Theater doors open 45 minutes before the show and seating begins 30 minutes before the curtain. All ages require a ticket. Children under 4 years of age, including babes in arms, will not be admitted. Program, dates and prices are subject to change.</p>',
    //     emailThankYou: '<h3>Thank you for your order.</h3><p>Please do not reply to this email. If you have any questions about your order, please contact us at <a href="tel:2066251900">206.625.1900</a> or <a href="mailto:guestservices@5thavenue.org">guestservices@5thavenue.org</a>.</p><p>Please print or save this page for your records.',
    //     emailFooter: `<hr>
    //         <p>Prices include a $9.00 per ticket facility fee.</p>
    //         <p>If you have any questions about your order, please contact us at 206.625.1900 or <a href="mailto:guestservices@5thavenue.org" target="_blank">guestservices@5thavenue.org</a>.</p>
    //         <p>All Sales are final – no refunds.</p>
    //         <p>Late seating is at the discretion of management. Theater doors open 45 minutes before the show and seating begins 30 minutes before the curtain. All ages require a ticket. Children under 4 years of age, including babes in arms, will not be admitted. 5th Avenue productions are generally suitable for ages 8 and above. No Firearms. For detailed performance information, parental guidelines, driving directions, parking garages or area restaurants visit <a href="http://www.5thavenue.org" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.5thavenue.org&amp;source=gmail&amp;ust=1583873659039000&amp;usg=AFQjCNGOLTvAvVQH4Vh0S5XU16g1g41jiQ">www.5thavenue.org</a>. The 5th Avenue Theatre is located at 1308 5th Avenue in Downtown Seattle; on 5th Avenue between Union and University Street.</p>
    //         <p>Program, dates and prices subject to change.</p>
    //         <p>An exciting new construction project is happening across the street from our theater that began in September of 2017. The Rainier Square Tower will be the 2nd tallest building in Seattle and is expected to open in 2019. Due to construction and possible lane closures, please allow yourself more time to arrive at the theater for your performance. For more information on what this building will bring to Downtown Seattle, please visit <a href="http://www.rainiersquare.com/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.rainiersquare.com/&amp;source=gmail&amp;ust=1583873659039000&amp;usg=AFQjCNGCa66gY13B6pNKgxkT7qaMJVxoYQ">http://www.rainiersquare.com/</a>.</p>
    //         <hr>
    //         <p>The 5th Avenue Theatre is a registered 501(c)(3) and our Tax Identification Number is 91-1087612. The 5th is registered with Washington State's Charities Program as required by law. Additional information is on file with the secretary at <a href="http://www.sos.wa.gov/charities" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.sos.wa.gov/charities&amp;source=gmail&amp;ust=1583873659039000&amp;usg=AFQjCNEbma5EZoTK7jsSbVkLENDlLr9OFg">www.sos.wa.gov/charities</a>. Donations are tax-deductible to the extent allowed by law. For gifts of $300 or more, patrons will receive a separate, mailed tax receipt.</p>
    //         <hr>
    // 		<p>Tickets are not valid unless purchased from an authorized ticket agency. A ticket is a revocable license and admission may be refused upon refunding the face value of the ticket. Cameras, audio and video recorders are strictly prohibited. Holder of a ticket voluntarily assumes all risks and danger incidental to any event for which a ticket is issued whether occurring prior to, during or subsequent to the actual event. Any claim for cancelled performance shall be limited to refund of ticket price and shall occur within 60 days after performance was to have occurred.</p>
    //         <p>Thank you.</p>
    //         <p>The 5th Avenue Theatre 1308 5th Avenue Seattle, WA 98101 <a href="http://www.5thavenue.org" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.5thavenue.org&amp;source=gmail&amp;ust=1583873659039000&amp;usg=AFQjCNGOLTvAvVQH4Vh0S5XU16g1g41jiQ">www.5thavenue.org</a></p>
    //     `
    // }
}
