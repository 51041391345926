import React, { useRef, useState } from "react";

import { motion } from "framer-motion";

import { BuyTicketsButton, BuyTicketsButtonProps } from "Tess/Tickets/BuyTicketsButton";
import { AppLink } from "App/AppLink";
import { formatDateRange } from "Util/format";
import { useWindowEventListener } from "Util/useEventListener";
import { Figure, FigureData } from "../Figure/Figure";
import './show-link.scss';


export interface ShowLinkData extends BuyTicketsButtonProps {
    url?: string;
    image: FigureData;
    grid: number;
}

export default function ShowLink(props: ShowLinkData) {
    return <RevealBox
        image={props.image}
        grid={props.grid}
        footer={formatDateRange(props)}
    >
        <BuyTicketsButton {...props} />
        {props.url && <AppLink to={props.url} className="btn btn-clear">Learn More</AppLink>}
    </RevealBox>
}

interface RevealBoxProps {
    image: FigureData;
    grid?: number;
    footer: any; // footer text
}
export const RevealBox: React.FC<RevealBoxProps> = (props) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const figureRef = useRef<any>(null);

    useWindowEventListener('click', (e) => {
        if (!figureRef.current || !figureRef.current.contains(e.target)) {
            setIsClicked(false);
        }
    });

    return (
        <motion.figcaption className="show-link"
            whileHover="open"
            onClick={() => setIsClicked(true)}
            animate={(isClicked) ? "open" : "closed"}
        >
            <Figure {...props.image} grid={props.grid || 0} ref={figureRef}>
                <motion.figcaption
                    variants={{
                        closed: { height: '3.5rem', clipPath: 'polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)', transition: { type: 'tween' } },
                        open: { height: '100%', clipPath: 'polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 30%)', transition: { type: 'tween' } }
                    }} className="bg-black">
                    <motion.div variants={{
                        closed: { opacity: 1, display: 'block' },
                        open: { opacity: 0, display: 'none' }
                    }}>
                        {props.footer}
                    </motion.div>
                    <motion.div variants={{
                        closed: { opacity: 0, display: 'none', transition: { duration: 0 } },
                        open: { opacity: 1, display: 'block' }
                    }}>
                        {props.children}
                    </motion.div>
                </motion.figcaption>
            </Figure>
        </motion.figcaption>
    );
}
