import { useAppConfig } from "./AppConfig";
import React, { MouseEvent, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { RawHtmlPropTypes } from "Util/RawHtml";


interface AppRawHtmlPropTypes extends RawHtmlPropTypes {
    onContentClick?: (e: MouseEvent) => void; // should preventDefault() to stop the click from taking over. 
}

export const AppRawHtml: React.FC<AppRawHtmlPropTypes> = (props) => {
    let { tagName = 'div', className = undefined, children } = props;
    const appConfig = useAppConfig();
    const history = useHistory();
    const contentClickHandler = (e: MouseEvent) => {
        if (props.onContentClick) {
            props.onContentClick(e);
            if (e.isDefaultPrevented()) {
                return;
            }
        }
        const a = e.nativeEvent.target as HTMLAnchorElement;
        if (a) {
            const href = appConfig.mangleUrl(a.getAttribute("href") || ''),
                target = a.getAttribute("target") || "self";

            if (href && href[0] === '/' && href.indexOf('/media') !== 0 && target === "self") {
                history.push(href);
                e.preventDefault();
            }
        }
    };
    // from https://github.com/christo-pr/dangerously-set-html-content/blob/master/src/index.js

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fragment = document.createRange().createContextualFragment(children);
        if (divRef.current) {
            while (divRef.current.firstChild) {
                divRef.current?.removeChild(divRef.current.firstChild);
            }
            divRef.current.appendChild(fragment);
            divRef.current.querySelectorAll('a').forEach((a) => {
                const href = a.getAttribute("href") || '';
                if (href.indexOf("https") === 0 && href.indexOf(appConfig.siteUrl) === -1 && href.indexOf(appConfig.tessConfig.tnewSiteRoot || 'http') === -1) {
                    a.target = "_blank";
                }
                else if (href.indexOf('/') === 0) {
                    a.href = appConfig.mangleUrl(href);
                }
            });
            if (!appConfig.isSameSite) {
                divRef.current.querySelectorAll('img').forEach((img) => {
                    const src = img.getAttribute("src");
                    if (src && src.indexOf("/") === 0) {
                        img.src = appConfig.mangleUrl(src);
                    }

                });
            }
        }
    }, [appConfig, appConfig.siteUrl, appConfig.tessConfig.tnewSiteRoot, children, divRef])

    return React.createElement(
        tagName,
        {
            className,
            onClick: contentClickHandler,
            ref: divRef
        }
    );
}