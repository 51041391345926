import { motion } from 'framer-motion';
import React from "react";
import { AppLink } from "App/AppLink";

import './mega-menu.scss';
import { NavConfig } from "./NavConfig";
import { AppRawHtml } from 'App/AppRawHtml';




interface MegamenuProps {
    t1: NavConfig,
    isExpanded: boolean
}
export function Megamenu({ t1, isExpanded }: MegamenuProps) {
    const variants = {
        closed: {
            height: 0,

        },
        expanded: {
            height: "auto",
            transition: {
                duration: 0.3,
                ease: 'easeOut'
            }
        }
    };

    const meganavitems = t1.meganav.filter(c => c);
    const colSize = `col-lg-${12 / meganavitems.length}`;
    return (
        <motion.div className="mega-menu" variants={variants} animate={isExpanded ? 'expanded' : 'closed'}>
            <div className="content">
                <h2><AppLink to={t1.url[0]}>{t1.title} <i className="fifth-atom-icon-arrow-right" /></AppLink></h2>
                <div className="row">
                    {meganavitems.map((col, index) => (
                        <div className={colSize} key={index}>
                            <AppRawHtml>{col}</AppRawHtml>
                        </div>
                    ))}
                </div>
            </div>
        </motion.div>
    )
}