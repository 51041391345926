import React from "react"
import { useForm } from "react-hook-form";
import { Button } from "Util/Forms/Button";
import { GridRow } from "Util/Grid/GridRow";
import { RawHtml } from "Util/RawHtml";
import { CustomerOrderNumber, ProtectedContentProps } from "./ProtectedContentLoader";

export const OrderNumberForm: React.FC<ProtectedContentProps> = (props) => {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const submit = handleSubmit(async (data: any) => {
        return await props.authorize(data as CustomerOrderNumber)
    });
    return (
        <form onSubmit={submit}>
            <GridRow className="row-standard with-padding">
                <div className="col-12 mb-0">
                    <div>
                        <h2>Order Number</h2>
                        <RawHtml>{props.properties.customerOrderNumberInstructions}</RawHtml>
                    </div>
                </div>
                <div className="col-md-4 mt-0">
                    <div>
                        <div className={`form-group text${errors.customerNumber ? ' invalid' : ''}`}>
                            {errors.customerNumber
                                ? <label>Please enter customer number</label>
                                : <label>Customer Number</label>}
                            <input type="number" name="customerNumber" ref={register({ required: true })} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-0">
                    <div>
                        <div className={`form-group text${errors.orderNumber ? ' invalid' : ''}`}>
                            {errors.orderNumber
                                ? <label>Please enter order number</label>
                                : <label>Order Number</label>}
                            <input type="number" name="orderNumber" ref={register({ required: true })} />
                        </div>
                    </div>
                </div>
                <div className="col-8 buttons">
                    <Button className="btn" type="submit">Submit<i className="fifth-icon-right-arrow-filled" /></Button>
                </div>
            </GridRow>
        </form>
    );
}