import React, { useMemo } from "react";
import { labels } from "Util/Labels";
import { pickRandom } from "Util/pickRandom";
import { useFormContext } from "react-hook-form";
interface DetailFormProps {
    editEmail: () => void;
}
export const DetailForm: React.FC<DetailFormProps & any> = (props) => {
    const { register, getValues, errors } = useFormContext();
    const emailPlaceholder = useMemo(() => pickRandom(labels.getString("user.username.placeholder").split(',')), []);
    return (

        <fieldset>

            <div className={'row'}>
                <div className="col">
                    <div className={`form-group text${errors.email ? ' invalid' : ''}`}>
                        {errors.email ? <label>{labels.getString("user.username.invalid")}</label> : <label>{labels.getString("user.username.label")}</label>}
                        <input type="email" name="email" defaultValue={props.email} placeholder={emailPlaceholder}
                            readOnly
                            onClick={props.editEmail}
                        />
                    </div>
                </div>


                <div className="col">
                    <div className={`form-group text${errors.emailConfirm ? ' invalid' : ''}`}>
                        {errors.emailConfirm ? <label>{labels.getString("user.usernameConfirm.invalid")}</label> : <label>{labels.getString("user.usernameConfirm.label")}</label>}
                        <input type="email" name="emailConfirm" ref={register({
                            required: "Please confirm email!",
                            validate: {
                                matchesPreviousUsername: (value: any) => {
                                    const { email } = props;
                                    return email === value || "Emails should match!";
                                }
                            }
                        })} />
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col">
                    <div className={`form-group password${errors.password ? ' invalid' : ''}`}>
                        {errors.password ? <label>{labels.getString("user.password.invalid")}</label> : <label>{labels.getString("user.password.label")}</label>}
                        <input name="password" type="password" defaultValue={props.password} ref={register({ required: true })} />
                    </div>
                </div>

                <div className="col">
                    <div className={`form-group password${errors.passwordConfirm ? ' invalid' : ''}`}>
                        {errors.passwordConfirm ? <label>{labels.getString("user.passwordConfirm.invalid")}</label> : <label>{labels.getString("user.passwordConfirm.label")}</label>}
                        <input name="passwordConfirm" type="password" defaultValue={props.password} ref={register({
                            required: "Please confirm password!",
                            validate: {
                                matchesPreviousPassword: (value: any) => {
                                    const { password } = getValues();
                                    return password === value || "Passwords should match!";
                                }
                            }
                        })} />
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col">
                    <div className={`form-group text${errors.firstName ? ' invalid' : ''}`}>
                        {errors.firstName ? <label>{labels.getString("user.firstName.invalid")}</label> : <label>{labels.getString("user.firstName.label")}</label>}
                        <input type="text" name="firstName" defaultValue={props.firstName} ref={register({ required: true })} />
                    </div>
                </div>
                <div className="col">
                    <div className={`form-group text${errors.lastName ? ' invalid' : ''}`}>
                        {errors.lastName ? <label>{labels.getString("user.lastName.invalid")}</label> : <label>{labels.getString("user.lastName.label")}</label>}
                        <input type="text" name="lastName" defaultValue={props.lastName} ref={register({ required: true })} />
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className="col">
                    <div className={`form-group text${errors.phone ? ' invalid' : ''}`}>
                        {errors.phone ? <label>{labels.getLabel("user.phone.invalid")}</label> : <label>{labels.getLabel("user.phone.label")}</label>}
                        <input type="tel" name="phone" defaultValue={props.phone} ref={register({
                            required: true
                        })} />
                    </div>
                </div>
                <div className="col">
                    <div className={`form-group text${errors.dateOfBirth ? ' invalid' : ''}`}>
                        {errors.dateOfBirth ? <label>{labels.getLabel("user.dateOfBirth.invalid")}</label> : <label>{labels.getLabel("user.dateOfBirth.label")}</label>}
                        <input type="date" name="dateOfBirth" placeholder="MM/DD/YYYY" defaultValue={props.dateOfBirth} ref={register} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>


        </fieldset>
    );
}