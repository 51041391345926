import { AppConfigProvider } from 'App/AppConfig';
import { CmsContextProvider } from 'Cms/Data/CmsContext';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { TessContextProvider } from 'Tess/Data/TessContext';
import { DebugInfo } from 'Util/DebugInfo';
import { GlobalMessageProvider } from 'Util/GlobalMessage/GlobalMessageContext';
import { ModalProvider } from 'Util/Modal/ModalContext';
import { AppFooter } from './AppFooter/AppFooter';
import { AppHeader } from './AppHeader/AppHeader';
import { AppRouter } from './AppRouter';


const App: React.FC = (props) => {

  return (
    <AppConfigProvider>
      <GlobalMessageProvider>
        <TessContextProvider>
          <BrowserRouter>
            <ModalProvider>
              <CmsContextProvider>
                <AppHeader />
                <AppRouter />
                <AppFooter />
                <DebugInfo />
              </CmsContextProvider>
            </ModalProvider>
          </BrowserRouter>
        </TessContextProvider>
      </GlobalMessageProvider>
    </AppConfigProvider>
  );
}

export default App;
