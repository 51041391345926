import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import './loading.scss';


export const Loading: React.FC = () => {

    return (
        <AnimatePresence>
            <motion.div className="loading" >
                <div>
                    <svg viewBox="0 0 360 360">
                        <defs>
                            <linearGradient id="linearGradient" gradientTransform="rotate(90)">
                                <stop offset="0%" stopColor="#eeeeee" />
                                <stop offset="100%" stopColor="#333333" />
                            </linearGradient>
                            <radialGradient id="radialGradient">
                                <stop offset="0%" stopColor="#ffffff" stopOpacity={.9} />
                                <stop offset="70%" stopColor="#ffffff" stopOpacity={.9} />
                                <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
                            </radialGradient>
                        </defs>
                        <motion.circle cx="180" cy="180" r="180" style={{
                            fill: 'url(#linearGradient)'
                        }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 10, yoyo: 'Infinity' } }}
                        />


                        <motion.path
                            d="M 0,0 H 360 V 360 H 0 Z 
                            m 192.20493,131.57212 
                            c -0.60071,-4.9378 0.95688,-7.55762 6.17786,-6.243 
                            h 49.10014 
                            V 43.803119 
                            h -135.782 
                            c -3.33176,54.076562 -6.59723,108.080451 -9.704,162.159001 19.43367,8.77433 38.86733,17.54867 58.301,26.323 
                            h -59.84 
                            l -2.993998,51.223 
                            c 25.937108,31.2344 73.031388,41.65982 109.754378,24.39073 36.9348,-16.04412 60.2515,-57.72097 54.42907,-97.59669 -4.38933,-37.24779 -33.06112,-69.67866 -69.44245,-78.73004 z"
                            style={{ fill: '#ffffff' }}

                            animate={{
                                rotate: [0, 360, 360, 360], transition: {
                                    duration: 10,
                                    loop: 'Infinity'
                                }
                            }}
                            exit={{
                                rotate: [0, 360, 360, 360], transition: {
                                    duration: 2,
                                    loop: 'Infinity'
                                }
                            }}
                        />
                    </svg>

                </div>
            </motion.div>
        </AnimatePresence>
    );
}