import React, { useState } from "react";
import './blog-section.scss';
import { PageStub, CmsData } from "Cms/Data/CmsData";
import { GridSection } from "Util/Grid/GridSection";
import { GridRow } from "Util/Grid/GridRow";
import { GridArea } from "Util/Grid/GridArea";
import PageLink from "../PageLink/PageLink";
import { BlogFilter } from "./BlogFilter";

export interface BlogEntry extends PageStub {
    keywords: string[];
    image: Image;
    date: Date;
}

export interface Image {
    src: string;
    height: number;
    width: number;
    title: string;
}
export default function BlogSection(props: CmsData) {
    let allEntries = props.childNodes?.map(entry => entry as BlogEntry) || [];
    const [filteredEntries, setFilteredEntries] = useState<BlogEntry[]>(allEntries);

    return (
        <>
            <BlogFilter title={props.title} allEntries={allEntries} setFilteredEntries={setFilteredEntries} />
            <GridSection className="blog">
                <BlogEntries entries={filteredEntries} />
            </GridSection>
        </>
    );
}

interface BlogEntriesPropTypes {
    entries: BlogEntry[];
}
export const BlogEntries: React.FC<BlogEntriesPropTypes> = (props) => {
    let { entries } = props;
    let itemsLeftInThisRow = 1; // alternate 3/4/3/4 
    let rowIndex = -1;
    let rows: BlogEntry[][] = [];



    const getItemCountForRow = (rowNum: number) => {
        switch (entries.length) {
            case 1:
            case 2:
            case 3:
                return entries.length;
            case 4:
                return 2;
            case 5:
            case 8:
                return rowNum ? 3 : 2;
            case 6:
                return 3;
        }
        switch (entries.length % 7) {
            case 3: return rowNum % 2 ? 4 : 3;
            case 1:
            case 2:
            case 5: return (rowNum) ? rowNum % 2 ? 3 : 4 : 2;
            case 6: return (rowNum) ? rowNum % 2 ? 4 : 3 : 2;
            default: return rowNum % 2 ? 3 : 4;
        }
    }
    for (let index = 0; index < entries?.length; index++) {
        if (!(--itemsLeftInThisRow)) {
            rowIndex++;
            rows[rowIndex] = []
            itemsLeftInThisRow = getItemCountForRow(rowIndex);
        }
        rows[rowIndex].push(entries[index]);
    }
    return (
        <>
            {
                rows.map((row, rowIndex) => {
                    const areaSize = Math.min(6, 12 / row.length);
                    return (
                        <GridRow key={rowIndex} className={`row-${rowIndex} ${getItemCountForRow(rowIndex)}-col`}>
                            {
                                row.map((entry, entryIndex) => (
                                    <GridArea size={areaSize} key={entryIndex}>
                                        <PageLink
                                            url={entry.url}
                                            text={entry.title}
                                            buttonText={(entry.keywords && entry.keywords[0]) ? entry.keywords[0] : "Read More"}
                                            grid={areaSize}
                                            image={entry.image}
                                        />
                                    </GridArea>
                                ))
                            }
                        </GridRow>
                    )
                })
            }
        </>
    );
}