import React from "react"
import { FormFieldPropTypes, getFieldCaption } from "../Types";

export const DropDownList: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const err = props.formContext.errors[field.id];
    return (

        <div className={`form-group text${err ? ' invalid' : ''}`}>
            <label>{getFieldCaption(field, props.formContext)}</label>
            <select
                name={field.id}
                defaultValue={props.formContext.formResponses[field.id]}
                ref={props.formContext.registerField(field)}
            >
                <option></option>
                {field.preValues?.map(val => (
                    <option key={val}>{val}</option>
                ))}
            </select>
        </div>

    );
}