import { useAppConfig } from "App/AppConfig";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTessContext } from "Tess/Data/TessContext";
import { LogoutButton } from "Tess/User/LogoutButton";
import { Button } from "Util/Forms/Button";
export const UtilNav: React.FC = () => {
    const { tessSession } = useTessContext();
    const appConfig = useAppConfig();


    const cartCount = tessSession ? tessSession.cartSummary.count : 0;
    return (
        <ul className="util-nav">
            <ul className="util-nav">
                <li><a href={`${appConfig.siteUrl}/givenow`} className="btn">Donate</a></li>
                {/* <li><NavLink to="/search" ><i className="fifth-icon-search" style={{ padding: '2px 4px' }} /></NavLink></li> */}
                {tessSession && tessSession.isLoggedIn && <li><a href={`${appConfig.tessConfig.tnewSiteRoot}/account/update?returnurl=${document.location.href}`}>Account</a></li>}
                {tessSession && tessSession.isLoggedIn && <li><LogoutButton /></li>}
                {tessSession && !tessSession.isLoggedIn && <li><a href={`${appConfig.tessConfig.tnewSiteRoot}/account/login?returnurl=${document.location.href}`}>Login</a></li>}
                {tessSession && cartCount > 0 && <li><a href={`${appConfig.tessConfig.tnewSiteRoot}/cart/details`}><i className="fifth-icon-shopping-cart" />Cart{cartCount > 1 ? `(${cartCount})` : ''}</a></li>}
            </ul>
        </ul>
    )
}

export const SearchModal: React.FC = () => {
    const history = useHistory();
    const { register, handleSubmit } = useForm(); // initialise the hook
    const submitSearch = handleSubmit((data) => {
        history.push(`/search/?query=${data.query}`);
    })
    return (
        <form onSubmit={submitSearch} className="search-modal">
            <div className="form-group text search-field">
                <label>Site Search</label>
                <input type="text"
                    name="query"
                    placeholder="Search Term"
                    autoComplete="off"
                    autoFocus
                    ref={register()}
                    style={{ flex: '80% 1 0' }}
                />
                <Button type="submit" style={{ flex: 'auto 0 1' }}>Go</Button></div>
        </form>
    );
}