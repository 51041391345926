import { useAppConfig } from "App/AppConfig";
import { CmsGrid } from "Cms/Components/Grid/CmsGrid";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import './app-footer.scss';
import { SponsorsFooter } from "./SponsorsFooter";




export const AppFooter: React.FC = () => {
    const { contentConfig } = useAppConfig()
    const { sponsors, footer } = contentConfig;

    const footerDiv = document.getElementById("footer");
    const variants = {
        initial: { opacity: 0, delay: 3000 },
        animate: { opacity: 1, delay: 3000 },
        exit: { opacity: 0 }
    };
    return footerDiv && footer ? ReactDOM.createPortal(
        <AnimatePresence>
            <motion.div {...variants} key="sponsors-footer">
                <SponsorsFooter sponsors={sponsors} />
            </motion.div>
            <motion.div {...variants} key="footer" className="footer bg-black"><CmsGrid grid={footer} /></motion.div>
        </AnimatePresence>,
        footerDiv
    ) : null;
};