import { useAppConfig } from "App/AppConfig";
import React from "react";

import { LinkProps, Link } from "react-router-dom";


/** 
 * this is a <Link> object but with links replaced coming from content
 * use appConfig to mangle link;
 * return an <a> or <Link> as appropriate
 */

export const AppLink = React.forwardRef((props: LinkProps, ref: React.Ref<any>) => {
    const appConfig = useAppConfig();
    const sameSite = appConfig.isSameSite();
    let href = props.to;
    if (href && typeof href === 'string') {
        href = appConfig.mangleUrl(href);
        if (sameSite && href[0] === '/') {
            return <Link {...props} to={href} ref={ref}>{props.children}</Link>
        }
        return <a {...props} href={href} ref={ref}>{props.children}</a>
    }
    else {
        // not sure what this is for
        return sameSite
            ? <Link {...props} ref={ref}>{props.children}</Link>
            : <a {...props} ref={ref}>{props.children}</a>;
    }
});