import React from "react";

export interface RawHtmlPropTypes {
    tagName?: string;
    className?: string;
    children: any;
}

export const RawHtml: React.FC<RawHtmlPropTypes> = (props) => {
    let { tagName = 'div', className = '', children } = props;

    return React.createElement(
        tagName,
        {
            className,
            dangerouslySetInnerHTML: { __html: children }
        });
}