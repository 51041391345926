import React from "react";

import { AppRawHtml } from "App/AppRawHtml";
import { useCmsContext } from "Cms/Data/CmsContext";

interface PropTypes {
    text: string
}

export default function Rte(props: PropTypes) {
    const { onContentClick } = useCmsContext();
    return (
        <AppRawHtml className="rich-text" onContentClick={onContentClick}>{props.text}</AppRawHtml>
    );
}