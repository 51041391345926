import React, { useState } from "react";
import { useForm, ValidationRules } from "react-hook-form";
import { Loading } from "App/Loading/Loading";
import { FormResult, UmbracoForm, UmbracoFormField } from "./Types";
import { FormPage } from "./FormPage";
import { useUmbracoFormsService } from "./UmbracoFormsService";
import { AppRawHtml } from "App/AppRawHtml";
import { useHistory } from "react-router";

export const Form: React.FC<UmbracoForm> = (form: UmbracoForm) => {
    const history = useHistory();
    const formContext = useForm();

    const formService = useUmbracoFormsService();
    const { errors, control } = formContext;
    const [pageNumber, setPageNumber] = useState<number>(0); // 0-based page number
    const [formResponses, setResponses] = useState<Record<string, any>>({ formId: form.formId });
    const formPage = form.pages[pageNumber];
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [result, setResult] = useState<FormResult | undefined>();

    // merge the page's responses in with the formResponses
    const savePageResponses = (pageResponses: Record<string, any>) => {
        const mergedResponses = { ...pageResponses, ...formResponses };
        setResponses(mergedResponses);
        return mergedResponses;
    }
    const submitForm = async (pageResponses: Record<string, any>) => {
        setIsSubmitting(true);
        const data = await formService.submitForm(savePageResponses(pageResponses));
        setResult(data);
        if (data.url) {
            history.push(data.url);
        }
        else {
            setIsSubmitting(false);
        }
    }
    // shortcut for common formContext.register(rules)
    function registerField(field: UmbracoFormField) {
        const validationRules: ValidationRules = {};
        if (field.required) {
            validationRules.required = true;
        }
        if (field.regEx) {
            validationRules.pattern = {
                value: new RegExp(field.regEx),
                message: field.invalidErrorMessage || "Invalid entry"
            }
        }
        return formContext.register(validationRules)
    }

    if (!formPage) {
        if (pageNumber !== 0) {
            setPageNumber(0);
        }
        return <Loading />
    }
    else if (isSubmitting || (result && result.success && result.url)) {
        return <Loading />
    }
    else if (result && result.success) {
        return (
            <div>
                <h2>{form.name}</h2>
                <AppRawHtml>{result.message || "Thank you."}</AppRawHtml>
            </div>
        );
    }
    else {
        return (
            <div id={`form_${form.formId}`}>
                <h2>{form.name}</h2>
                <FormPage
                    {...formContext}
                    form={form}
                    formPage={formPage}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    formResponses={formResponses}
                    savePageResponses={savePageResponses}
                    errors={errors}
                    registerField={registerField}
                    control={control}
                    result={result}
                    submitForm={submitForm}
                />
            </div>
        );
    }
}
