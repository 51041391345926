import { TessSession, Countries } from "./User";
import { TessProduction, PerfDetail, SeatResult } from "./TessInventory";
import { WheelchairSeat } from "./WheelchairSeats";
import { CartResponse, CheckoutResponse } from "./Cart";
import { parseDate } from "Util/format";

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {

    public static toTessSession(json: any): TessSession {
        return cast(typeof json === "string" ? JSON.parse(json) : json, r("TessSession"));
    }
    public static toProductionList(json: any): TessProduction[] {
        return cast(typeof json === "string" ? JSON.parse(json) : json, a(r("Production")));
    }
    public static toPerfDetail(json: any): PerfDetail {
        return cast(json, r("PerfDetail"));
    }
    public static toIntArray(json: any): Array<number> {
        return cast(json, a(0));
    }
    public static toSeatResult(json: any): SeatResult {
        return cast(json, r("SeatResult"));
    }
    public static toWheelchairSeats(json: any): WheelchairSeat[] {
        return cast(json, a(r("WheelchairSeat")));
    }
    public static toCartResponse(json: any): CartResponse {
        return cast(json, r("CartResponse"));
    }
    public static toCheckoutResponse(json: any): CheckoutResponse {
        return cast(json, r("CheckoutResponse"));
    }
    public static toCountries(json: any): Countries {
        return cast(json, r("Countries"));
    }

}

function invalidValue(typ: any, val: any): any {
    if (!val) {
        switch (typeof typ) {
            case "string": return '';
            case "number": return 0;
            case "object": return {};
            case "boolean": return false;
        }
    }
    if (!typ) {
        return val;
    }
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function transform(val: any, typ: any, getProps: any): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) {
            return val;
        }
        return invalidValue(typ, val);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        var l = typs.length;
        for (var i = 0; i < l; i++) {
            var typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) { }
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(typ: any, val: any): any {
        if (val === null) {
            return null;
        }
        const d = parseDate(val);
        if (!d || isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        var result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps);
            }
        });
        return result;
    }

    if (typ === "any") {
        return val;
    }
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) {
        return invalidValue(typ, val);
    }
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) {
        return transformEnum(typ, val);
    }
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers")
            ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")
                ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty("props")
                    ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") {
        return transformDate(typ, val);
    }
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

// function uncast<T>(val: T, typ: any): any {
//     return transform(val, typ, jsToJSONProps);
// }

// array
function a(typ: any) {
    return { arrayItems: typ };
}
// union
function u(...typs: any[]) {
    return { unionMembers: typs };
}
// this is an object 
function o(props: any[], additional: any) {
    return { props, additional };
}
// this is a mapping
function m(additional: any) {
    return { props: [], additional };
}
// this is a reference
function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "TessSession": o([
        { json: "sessionKey", js: "sessionKey", typ: "" },
        { json: "jwt", js: "jwt", typ: "" },
        { json: "orderId", js: "orderId", typ: 0 },
        { json: "isLoggedIn", js: "isLoggedIn", typ: true },
        { json: "modeOfSale", js: "modeOfSale", typ: 0 },
        { json: "loginInfo", js: "loginInfo", typ: r("LoginInfo") },
        { json: "cartSummary", js: "cartSummary", typ: r("CartSummary") },
        { json: "accountInfo", js: "accountInfo", typ: null },
        { json: "promoCode", js: "promoCode", typ: u(undefined, r("PromoCode")) },
    ], false),
    "CartSummary": o([
        { json: "count", js: "count", typ: 0 },
        { json: "firstSeatAddedDateTime", js: "firstSeatAddedDateTime", typ: null },
        { json: "expiration", js: "expiration", typ: u(undefined, Date) },
    ], false),
    "LoginInfo": o([

        { json: "username", js: "username", typ: '' },
        { json: "email", js: "email", typ: '' },
        { json: "isTemporary", js: "isTemporary", typ: u(undefined, true) },

    ], false),
    "AccountInfo": o([
        { json: "attributes", js: "attributes", typ: u(undefined, a("any")) },
        { json: "displayName", js: "displayName", typ: u(undefined, "") },
        { json: "id", js: "id", typ: u(undefined, 0) },

        { json: "firstName", js: "firstName", typ: u(undefined, "") },
        { json: "lastActivityDate", js: "lastActivityDate", typ: u(undefined, Date) },
        { json: "lastGiftDate", js: "lastGiftDate", typ: u(undefined, Date) },
        { json: "lastTicketDate", js: "lastTicketDate", typ: u(undefined, Date) },
        { json: "lastName", js: "lastName", typ: u(undefined, "") },
        { json: "middleName", js: "middleName", typ: u(undefined, "") },
        { json: "originalSource", js: "originalSource", typ: u(undefined, 0) },
        { json: "addresses", js: "addresses", typ: u(undefined, a(r("Address"))) },
        { json: "emailAddresses", js: "emailAddresses", typ: u(undefined, a(r("EmailAddress"))) },
        { json: "phoneNumbers", js: "phoneNumbers", typ: u(undefined, a(r("PhoneNumber"))) },
    ], false),
    "EmailAddress": o([
        { json: "isPrimary", js: "isPrimary", typ: u(undefined, true) },
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "address", js: "address", typ: u(undefined, "") },
    ], false),
    "PhoneNumber": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "phoneNumber", js: "phoneNumber", typ: u(undefined, "") },
        { json: "allowTelemarketing", js: "allowTelemarketing", typ: u(undefined, true) },
    ], false),
    "Address": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "isPrimary", js: "isPrimary", typ: u(undefined, true) },
        { json: "addressType", js: "addressType", typ: u(undefined, "") },
        { json: "street1", js: "street1", typ: u(undefined, "") },
        { json: "city", js: "city", typ: u(undefined, "") },
        { json: "state", js: "state", typ: u(undefined, r("State")) },
        { json: "postalCode", js: "postalCode", typ: u(undefined, "") },
        { json: "country", js: "country", typ: u(undefined, r("AddressCountry")) },
        { json: "street2", js: "street2", typ: u(undefined, "") },
    ], false),
    "AddressCountry": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "inactive", js: "inactive", typ: u(undefined, true) },
    ], false),
    "PromoCode": o([
        { json: "appealDescription", js: "appealDescription", typ: "" },
        { json: "appealNumber", js: "appealNumber", typ: 0 },
        { json: "modeOfSale", js: "modeOfSale", typ: 0 },
        { json: "overrideRank", js: "overrideRank", typ: true },
        { json: "sourceName", js: "sourceName", typ: "" },
        { json: "sourceNumber", js: "sourceNumber", typ: 0 },
        { json: "isPromotion", js: "isPromotion", typ: true },
        { json: "modeOfSaleId", js: "modeOfSaleId", typ: 0 },
        { json: "overrideRankIndicator", js: "overrideRankIndicator", typ: null },
        { json: "promoCode", js: "promoCode", typ: '' },
        { json: "promotionDate", js: "promotionDate", typ: null },
        { json: "source", js: "source", typ: r("Source") },
        { json: "text1", js: "text1", typ: '' },
        { json: "text2", js: "text2", typ: '' },
        { json: "text3", js: "text3", typ: '' },
        { json: "text4", js: "text4", typ: null },
        { json: "text5", js: "text5", typ: null },
        { json: "text6", js: "text6", typ: null },
    ], false),
    "Source": o([
        { json: "description", js: "description", typ: "" },
        { json: "id", js: "id", typ: 0 },
        { json: "inactive", js: "inactive", typ: true },
        { json: "appeal", js: "appeal", typ: u(undefined, r("Source")) },
    ], false),
    "Production": o([
        { json: "title", js: "title", typ: "" },
        { json: "url", js: "url", typ: u(undefined, "") },
        { json: "startDate", js: "startDate", typ: Date },
        { json: "endDate", js: "endDate", typ: Date },
        { json: "productionImage", js: "productionImage", typ: u(undefined, r("ProductionImage")) },
        { json: "description", js: "description", typ: "" },
        { json: "specialDates", js: "specialDates", typ: u(undefined, "") },
        { json: "prodNumber", js: "prodNumber", typ: 0 },
        { json: "season", js: "season", typ: 0 },
        { json: "facility", js: "facility", typ: "" },
        { json: "seatMethods", js: "seatMethods", typ: a("") },
        { json: "isOnSale", js: "isOnSale", typ: true },
        { json: "performances", js: "performances", typ: a(r("Performance")) },
        { json: "allDayEvent", js: "allDayEvent", typ: true },
    ], false),
    "Performance": o([
        { json: "perfNo", js: "perfNo", typ: 0 },
        { json: "perfCode", js: "perfCode", typ: "" },
        { json: "date", js: "date", typ: Date },
        { json: "isOnSale", js: "isOnSale", typ: true },
        { json: "availability", js: "availability", typ: u(undefined, m(0)) },
        { json: "onSaleStart", js: "onSaleStart", typ: u(undefined, Date) },
        { json: "keywords", js: "keywords", typ: u(undefined, a(r("Keyword"))) },
    ], false),
    "Keyword": o([
        { json: "keyword", js: "keyword", typ: u(undefined, "") },
        { json: "keywordId", js: "keywordID", typ: u(undefined, 0) },
        { json: "ownerId", js: "ownerID", typ: u(undefined, 0) },
        { json: "categoryId", js: "categoryID", typ: u(undefined, 0) },
        { json: "category", js: "category", typ: u(undefined, "") },
    ], false),
    "ProductionImage": o([
        { json: "src", js: "src", typ: "" },
        { json: "height", js: "height", typ: 0 },
        { json: "width", js: "width", typ: 0 },
        { json: "title", js: "title", typ: "" },
    ], false),
    "PerfDetail": o([
        { json: "priceTypes", js: "priceTypes", typ: a(r("PriceType")) },
        { json: "zones", js: "zones", typ: a(r("Zone")) },
    ], false),
    "PriceType": o([
        { json: "id", js: "id", typ: 0 },
        { json: "title", js: "title", typ: "" },
        { json: "maxSeats", js: "maxSeats", typ: 0 },
    ], false),
    "Zone": o([
        { json: "zoneNumbers", js: "zoneNumbers", typ: a(0) },
        { json: "title", js: "title", typ: "" },
        { json: "color", js: "color", typ: "" },
        { json: "prices", js: "prices", typ: a(0) },
        { json: "seats", js: "seats", typ: a(0) },
    ], false),
    "SeatResult": o([
        { json: "success", js: "success", typ: true },
        { json: "message", js: "message", typ: "" },
    ], false),
    "WheelchairSeat": o([
        { json: "wheelchair", js: "wheelchair", typ: 0 },
        { json: "companions", js: "companions", typ: a(0) },
    ], false),
    "CartResponse": o([
        { json: "tessSession", js: "tessSession", typ: u(undefined, r("TessSession")) },
        { json: "cart", js: "cart", typ: u(undefined, r("Cart")) },
    ], false),
    "Cart": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "orderDateTime", js: "orderDateTime", typ: u(undefined, Date) },
        { json: "firstSeatAddedDateTime", js: "firstSeatAddedDateTime", typ: u(undefined, Date) },
        { json: "cartPrimaryAmount", js: "cartPrimaryAmount", typ: u(undefined, 3.14) },
        { json: "cartFirstChoiceAmount", js: "cartFirstChoiceAmount", typ: u(undefined, 3.14) },
        { json: "cartAmount", js: "cartAmount", typ: u(undefined, 3.14) },
        { json: "amountPaidPreviously", js: "amountPaidPreviously", typ: u(undefined, 0) },
        { json: "amountPaidNow", js: "amountPaidNow", typ: u(undefined, 0) },
        { json: "balanceToCharge", js: "balanceToCharge", typ: u(undefined, 3.14) },
        { json: "subTotal", js: "subTotal", typ: u(undefined, 0) },
        { json: "feesAmount", js: "feesAmount", typ: u(undefined, 3.14) },
        { json: "cartWasPriced", js: "cartWasPriced", typ: u(undefined, true) },
        { json: "constituent", js: "constituent", typ: u(undefined, r("Constituent")) },
        { json: "modeOfSale", js: "modeOfSale", typ: u(undefined, 0) },
        { json: "source", js: "source", typ: u(undefined, 0) },
        { json: "appeal", js: "appeal", typ: u(undefined, 0) },
        { json: "products", js: "products", typ: u(undefined, a(r("Product"))) },
        { json: "deliveryMethods", js: "deliveryMethods", typ: u(undefined, a(r("DeliveryMethod"))) },
        { json: "customData", js: "customData", typ: u(undefined, a(r("CustomData"))) },
        { json: "payments", js: "payments", typ: u(undefined, a(r("Payments"))) },
    ], false),
    "CheckoutResponse": o([
        { json: "tessSession", js: "tessSession", typ: u(undefined, r("TessSession")) },
        { json: "success", js: "success", typ: u(undefined, true) },
        { json: "message", js: "message", typ: u(undefined, "") },
        { json: "orderComplete", js: "orderComplete", typ: u(undefined, r("Cart")) },
    ], false),
    "DeliveryMethod": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "addressId", js: "addressId", typ: u(undefined, 0) },
        { json: "electronicAddressId", js: "electronicAddressId", typ: u(undefined, 0) },
    ], false),
    "CustomData": o([
        { json: "name", js: "name", typ: u(undefined, "") },
        { json: "index", js: "index", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "value", js: "value", typ: u(undefined, "") },
    ], true),
    "Payment": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "amount", js: "amount", typ: u(undefined, 3.14) },
        { json: "tenderedAmount", js: "tenderedAmount", typ: u(undefined, 0) },
        { json: "paymentMethod", js: "paymentMethod", typ: u(undefined, r("PaymentMethod")) },
        { json: "lastFourCreditCardNumber", js: "lastFourCreditCardNumber", typ: u(undefined, "") },
        { json: "payerName", js: "payerName", typ: u(undefined, "") },
        { json: "applied", js: "applied", typ: u(undefined, true) },
    ], false),
    "PaymentMethod": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "accountType", js: "accountType", typ: u(undefined, r("AccountType")) },
    ], false),
    "AccountType": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
    ], false),
    "Constituent": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "displayName", js: "displayName", typ: u(undefined, "") },
    ], false),
    "Product": o([
        { json: "productType", js: "productType", typ: u(undefined, "") },
        { json: "lineItems", js: "lineItems", typ: u(undefined, a(r("LineItem"))) },
        { json: "id", js: "id", typ: u(undefined, "") },
    ], false),
    "LineItem": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "source", js: "source", typ: u(undefined, 0) },
        { json: "specialRequest", js: "specialRequest", typ: u(undefined, r("SpecialRequest")) },
        { json: "totalDue", js: "totalDue", typ: u(undefined, 0) },
        { json: "dueAmount", js: "dueAmount", typ: u(undefined, 0) },
        { json: "subLineItems", js: "subLineItems", typ: u(undefined, a(r("SubLineItem"))) },
    ], false),
    "SpecialRequest": o([
    ], false),
    "SubLineItem": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "priceTypeId", js: "priceTypeId", typ: 0 },
        { json: "priceTypeTitle", js: "priceTypeTitle", typ: "" },
        { json: "zoneNumber", js: "zoneNumber", typ: u(undefined, 0) },
        { json: "seat", js: "seat", typ: u(undefined, r("Seat")) },
        { json: "dueAmount", js: "dueAmount", typ: u(undefined, 0) },
        { json: "paidAmount", js: "paidAmount", typ: u(undefined, 0) },
        { json: "subLineItemFees", js: "subLineItemFees", typ: u(undefined, a(r("SubLineItemFee"))) },
    ], false),
    "Seat": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "row", js: "row", typ: u(undefined, "") },
        { json: "number", js: "number", typ: u(undefined, "") },
        { json: "section", js: "section", typ: u(undefined, r("Section")) },
    ], false),
    "Section": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "shortDescription", js: "shortDescription", typ: u(undefined, "") },
        { json: "printDescription", js: "printDescription", typ: u(undefined, "") },
    ], false),
    "SubLineItemFee": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "lineItemId", js: "lineItemID", typ: u(undefined, 0) },
        { json: "subLineItemId", js: "subLineItemID", typ: u(undefined, 0) },
        { json: "feeSummary", js: "feeSummary", typ: u(undefined, r("FeeSummary")) },
        { json: "amount", js: "amount", typ: u(undefined, 3.14) },
        { json: "overrideIndicator", js: "overrideIndicator", typ: u(undefined, "") },
        { json: "overrideAmount", js: "overrideAmount", typ: u(undefined, 0) },
        { json: "dbStatus", js: "dbStatus", typ: u(undefined, 0) },
    ], false),
    "FeeSummary": o([
        { json: "feeId", js: "feeID", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "category", js: "category", typ: u(undefined, r("Category")) },
    ], false),
    "Category": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "description", js: "description", typ: u(undefined, "") },
    ], false),
    "LoginResult": o([
        { json: "success", js: "success", typ: true },
        { json: "message", js: "message", typ: "" },
        { json: "tessSession", js: "tessSession", typ: r("TessSession") }
    ], false),
    "Countries": o([
        { json: "defaultCountry", js: "defaultCountry", typ: u(undefined, 0) },
        { json: "defaultState", js: "defaultState", typ: u(undefined, "") },
        { json: "countries", js: "countries", typ: u(undefined, a(r("Country"))) },
    ], false),
    "Country": o([
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "states", js: "states", typ: u(undefined, a(r("State"))) },
    ], false),
    "State": o([
        { json: "code", js: "code", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
    ], false),
};
