import React, { useState } from "react";
import * as ReactScroll from 'react-scroll';

import { FigureData, Figure } from "../Figure/Figure";
import { AppRawHtml } from "App/AppRawHtml";
import { Responsive } from "Util/Responsive";
import { motion } from "framer-motion";
interface Props {
    cast: CastMember[]
}
interface CastMember {
    name: string;
    role: string;
    headshot: FigureData;
    bio: string;
}
const variants = {
    'not': { height: 0 },
    'me': { height: 'auto' }
};

export const Cast: React.FC<Props> = (props: Props) => {
    let [curr, setCurr] = useState(-1);
    const toggle = (index: number) => setCurr(curr === index ? -1 : index);
    return (
        <div className="accordion cast">
            <div className="accordion-entry" onClick={() => toggle(0)}>
                <h5>Meet the Cast
                    <i className={curr === 0 ? 'fifth-icon-minus' : 'fifth-icon-plus'} >&nbsp;</i>
                </h5>
                <motion.div variants={variants} animate={curr === 0 ? 'me' : 'not'}><CastBody {...props} /></motion.div>
            </div>
        </div>
    );
}
const CastBody: React.FC<Props> = (props: Props) => {
    const [curr, setCurr] = useState<CastMember>();

    // have to call all of these every time because they make hooks. 
    const isScreenXL = Responsive.isScreenXL();
    const isScreenLG = Responsive.isScreenLG();
    const isScreenMD = Responsive.isScreenMD();

    const colSize = isScreenXL ? 5 : isScreenLG ? 4 : isScreenMD ? 3 : 2;

    let currOrder = 0;
    let offset = 0;
    const getOrder = (who: CastMember, index: number) => {
        if (who === curr && index % colSize !== 0) {
            currOrder = index + colSize - (index % colSize);
            offset = currOrder - index;
            return currOrder;
        }
        if (currOrder > 0 && index <= currOrder) {
            return index - offset;
        }
        return index;
    }
    const setCurrCastMember = (who: CastMember) => {
        setCurr(who);
        if (who != null) {
            setTimeout(() => ReactScroll.scroller.scrollTo(who.name, {
                duration: 400,
                offset: - 12 * 16,
                smooth: 'easeInOutCubic'
            }), 350);
        }
    }

    return props && props.cast && props.cast.map ? (
        <div className="cast-members">
            {props.cast.map((who, index) =>
                <ReactScroll.Element name={who.name} className={`cast-member ${curr === who ? "at" : "not-at"}`} key={who.name}
                    style={{ order: getOrder(who, index) }}
                    onClick={(ev) => { setCurrCastMember(who); ev.stopPropagation(); }}>
                    <Figure {...who.headshot}>
                        {curr === who ? (
                            <figcaption><h6>{who.name}</h6><i>{who.role}</i><AppRawHtml>{who.bio}</AppRawHtml></figcaption>
                        ) : (<figcaption>{who.name}</figcaption>)}
                    </Figure>
                </ReactScroll.Element>
            )}
        </div>
    ) : null;
}