import React from "react"
import { UmbracoFormContext, UmbracoFormFieldset } from "./Types";
import { FormField } from "./FormField";
import { GridRow } from "Util/Grid/GridRow";
import { GridArea } from "Util/Grid/GridArea";

interface FormFieldsetPropTypes {
    formContext: UmbracoFormContext;
    fieldset: UmbracoFormFieldset;
}
export const FormFieldset: React.FC<FormFieldsetPropTypes> = (props) => {

    return (
        <fieldset className="form-fieldset">
            <legend>{props.fieldset.caption}</legend>
            <GridRow>
                {props.fieldset.containers?.map((container, i) =>
                    <GridArea key={i} size={container.width || 12}>
                        <div>
                            {container.caption && <h4>{container.caption}</h4>}
                            {
                                container.fields?.map((field, fieldIndex) => (
                                    <FormField
                                        key={field.id}
                                        formContext={props.formContext}
                                        field={field}
                                    />
                                ))
                            }
                        </div>
                    </GridArea>
                )}
            </GridRow>
        </fieldset>
    );
}