import React from "react";
import { CmsData } from "Cms/Data/CmsData";
import { RawHtml } from "Util/RawHtml";
import { useTessContext } from "Tess/Data/TessContext";
import { formatDateTime } from "Util/format";

import { usePerfSelectPopup } from "Tess/Tickets/PerfSelect/usePerfSelectPopup";
import { motion } from "framer-motion";
import { useAppConfig } from "App/AppConfig";


export default function ProductionSidebar(props: CmsData) {
    const prodNumber: number = props.properties.prodNumber;
    const { inventory } = useTessContext();
    const prod = inventory?.findProduction(prodNumber);
    const { tessConfig } = useAppConfig();
    const perfPopup = usePerfSelectPopup(inventory, `${tessConfig.tnewSiteRoot}/${props.properties.tnewUrlSlug}/`, prod, `Buy Tickets: ${prod?.title}`);
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}

            className='production-sidebar'>
            <div>
                <RawHtml tagName="h3">{props.title}</RawHtml>
            </div>
            <div>
                <div className="form-group" onClick={perfPopup}>
                    <label>Buy Tickets</label>
                    <div className="display-date">
                        <span className="xs-only sm-only md-only">{prod?.performances[0] && formatDateTime(prod.performances[0].date, 'short')}</span>
                        <span className="lg-only">{prod?.performances[0] && formatDateTime(prod.performances[0].date, 'med')}</span>
                        <span className="xl-only">{prod?.performances[0] && formatDateTime(prod.performances[0].date, 'long')}</span>
                        <i className="fifth-icon-down-arrow-small float-right" />
                    </div>
                </div>
            </div>
            <RawHtml>{props.properties.sidebar}</RawHtml>
        </motion.div>
    );
}
