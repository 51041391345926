import { CmsService } from "Cms/Data/CmsService";
import React, { useContext, useMemo, useState } from "react";
import { TessConfig } from "Tess/Data/TessConfig";
import { config } from "Util/format";
import { labels } from "Util/Labels";
import { ContentConfig } from "../Cms/Data/CmsData";
// import { NavConfig } from "./AppHeader/NavConfig";
import { GlobalMessageEntry } from "../Util/GlobalMessage/Types";
import { NavConfig } from "./AppHeader/NavConfig";

declare global {
    interface Window {
        siteUrl?: string;       // where the react is hosted
    }
}

/**
 * on localhost:44353
 *      appConfig.siteUrl           where the site lives            localhost:44353
 *      appConig.currUrl            current browser url             localhost:44353
 *      contentConfig.url           content url                     localhost:44353
 *
 * on localhost:3000
 *      appConfig.siteUrl           where the site lives            localhost:3000
 *      appConig.currUrl            current browser url             localhost:3000
 *      contentConfig.url           content url                     dev.5thavenue.org
 *
 * on dev.5thavenue.org
 *      appConfig.siteUrl           where the site lives            dev.5thavenue.org
 *      appConig.currUrl            current browser url             dev.5thavenue.org
 *      contentConfig.url           content url                     dev.5thavenue.org
 *
 * on my.5thavenue.org
 *      appConfig.siteUrl           where the site lives            www.5thavenue.org
 *      appConig.currUrl            current browser url             my.5thavenue.org
 *      contentConfig.url           content url                     www.5thavenue.org
 *
*/

export interface AppConfig {
    configName: string;         // Development, Staging or Release
    siteUrl: string;            // where the site lives (www.company.com, not cart.company.com)
    currUrl: string;            // the current url of the request
    isSameSite: () => boolean;
    mangleUrl: (href: string) => string;
    globalMessages?: GlobalMessageEntry[]
    contentConfig: ContentConfig;
    navConfig?: NavConfig[];
    tessConfig: TessConfig;
    timeZone: string;
    googleAnalyticsId?: string; // UA-YOUR1KEY-HERE
}
class Config implements AppConfig {
    configName: string = '';
    siteUrl: string = process.env.REACT_APP_SITE_URL || `${window.location.protocol}//${window.location.host}`;
    currUrl: string = `${window.location.protocol}//${window.location.host}`;

    isSameSite = () => this.siteUrl === this.currUrl;
    mangleUrl = (href: string) => {
        if (this.isSameSite()) {
            if (href.indexOf(this.siteUrl) === 0) {
                return href.substr(this.siteUrl.length);
            }
        }
        else {
            if (href.indexOf('/') === 0) {
                return this.siteUrl + href;
            }
        }
        return href;
    };

    globalMessages?: GlobalMessageEntry[] | undefined;
    contentConfig: ContentConfig;
    navConfig?: NavConfig[] | undefined
    tessConfig: TessConfig;
    timeZone: string;
    googleAnalyticsId?: string | undefined;

    constructor(toClone: any) {
        if (toClone?.contentConfig) {
            Object.assign(this, toClone);
        }
        this.contentConfig = toClone?.contentConfig || { url: process.env.REACT_APP_CONTENT_URL };
        this.tessConfig = toClone?.tessConfig || { isDisabled: false };
        if (!this.tessConfig.url?.length) {
            this.tessConfig.url = process.env.REACT_APP_TESS_URL || '';
        }
        this.siteUrl = this.siteUrl || this.contentConfig?.url;
        this.timeZone = toClone.timeZone || ''
        if (this.timeZone) {
            config.timeZone = this.timeZone;
        }
    }

}

let winConfig: AppConfig = new Config(JSON.parse(document.getElementById("appConfig")?.innerHTML || '{}'));


const AppConfigContext = React.createContext<AppConfig>(winConfig);
export const useAppConfig = () => useContext<AppConfig>(AppConfigContext);

function mergeConfig(old: AppConfig, incoming: AppConfig): AppConfig {
    Object.assign(incoming.contentConfig, old.contentConfig);
    Object.assign(incoming.tessConfig, old.tessConfig);
    return Object.assign({}, old, incoming);
}

export const AppConfigProvider: React.FC = (props) => {
    const [config, setConfig] = useState<AppConfig>(winConfig);
    const [labelsLoaded, setLabelsLoaded] = useState<boolean>(!!labels.tryGetLabel('global'));
    useMemo(() => {
        const cmsService = new CmsService(config.contentConfig);
        if (!config.navConfig) {
            cmsService.fetchJson({
                request: '/reactAppConfig'
            }).then(data => setConfig(mergeConfig(config, data)));
        }
        if (!labelsLoaded) {
            cmsService.fetchJson({
                request: '/content/labels',
            }).then(data => { labels.merge(data); setLabelsLoaded(true); })
        }

    }, [config, labelsLoaded]);
    return config && labelsLoaded ? (
        <AppConfigContext.Provider value={config}>
            {props.children}
        </AppConfigContext.Provider>)
        : null;
}
