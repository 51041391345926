import React, { useState } from "react";

import './accordion.scss';
import { AppRawHtml } from "App/AppRawHtml";
import { motion } from "framer-motion";

export interface AccordionData {
    entries: AccordionEntryData[];
}
export interface AccordionEntryData {
    key?: string;
    title: string;
    text?: string;
    body?: any;
}

export const Accordion: React.FC<AccordionData> = (props: AccordionData) => {
    let [curr, setCurr] = useState(-1);
    const toggle = (index: number) => setCurr(curr === index ? -1 : index);
    return (
        <div className="accordion">
            {props.entries && props.entries.map((entry, index) =>
                <AccordionEntry key={entry.key || index} {...entry}
                    onToggle={() => toggle(index)}
                    isOpen={curr === index}
                >{entry.body}</AccordionEntry>
            )}
        </div>
    );
}


interface AccordionEntryPropTypes {
    title: string;
    text?: string;
    isOpen?: boolean;
    onToggle?: (newState: boolean) => void;
}
/**
 * if we pass in isOpen, then it will take charge; else the isOpenState will take charge
 * @param props 
 */
export const AccordionEntry: React.FC<AccordionEntryPropTypes> = (props) => {
    const [isOpenState, setIsOpenState] = useState<boolean>(false);

    const isOpen = props.isOpen !== undefined ? props.isOpen : isOpenState;

    const toggle = () => {
        setIsOpenState(!isOpenState);
        props.onToggle && props.onToggle(!isOpen);
    }
    const animate = isOpen
        ? { height: 'auto', opacity: 1 }
        : { height: 0, opacity: 0 };
    return (
        <div className="accordion-entry">
            <div>
                <h5 onClick={() => toggle()}>{props.title}
                    <i className={isOpen ? 'fifth-icon-minus' : 'fifth-icon-plus'} >&nbsp;</i>
                </h5>
                <motion.div animate={animate}
                    transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {props.text && <AppRawHtml>{props.text}</AppRawHtml>}
                    {isOpen && props.children}
                </motion.div>
            </div>
        </div>
    );
}