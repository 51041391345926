import React from "react";
import { formatString } from "Util/format";
import { RawHtml } from "Util/RawHtml";
import { devLabels } from "./devLabels";

export interface LabelDefinition extends Record<string, any> {
};

const allLabels: LabelDefinition = devLabels;

const mergeLabels = (incoming: LabelDefinition, existing?: LabelDefinition) => {
    if (!existing) {
        return incoming;
    }
    Object.keys(incoming).forEach((key: string) => {
        let oldVal: any = existing[key];
        let newVal: any = incoming[key];

        switch (typeof (newVal)) {
            case 'string':
                if (typeof (oldVal) == 'object') {
                    throw new Error("Can't assign object to string for label " + key);
                }
                else {
                    existing[key] = newVal;
                }
                break;
            case 'object':
                if (typeof (oldVal) == 'string') {
                    throw new Error("Can't assign string to object");
                }
                else {
                    existing[key] = mergeLabels(newVal, oldVal as LabelDefinition);
                }
                break;
        }
    });
    return existing;
}
function merge(incoming: any) {
    mergeLabels(incoming as LabelDefinition, allLabels);
}

function getLabel(key: string): (string | LabelDefinition) {
    let result = tryGetLabel(key);
    if (result === undefined) {
        console.warn('Missing label ' + key);
        return '';
    }
    else {
        return result;
    }

}
function tryGetLabel(key: string): (string | LabelDefinition | undefined) {
    let result: (string | LabelDefinition);
    result = allLabels;
    key.split('.').forEach(k => {
        if (typeof result === 'object') {
            result = result[k];
            if (!result) {
                return undefined;
            }
        }
    });
    return result;
}
function getString(key: string, arg?: any) {
    var label = getLabel(key).toString();
    return arg ? formatString(label, arg) : label;
}
function getHtml(key: string, arg?: any) {
    return <RawHtml>{getString(key, arg)}</RawHtml>
}
function getObject(key: string) {
    var label = getLabel(key);
    if (typeof label === 'string') {
        console.error('Label type mismatch' + key + ' is not an object.');
    }
    return label as LabelDefinition;
}
export const labels = {
    all: allLabels,
    merge,
    tryGetLabel,
    getLabel,
    getString,
    getHtml,
    getObject
}
