import React from "react";
import ReactPlayer from "react-player";
import './embed.scss';

interface EmbedData {
    constrain: boolean;
    height: number;
    width: number;
    info: string;
    preview: string;
    url: string;
}

export default function Embed(props: EmbedData) {
    var { url } = props;
    return (
        <div>
            <div className="embed" style={{ position: 'relative', paddingTop: `${100 / (props.width / props.height)}%` }}>
                <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    controls
                />
            </div>
        </div>
    );
}
