import React from 'react';
import { CmsData } from '../Data/CmsData';
import { CmsGrid } from '../Components/Grid/CmsGrid';
import { AppMain } from '../../App/AppMain/AppMain';
import { Tnew } from 'Cms/Components/Tnew/Tnew';

export const TnewPage: React.FC<CmsData> = (props) => {
    const properties = props.properties;
    console.log("TnewPage props ", props);
    if (properties) {
        return (
            <AppMain
                hideBreadcrumbs={true}
                contentType={props.contentType}
                hero={props.heroGrid ? <CmsGrid grid={props.heroGrid} /> : null}
                body={<>
                    {props.bodyGrid ? <CmsGrid grid={props.bodyGrid} /> : null}
                    <Tnew {...props} />

                </>}
            />
        );
    }
    else if (!props.id) {
        // loading... 
    }
    return (
        <div className="err">Content not found</div>
    );
}