import React from "react"
import { FormFieldPropTypes, getFieldCaption } from "../Types";

export const Textarea: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const err = props.formContext.errors[field.id];
    return (
        <div className={`form-group textarea${err ? ' invalid' : ''}`}>
            <label>{getFieldCaption(field, props.formContext)}</label>
            <textarea name={field.id}
                placeholder={field.settings?.placeholder}
                defaultValue={props.formContext.formResponses[field.id]}
                ref={props.formContext.registerField(field)}
            ></textarea>
        </div>
    );
}