import { useAppConfig } from "App/AppConfig";
import { Image } from "Cms/Data/CmsData";
import React from "react";
interface SponsorsFooterPropTypes {
    sponsors?: Image[];
}
export const SponsorsFooter: React.FC<SponsorsFooterPropTypes> = ({ sponsors }) => {
    const appConfig = useAppConfig();
    return sponsors && sponsors.length ? (
        <section className="sponsors-footer">
            <h3>Season Sponsors</h3>
            <div className="row">
                {
                    sponsors.filter(sponsor => sponsor?.src).map(sponsor =>
                        <div className="sponsor col" key={sponsor.link}>
                            {sponsor.link
                                ? <a href={sponsor.link} target="_blank" rel="noopener noreferrer"><img src={appConfig.mangleUrl(sponsor.src)} alt={sponsor.title} /></a>
                                : <img src={appConfig.mangleUrl(sponsor.src)} alt={sponsor.title} />
                            }

                        </div>
                    )
                }
            </div>
        </section>
    ) : null;
}