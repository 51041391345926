import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { Loading } from "App/Loading/Loading";

import { CmsData } from "./CmsData";
import { useCmsService } from "./CmsService";

declare global {
    interface Window { tnew: any; }
}

const loadingData: CmsData = {
    url: '',
    isLoading: true,
    id: 0,
    contentType: '',
    title: '',
    path: [],
};

export const CmsContext = React.createContext<CmsData>(loadingData);
export const useCmsContext = () => useContext<CmsData>(CmsContext);

interface CmsContextPropTypes {
    onContentClick?: (e: React.MouseEvent) => void; // handler for RawHtml clicks.
}
const useTnewOrPathname = () => {
    const location = useLocation();
    return window["tnew"]
        ? `/tnew${location.pathname}`
        : location.pathname;
}
export const CmsContextProvider: React.FC<CmsContextPropTypes> = (props) => {
    const { onContentClick } = props;
    const [pageData, setPageData] = useState<CmsData>(loadingData);
    const cmsService = useCmsService();
    const pathname = useTnewOrPathname();

    useEffect(() => {
        if (pageData.url !== pathname) {
            // console.log('CmsContext', pageData.url, pathname);
            const abortController: AbortController = new window.AbortController();
            if (pageData.isLoading) {
                cmsService
                    .fetchPageData(pathname, abortController)
                    .then(data => setPageData(data));
                return () => abortController.abort();
            }
            else {
                setPageData({ ...loadingData, isLoading: true })
                return () => { };
            }


        }
    }, [pathname, pageData, cmsService, onContentClick, setPageData, pageData.isLoading])
    return <CmsContext.Provider
        value={{
            ...pageData,
            onContentClick
        }}

    >{pageData ? props.children : <Loading />}</CmsContext.Provider>
}
