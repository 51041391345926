import React from "react";

import * as GridControls from "../";
import { GridData, SectionData, RowData, AreaData, ControlData } from "../../Data/CmsData";
import { AppRawHtml } from "App/AppRawHtml";
import { GridArea } from "Util/Grid/GridArea";
import { GridRow } from "Util/Grid/GridRow";
import { GridSection } from "Util/Grid/GridSection";


export const CmsGrid: React.FC<{ grid?: GridData }> = ({ grid }) => (
    <>{grid && grid.sections && grid.sections.map((section: SectionData, sectionIndex: number) =>
        <GridSection key={1 * sectionIndex}>
            {section.rows?.map((row: RowData, rowIndex: number) => {
                let className = 'row';
                if (row.name) {
                    className += ` row-${row.name.replace(/[^a-zA-Z0-9]{1,}/gi, "-").toLowerCase()}`;
                }
                if (row.config?.center === "1") {
                    className += ' row-centered'
                }
                return (
                    <GridRow key={10 * rowIndex} className={className}>
                        {row.areas?.map((area: AreaData, areaIndex: number) => {
                            const grid = parseInt(area.grid.toString());
                            return area.controls && area.controls.length ? (
                                <GridArea size={grid} key={100 * areaIndex}>
                                    {area.controls?.map((ctrl: ControlData, ctrlIndex: number) => (
                                        <GridControl
                                            {...ctrl}
                                            grid={grid}
                                            key={ctrlIndex}
                                            index={ctrlIndex}
                                        />
                                    ))}
                                </GridArea>
                            ) : null;
                        })}
                    </GridRow>
                )
            })}
        </GridSection>
    )}
    </>
);

function GridControl(props: ControlData) {
    if (props) {
        const name = props.alias as keyof typeof GridControls;
        if (name) {
            var Control = GridControls[name];
            if (Control != null) {
                try {
                    return <Control {...props.value} grid={props.grid} index={props.index} />;
                }
                catch (e) {
                    return <div className="err">There was an error displaying the content.</div>
                }
            }
            return <AppRawHtml>{JSON.stringify(props)}</AppRawHtml>
        }
    }
    return null;
}