import React from "react"
import { fields } from "./Fields";
import { FormFieldPropTypes } from "./Types";


type FieldType = keyof typeof fields;

/*****
 * This does the dispatching from FormFieldPropTypes to a field control located in ./Fields
 * checks the fieldType.Name and locates a field control with the same name.
 */
export const FormField: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const Input = fields[field.fieldType?.name as FieldType];
    return Input
        ? <Input {...props} />                      // forward the props onto the field
        : <div>{JSON.stringify(field)}</div>        // spew some JSON as a fallback

}