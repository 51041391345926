import { AjaxService } from "Util/Ajax";
import { useAppConfig } from "App/AppConfig";
import { TessConfig } from "Tess/Data/TessConfig";
import { TessContext } from "Tess/Data/TessContext";
import { TessSession, Countries } from "Tess/Data/User";
import { Convert } from "Tess/Data/Convert";

export const useTessUserService = () => {
    const { tessConfig } = useAppConfig();
    return new TessUserService(tessConfig);
}

export class TessUserService extends AjaxService {

    constructor(tessConfig: TessConfig) {
        super(tessConfig.url);
    }
    async loginExists(username: string): Promise<boolean> {
        const data = await this.postJson({
            request: '/user/loginExists',
            data: username
        });
        return (data && data.result);
    }
    async login(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.postJson({
                    request: `/user/login`,
                    abortController: new AbortController(),
                    tessSession,
                    data: form
                });
                const result = Convert.toTessSession(data);
                if (result.isLoggedIn) {
                    reloadSession({ tessSession: result });
                    return result;
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessSession;
    }
    async sendToken(email: string): Promise<boolean> {
        const data = await this.postJson({
            request: '/user/sendToken',
            data: email
        });
        return (data && data.result);
    }
    async loginViaToken(tessContext: TessContext, email: string, token: string): Promise<TessSession | undefined> {
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.postJson({
                    request: `/user/loginViaToken`,
                    abortController: new AbortController(),
                    tessSession,
                    data: { email, token }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }

    async logout(tessContext: TessContext): Promise<any> {
        const { tessSession, reloadSession } = tessContext;
        if (!tessSession) {
            return;
        }
        try {
            const data = await this.fetchJson({
                request: `/user/logout`,
                tessSession,
                options: {
                    method: 'post',
                }
            });
            const result = Convert.toTessSession(data);
            reloadSession({ tessSession: result });
            return true;
        }
        catch (err) {
            console.error(err);
            reloadSession();
            return true;
        }
    }
    async updateLogin(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.postJson({
                    request: `/user/updateLogin`,
                    abortController: new AbortController(),
                    tessSession,
                    data: form
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }

    async register(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const requestBody = JSON.stringify(form);
        const abortController = new AbortController();
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.fetchJson({
                    request: `/user/register`,
                    abortController,
                    tessSession,
                    options: {
                        method: 'post',
                        body: requestBody
                    }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }

    private static _countries: Countries | undefined;
    async fetchCountries(): Promise<Countries> {
        if (!TessUserService._countries) {
            const data = await this.fetchJson({
                request: `/user/countries`,
                options: {
                    method: 'get'
                }
            });
            TessUserService._countries = Convert.toCountries(data);
        }
        return TessUserService._countries;
    }

    async addAddress(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const requestBody = JSON.stringify(form);
        const abortController = new AbortController();
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.fetchJson({
                    request: `/user/addAddress`,
                    abortController,
                    tessSession,
                    options: {
                        method: 'post',
                        body: requestBody
                    }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }
    async updateAddress(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const requestBody = JSON.stringify(form);
        const abortController = new AbortController();
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.fetchJson({
                    request: `/user/updateAddress`,
                    abortController,
                    tessSession,
                    options: {
                        method: 'post',
                        body: requestBody
                    }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }
    async removeAddress(tessContext: TessContext, form: any): Promise<TessSession | undefined> {
        const requestBody = JSON.stringify(form);
        const abortController = new AbortController();
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.fetchJson({
                    request: `/user/removeAddress`,
                    abortController,
                    tessSession,
                    options: {
                        method: 'post',
                        body: requestBody
                    }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }

    async fetchUpcomingPerformances(): Promise<any[]> {
        const data = await this.fetchJson({
            request: `/user/upcomingPerformances`,
            options: {
                method: 'get'
            }
        });
        return data as any[];
    }

    async fetchPerformanceHistory(): Promise<any[]> {
        const data = await this.fetchJson({
            request: `/user/performanceHistory`,
            options: {
                method: 'get'
            }
        });
        return data as any[];
    }

    async fetchContributionHistory(): Promise<any[]> {
        const data = await this.fetchJson({
            request: `/user/contributionHistory`,
            options: {
                method: 'get'
            }
        });
        return data as any[];
    }
}
