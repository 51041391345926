import React, { useState, useEffect, useMemo } from "react";
import { useFormContext } from 'react-hook-form';
import { labels } from "Util/Labels";
import { Loading } from "App/Loading/Loading";
import { Country, State, Countries } from "Tess/Data/User";
import { useTessUserService } from "./TessUserService";

export const AddressForm: React.FC<any> = (props: any) => {
    const { register, getValues, errors } = useFormContext();
    const tessUserService = useTessUserService();

    const [countries, setCountries] = useState<Countries>();
    const countryId = parseInt(getValues()['country'] || props.country) || 1;

    const [country, setCountry] = useState<Country | undefined>();

    useEffect(() => {
        if (!country) {
            const found = countries ? countries.countries?.find(c => c.id === countryId) : undefined
            if (found) {
                setCountry(found);
            }
        }
    }, [country, countries, countryId])

    useMemo(() => {
        if (!countries) {
            tessUserService.fetchCountries().then(setCountries);
        }
    }, [countries, tessUserService]);

    return countries ? (
        <form className={'grid'}>
            <fieldset>
                <div className="row">
                    <div className="col">
                        <div className={`form-group text${errors.street1 ? ' invalid' : ''}`}>
                            {errors.street1 ? <label>{labels.getString("user.street1.invalid")}</label> : <label>{labels.getString("user.street1.label")}</label>}
                            <input autoFocus type="text" name="street1" defaultValue={props.street1} ref={register({ required: true })} />
                        </div>
                    </div>

                    <div className="col">
                        <div className={`form-group text${errors.street2 ? ' invalid' : ''}`}>
                            {errors.street2 ? <label>{labels.getString("user.street2.invalid")}</label> : <label>{labels.getString("user.street2.label")}</label>}
                            <input type="text" name="street2" defaultValue={props.street2} ref={register} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className={`form-group text${errors.city ? ' invalid' : ''}`}>
                            {errors.city ? <label>{labels.getString("user.city.invalid")}</label> : <label>{labels.getString("user.city.label")}</label>}
                            <input type="text" name="city" defaultValue={props.city} ref={register({ required: true })} />
                        </div>
                    </div>

                    <div className="col">
                        <div className={`form-group text${errors.country ? ' invalid' : ''}`}>
                            {errors.country ? <label>{labels.getString("user.country.invalid")}</label> : <label>{labels.getString("user.country.label")}</label>}
                            <select name="country" defaultValue={countryId} ref={register({ required: true })} onChange={() => setCountry(undefined)}>
                                {
                                    countries.countries?.map((country: Country, index: number) => {
                                        return (<option value={country.id} key={index}>{country.description}</option>);
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className={`form-group text${errors.state ? ' invalid' : ''}`}>
                            {errors.state ? <label>{labels.getString("user.state.invalid")}</label> : <label>{labels.getString("user.state.label")}</label>}
                            <select name="state" defaultValue={props.state} ref={register} style={{ width: '100%' }}>
                                {
                                    country?.states?.map((state: State, index: number) => {
                                        return (<option value={state.stateCode} key={index}>{state.description}</option>);
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col">
                        <div className={`form-group text${errors.postalCode ? ' invalid' : ''}`}>
                            {errors.postalCode ? <label>{labels.getString("user.postalCode.invalid")}</label> : <label>{labels.getString("user.postalCode.label")}</label>}
                            <input type="text" name="postalCode" defaultValue={props.postalCode} ref={register({ required: true })} />
                        </div>
                    </div>
                </div>
            </fieldset>
            {props.children}
        </form>
    ) : <Loading />;
}