import React from "react";

interface SectionProps {
    className?: string;
    children: any;
}
export const GridSection: React.FC<SectionProps> = (props) =>
    <section className={`grid-section ${props.className ? props.className : ''}`}>
        {props.children}
    </section>
