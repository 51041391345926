import React from "react"
import * as ReactScroll from 'react-scroll';
import { UmbracoFormContext } from "./Types";
import { FormFieldset } from "./FormFieldset";
import { Button } from "Util/Forms/Button";

import './forms.scss';

interface FormPagePropTypes extends UmbracoFormContext {
    submitForm: (pageResponses: Record<string, object>) => Promise<void>;
}

/** 
 * represents one page of a multi-page form
 */
export const FormPage: React.FC<FormPagePropTypes> = (formContext) => {
    const { handleSubmit } = formContext;


    const isLastPage = formContext.pageNumber >= formContext.form.pages.length - 1;

    const onNextPage = handleSubmit(async (data: Record<string, any>) => {
        formContext.savePageResponses(data);
        formContext.setPageNumber(formContext.pageNumber + 1);
        ReactScroll.scroller.scrollTo(`form_${formContext.form.formId}`, { offset: -80, duration: 800, smooth: 'easeInOutCubic' });
    });

    const onPrevPage = async () => {
        formContext.savePageResponses(formContext.getValues());
        formContext.setPageNumber(formContext.pageNumber - 1);
        ReactScroll.scroller.scrollTo(`form_${formContext.form.formId}`, { offset: -80, duration: 800, smooth: 'easeInOutCubic' });
    }
    const onSubmit = handleSubmit(async (data: Record<string, any>) => {
        ReactScroll.animateScroll.scrollToTop({ duration: 800, smooth: 'easeInOutCubic' });
        await formContext.submitForm(data);
    });
    return (

        <div className="form-page">
            <form>
                {formContext.formPage.fieldSets?.map(fieldset => (
                    <FormFieldset
                        key={fieldset.id}
                        formContext={formContext}
                        fieldset={fieldset}
                    />
                ))}
                <div className="buttons">

                    {!isLastPage && <Button className="btn" type="submit"
                        onClick={onNextPage}
                        validationErrors={formContext.errors}
                    >{formContext.form.nextLabel || "Next"} <i className="fifth-icon-right-arrow-small" /></Button>}
                    {isLastPage && <Button className="btn" type="submit"
                        onClick={onSubmit}
                        validationErrors={formContext.errors}
                    >{formContext.form.submitLabel || "Submit"} <i className="fifth-icon-right-arrow-small" /></Button>}
                    {formContext.pageNumber > 0 && <Button className="btn btn-clear" type="submit"
                        onClick={onPrevPage}
                        validationErrors={formContext.errors}
                    ><i className="fifth-icon-left-arrow-small" /> {formContext.form.prevLabel || "Back"}</Button>}
                </div>
            </form>
        </div>
    );
}