import { useEffect } from "react";

/* usage:
    useWindowEventListener('resize', () => {alert('resize'); })
 */
export function useWindowEventListener(eventName: string, callback: (e: Event) => void) {
    useEffect(() => {
        window.addEventListener(eventName, callback);
        return () => {
            window.removeEventListener(eventName, callback);
        };
    }, [eventName, callback])
}

export function useEventListener(eventName: string, selector: string, callback: (e: Event) => void) {
    useEffect(() => {
        const el = document.querySelector(selector);
        el && el.addEventListener(eventName, callback);
        return () => {
            el && el.removeEventListener(eventName, callback);
        };
    }, [eventName, callback, selector])
}