import React, { useState } from "react"
import { ResponsiveIndicator } from "./ResponsiveIndicator"
import { JsonDebugInfo } from "./JsonDebugInfo";
import { useAppConfig } from "App/AppConfig";
interface DebugInfoPropTypes { }
export const DebugInfo: React.FC<DebugInfoPropTypes> = (props) => {
    const appConfig = useAppConfig();
    const [showJsonData, setShowJsonData] = useState(false);
    return (
        appConfig.configName === "Release" ? null :
            <div className="debug-info">
                <ResponsiveIndicator toggle={() => setShowJsonData(!showJsonData)} />
                {showJsonData && <JsonDebugInfo />}
            </div>
    );
}