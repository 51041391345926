import React from "react";

import './breadcrumbs.scss';
import { useCmsContext } from "Cms/Data/CmsContext";
import { AppLink } from "App/AppLink";


export const Breadcrumbs: React.FC<{}> = () => {
    const { path } = useCmsContext();
    if (path && path.length > 1) {
        return (
            <div className="breadcrumbs">
                {
                    path.map((page, index) => (
                        <span key={page.url}>
                            {(index > 0) ? "/" : ""}
                            <AppLink to={page.url}>{page.title}</AppLink>
                        </span>
                    ))
                }
            </div>
        );
    }
    return null;
}