import React from "react"
import { FormFieldPropTypes, getFieldCaption } from "../Types";

export const Textfield: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const err = props.formContext.errors[field.id];
    return (

        <div className={`form-group text${err ? ' invalid' : ''}`}>
            <label>
                {getFieldCaption(field, props.formContext)}
            </label>
            <input type="text"
                name={field.id}
                defaultValue={props.formContext.formResponses[field.id]}
                ref={props.formContext.registerField(field)}
                placeholder={field.settings?.placeholder}
            />
        </div>

    );
}