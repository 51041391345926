import React from "react"
import { FormFieldPropTypes, getFieldCaption } from "../Types";

export const DataConsent: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const err = props.formContext.errors[field.id];
    return (

        <div className={`form-group checkbox${err ? ' invalid' : ''}`}>
            <label>{getFieldCaption(field, props.formContext)}</label>
            <label><input type="checkbox" name={field.id} id={field.id}
                ref={props.formContext.register({ required: field.required })}
            /><span>{field.settings?.acceptCopy}</span></label>
        </div>

    );
}
