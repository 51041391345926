import React, { useMemo, useState } from "react"
import { useForm } from 'react-hook-form';
import { labels } from "Util/Labels";
import { pickRandom } from "Util/pickRandom";
import { useTessContext } from "Tess/Data/TessContext";

import { useModalService } from "Util/Modal/Types";
import { Button } from "Util/Forms/Button";
import { useTessUserService } from "./TessUserService";

import { RegisterForm } from "./RegisterForm";
import { AppRawHtml } from "App/AppRawHtml";
// import { ResetPassword } from "./ResetPassword";

interface LoginFormPropTypes {
    allowRegister?: boolean;
    showModal?: boolean;
}

export const LoginForm: React.FC<LoginFormPropTypes> = ({ allowRegister = true, showModal = true }) => {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const tessContext = useTessContext();
    const tessUserService = useTessUserService();
    const modalService = useModalService();
    const emailPlaceholder = useMemo(() => pickRandom(labels.getString("user.username.placeholder").split(',')), []);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [email, setEmail] = useState<string>();
    const [tempEmail, setTempEmail] = useState<string>();
    const [emailExists, setEmailExists] = useState<boolean>();

    const editEmail = () => {
        setTempEmail(email);
        setEmail(undefined);
    };
    const handleEmail = handleSubmit(async ({ email }: any) => {
        const exists = await tessUserService.loginExists(email);
        setEmail(email);
        setTempEmail(undefined);
        setEmailExists(undefined);
        setEmailExists(exists);
    })
    const handleLogin = handleSubmit(async (data: any) => {
        const result = await tessUserService.login(tessContext, data);
        if (result?.isLoggedIn && showModal) {
            modalService.show(
                result.cartSummary.count > 0
                    ? labels.getString("user.loginSuccess_cart")
                    : labels.getString("user.loginSuccess_noCart"));
            setErrorMessage(undefined);
        }
        else {
            setErrorMessage(labels.getString("user.loginFailed"))
        }
    });
    // const resetPassword = async (data: any) => {
    //     if (email) {
    //         const result = await tessUserService.sendToken(email);
    //         if (result) {
    //             modalService.show({
    //                 key: 'resetPassword',
    //                 body: <ResetPassword email={email} tessUserService={tessUserService} tessContext={tessContext} modalService={modalService} />
    //             })
    //         }
    //     }
    //     else {
    //         alert("Please enter your email to reset.")
    //     }
    // };
    let intro: string;
    let form: any = (
        <form onSubmit={handleEmail}>
            <div className={`form-group text${errors.email ? ' invalid' : ''}`}>
                {errors.email
                    ? <label>{labels.getString("user.username.invalid")}</label>
                    : <label>{labels.getString("user.username.label")}</label>
                }
                <input
                    type="email"
                    name="email"
                    placeholder={emailPlaceholder}
                    defaultValue={email || tempEmail}
                    autoFocus
                    ref={register({ required: true, pattern: /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i })} />
            </div>
            <div className="buttons">
                <Button className="btn right" type="submit" validationErrors={errors}>
                    {labels.getString("user.nextButton")}
                    <i className="fifth-icon-right-arrow-filled" />
                </Button>
            </div>
        </form>);
    if (email && emailExists !== undefined) {

        switch (emailExists) {
            case true:
                intro = "<h2>Sign In</h2><p>Please enter your password to continue."
                form = (
                    <form onSubmit={handleLogin}>
                        <div className={`form-group text${errors.email ? ' invalid' : ''}`}>
                            {errors.email
                                ? <label>{labels.getString("user.username.invalid")}</label>
                                : <label>{labels.getString("user.username.label")}</label>
                            }
                            <input
                                type="email"
                                name="email"
                                readOnly
                                defaultValue={email} onClick={editEmail}
                                ref={register({ required: true })}
                            />
                        </div>
                        <div className={`form-group password${errors.password ? ' invalid' : ''}`}>
                            {errors.password
                                ? <label>{labels.getString("user.password.invalid")}</label>
                                : <label>{labels.getString("user.password.label")}</label>
                            }
                            <input autoFocus name="password" type="password"
                                ref={register({ required: true })} />
                        </div>
                        <div className="buttons">
                            <Button className="btn" type="submit" validationErrors={errors}>{labels.getString("user.loginButton")} <i className="fifth-icon-right-arrow-filled" /></Button>
                            {/* <Button className="btn btn-clear" onClick={resetPassword}><i className="fifth-icon-warning" /> Reset Password</Button> */}
                            <Button className="btn btn-clear" onClick={editEmail}><i className="fifth-icon-left-arrow-small" /> Back</Button>
                        </div>
                    </form>
                );
                break;
            case false:
                if (allowRegister) {
                    intro = "<h2>Sign Up</h2><p>First time using our site? Sign up here to purchase on our site and receive special offers and insider tips before the general public.</p>"
                    form = (<RegisterForm email={email} editEmail={editEmail} />);
                }
                else {
                    intro = "<h2>Sign In</h2><p>Email address not found.</p>"
                }
                break;
        }
    }
    else {
        intro = `<h2>${allowRegister ? 'Sign In / Sign Up' : 'Sign In'}</h2><p>Please enter your email address to continue.</p>`;
    }

    // const { bodyGrid } = useCmsContext(path);
    return (
        <>
            <AppRawHtml>{intro}</AppRawHtml>
            {errorMessage && <div className="err">{errorMessage}</div>}
            <div className="login">
                {form}
            </div>
        </>
    );

}