import React from "react"
import { Controller } from "react-hook-form";
import { FormFieldPropTypes, getFieldCaption } from "../Types";

export const CheckBoxList: React.FC<FormFieldPropTypes> = ({ field, formContext }) => {
    const err = formContext.errors[field.id];
    const collapse = (incoming: string[], name: string, checked: boolean) => {
        incoming = incoming || [];
        var index = incoming.indexOf(name);
        if (checked && index === -1) {
            return [...incoming, name];
        }
        else if (!checked && index !== -1) {
            return incoming.filter(s => s !== name);
        }
        console.log([...incoming]);
        return [...incoming];
    }
    return (
        <div className={`form-group checkbox${err ? ' invalid' : ''}`}>
            <label>{getFieldCaption(field, formContext)}</label>
            <Controller
                control={formContext.control}
                name={field.id}
                forwardRef={formContext.registerField(field)}
                defaultValue={[]}
                render={({ onChange, value, ref, name }) => (
                    <>
                        {field.preValues?.map((val, index) => (
                            <label key={field.id + "_" + val}>
                                <input
                                    type="checkbox"
                                    name={`${field.id}[${val}]`}
                                    id={field.id + "_" + val}
                                    value={val}
                                    onChange={(e) => { onChange(collapse(value, val, e.target.checked)) }}
                                /><span>{val}</span></label>
                        ))}
                    </>
                )} />
        </div>
    );
}