
import { useAppConfig } from 'App/AppConfig'
import { useEffect } from 'react'

import ReactGA from 'react-ga';
import { useHistory } from 'react-router';

export const useGoogleAnalytics = () => {
    const { configName, googleAnalyticsId } = useAppConfig();
    const { listen } = useHistory();
    useEffect(() => {
        if (googleAnalyticsId) {
            ReactGA.initialize(googleAnalyticsId, { debug: configName !== "Release", titleCase: true, gaOptions: undefined });
        }
    }, [configName, googleAnalyticsId]);

    listen((location) => {
        // console.log('tracking', location.pathname);
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return ReactGA;
}
