import React from "react"
import { useForm } from "react-hook-form";

import { Button } from "Util/Forms/Button";
import { ProtectedContentProps } from "./ProtectedContentLoader";

export const SecretCodeForm: React.FC<ProtectedContentProps> = (props) => {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const submit = handleSubmit(async (data: any) => {
        props.authorize(data);
    });
    return (
        <div className="secret-code-form">
            <h2>Secret Code</h2>
            <p>If you received a secret code, enter it here.</p>
            <form>
                <div className={`form-group text${errors.token ? ' invalid' : ''}`}>
                    {errors.secretCode
                        ? <label>Please enter code to continue</label>
                        : <label>Secret Code</label>}
                    <input type="password" name="secretCode" defaultValue="" ref={register({ required: true })} />
                </div>
                <div className="buttons">
                    <Button className="btn" type="submit" onClick={submit}>Submit<i className="fifth-icon-right-arrow-filled" /></Button>
                </div>
            </form>

        </div>
    );
}