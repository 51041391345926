import React from "react"
import { useTessUserService } from "./TessUserService";
import { useTessContext } from "Tess/Data/TessContext";

import { Button } from "Util/Forms/Button";

interface LogoutPropTypes { }
export const LogoutButton: React.FC<LogoutPropTypes> = (props) => {
    const tessUserService = useTessUserService();
    const tessContext = useTessContext();


    const logout = async () => {
        await tessUserService.logout(tessContext);
        document.location.reload();
    }

    return (
        <Button onClick={logout}>Logout</Button>
    );
}