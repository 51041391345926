import { Home } from './Home';
import { DefaultPage } from './Default';
import { CmsData } from '../Data/CmsData';
import { Production } from './Production';
import { Season } from './Season';
import { Redirect } from './Redirect';
import { PressPhotos } from './PressPhotos';
import { BlogPage } from './BlogPage';
import { SearchPage } from './Search';
import { TnewPage } from './TnewPage';
import { ProtectedPage } from './ProtectedPage';

export type TContentTemplate = React.FC<CmsData>;

export const ContentTemplates: { [name: string]: TContentTemplate } = {
    defaultPage: DefaultPage,
    home: Home,
    production: Production,
    season: Season,
    redirect: Redirect,
    pressPhotos: PressPhotos,
    blogSection: BlogPage,
    search: SearchPage,
    tnew: TnewPage,
    protectedPage: ProtectedPage
}