
import React from "react";

export interface RowProps {
    //     name?: string; // the name will be appended to row-, e.g. name='standard' will become className='row-standard'
    children?: any;
    className?: string;
}
export const GridRow: React.FC<RowProps> = (props) => {
    let className: string = 'row';
    if (props.className) {
        className += ' ' + props.className;
    }
    return <div className={className}>{props.children}</div>
}