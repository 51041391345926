import React from "react"
import { ModalEntry, ModalService, useModalService } from "./Types";
import { AppRawHtml } from "App/AppRawHtml";
import { Button } from "Util/Forms/Button";
import { useHistory } from "react-router";
import './modal.scss';
import { AnimatePresence, AnimationFeature, m as motion, MotionConfig } from "framer-motion";

interface PropTypes {
}
export const Modal: React.FC<PropTypes> = () => {
    const modalService = useModalService();
    const modal = modalService.current;



    if (!modal) {
        return null;
    }


    return (
        <div className={`modal ${modal ? 'show' : ''} ${modal?.className || ''}`} role='dialog' onClick={() => modalService.dismiss()}>
            <MotionConfig features={[AnimationFeature]}>
                <AnimatePresence>
                    {modal && <motion.div className="modal-dialog" onClick={(e) => e.stopPropagation()}
                        key="modal"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className="modal-content">
                            <ModalContent modalService={modalService} modal={modal} />
                        </div>
                    </motion.div>
                    }
                </AnimatePresence>
            </MotionConfig>
        </div >
    );
}


interface ModalContentPropTypes {
    modalService: ModalService;
    modal: ModalEntry;
}
export const ModalContent: React.FC<ModalContentPropTypes> = ({ modalService, modal }) => {
    const history = useHistory();
    if (modal.body) {
        return modal.body();
    }
    const buttons = typeof modal.button === 'string' ? [modal.button as string] : modal.button as Array<string>;

    const accept = (result: any) => {
        modalService.setResult(result);
        if (modal?.url) {
            history.push(modal.url);
        }
        return Promise.resolve();
    }
    return (
        <>
            {
                modal.title && (
                    <div className="modal-header">
                        {modal.allowClose && <button type="button" className="close" data-dismiss="modal" onClick={modalService.dismiss}>×</button>}
                        <h4 className="modal-title">{modal.title}</h4>
                    </div>
                )
            }
            <div className="modal-body">
                {!modal.title && modal.allowClose && <button type="button" className="close" data-dismiss="modal" onClick={modalService.dismiss}>×</button>}
                <AppRawHtml>{modal.text}</AppRawHtml>
            </div>
            <div className="modal-footer">
                {buttons?.map((btn, index) =>
                    <Button className={index === buttons.length - 1 ? 'btn' : 'btn-clear'} url={modal.url} onClick={() => accept(btn)}>{btn}</Button>
                )}
            </div>
        </>

    );
}