import React from 'react';
import { CmsData } from '../Data/CmsData';
import { AppMain } from '../../App/AppMain/AppMain';

import PageBanner from '../Components/PageBanner/PageBanner';
import ProductionBody from '../Components/Production/ProductionBody';
import ProductionSidebar from '../Components/Production/ProductionSidebar';
import ProductionBottom from '../Components/Production/ProductionBottom';
import { formatDateRange } from 'Util/format';

export const Production: React.FC<CmsData> = (props) => {
    if (props.title) {
        return (
            <AppMain
                contentType={props.contentType}
                hero={<PageBanner image={props.properties.productionImage} title={formatDateRange(props.properties)} />}
                body={<ProductionBody {...props} />}
                sidebar={<ProductionSidebar {...props} />}
                wideBottom={<ProductionBottom {...props} />}
            />
        );
    }
    return (
        <div className="err">Content not found</div>
    );
}