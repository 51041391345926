import React, { useState } from "react"
import { BlogEntry } from "./BlogSection";
import { GridSection } from "Util/Grid/GridSection";
import { GridRow } from "Util/Grid/GridRow";
import { GridArea } from "Util/Grid/GridArea";

interface BlogFilterPropTypes {
    title: string;
    allEntries: BlogEntry[];
    setFilteredEntries: (filteredEntries: BlogEntry[]) => void;
}
export const BlogFilter: React.FC<BlogFilterPropTypes> = (props) => {
    const { allEntries } = props;
    const ALL = "All Articles";
    const [currKeyword, setCurrKeyword] = useState<string>(ALL);
    const keywords = Array.from(new Set(allEntries.flatMap(entry => entry.keywords))).filter(kw => kw && kw.length).sort();
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrKeyword(event.target.value);
        props.setFilteredEntries(allEntries.filter(entry => event.target.value === ALL || entry.keywords?.includes(event.target.value)));
    }
    return (
        <GridSection className="blog-filter">
            <GridRow className="row-standard">
                <GridArea size={8}>
                    <div>
                        <h2>{props.title}</h2>
                        <h4>{currKeyword}</h4>
                    </div>
                </GridArea>
                <GridArea size={4}>
                    <div>
                        <div className={`form-group text`}>
                            <label>Filter Articles</label>
                            <select
                                name='blogFilter'
                                defaultValue={currKeyword}
                                onChange={onChange}
                            >
                                <option>{ALL}</option>
                                {keywords.map(keyword => <option key={keyword}>{keyword}</option>)}
                            </select>
                        </div>
                    </div>
                </GridArea>
            </GridRow>
        </GridSection>
    );
}