import React from "react";
import { useLocation } from 'react-router-dom';
import { AppLink } from "App/AppLink";
import { Megamenu } from "./Megamenu";
import { NavConfig } from "./NavConfig";
import './t1.scss';



interface PropTypes {
    t1: NavConfig;
    currT1?: NavConfig;
    setCurrT1: ((t1: NavConfig | undefined) => void);
}

export function T1(props: PropTypes) {
    const { t1, currT1, setCurrT1 } = props;

    const navClick = (event: React.MouseEvent) => {
        if (!currT1) {
            setCurrT1(t1);
        }
        else if (t1 === currT1) {
            setCurrT1(undefined);
        }
        else {
            // we have to close the currT1 then open ourselves
            setCurrT1(undefined);
            setTimeout(() => setCurrT1(t1), 500);
        }
        event.preventDefault();
    };
    const killMouseDown = (event: React.MouseEvent) => {
        if (t1 === currT1) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
    const isExpanded = currT1 === t1;

    const path = useLocation().pathname + '/'; // stick a slash at the end in case the location is /shows it will match /shows/
    const isActive = t1.url.find(t1url => path.indexOf(t1url) === 0) ? true : false;
    const className = isExpanded ? 't1 open' : isActive && !currT1 ? 't1 at' : 't1';

    const titleSpace = t1.title.indexOf(' ');
    const titleWord = titleSpace === -1 ? t1.title : t1.title.substr(0, titleSpace);
    const titleWords = titleSpace === -1 ? null : t1.title.substr(titleSpace)
    return (<li className={isExpanded ? 'nav-item show' : 'nav-item'}>
        <AppLink
            to={t1.url[0]}
            className={className}
            id={t1.id}
            onMouseDown={killMouseDown}
            onClick={navClick}
        ><span>{titleWord}</span><span className="title-words"> {titleWords}</span></AppLink>
        <Megamenu t1={t1} isExpanded={isExpanded} />
    </li>);
}