import React from "react";
import { Responsive } from "Util/Responsive";


import './figure.scss';
import { FigureData } from "Util/FigureData";
import { useAppConfig } from "App/AppConfig";


interface SrcLookup {
    [key: number]: string;
}

export type { FigureData };

export interface FigureDataWithSrcSet extends FigureData {
    srcSet?: SrcLookup;
}


/** forwards the ref to the <figure> element */
export const Figure = React.forwardRef<HTMLElement, FigureDataWithSrcSet>((props, ref) => {
    const { mangleUrl } = useAppConfig();
    const getSrc = (props: FigureDataWithSrcSet) => {
        let { src, srcSet, grid } = props;
        if (srcSet) {
            src = srcSet[grid || 1] || Object.values(srcSet)[0];
        }
        return mangleUrl(src);
    }
    const getSrcSet = (breakpoints: number[], baseSrc: string, width: number, height: number) => {
        if (baseSrc && baseSrc.length) {
            const pieces = baseSrc.split('?');
            const path = pieces[0];
            const qs = new URLSearchParams(pieces[1]);
            width = parseInt('' + qs.get("width")) || width;
            height = parseInt('' + qs.get("height")) || height;
            return breakpoints.map(w => {
                qs.set('width', String(w));
                qs.set('height', String(w / width * height))
                return `${path}?${qs.toString()} ${w}w`
            });
        }
        return [];
    }
    const srcSet = props && props.srcSet
        ? getSrcSet(
            [Responsive.XXL, Responsive.LG, Responsive.MD, Responsive.SM, Responsive.XS],
            getSrc(props),
            props.width, props.height)
        : [];
    const lowRes = srcSet && srcSet.length ? srcSet[srcSet.length - 1] : props.src;
    return (
        <figure ref={ref} style={props.style}>
            <img src={lowRes} srcSet={srcSet.join(',')} alt={props.title} />
            {props.children}
        </figure>
    );
});
