import React from "react"

import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'



import './mini-calendar.scss';
import { CalendarPropTypes, getCalendarEvents, getValidRange } from "./CalendarData";
import { useHistory } from "react-router";

export const MiniCalendar: React.FC<CalendarPropTypes> = (props) => {

    const events = getCalendarEvents(props.perfs, false, props.baseUrl);
    const dateRange = getValidRange(events);
    const history = useHistory();
    const eventClick = (ev: EventClickArg) => {
        if (props.eventClick) {
            props.eventClick(ev);
            if (ev.jsEvent.defaultPrevented) {
                return;
            }
        }
        const url = ev.event.url;
        if (url) {
            if (url.startsWith('https')) {
                window.location.href = url;
            }
            else {
                history.push(url);
            }
            ev.jsEvent.preventDefault();
        }
    }
    return (
        <div className="mini-calendar">
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialDate={dateRange.start}
                buttonIcons={{

                    prev: 'mini-cal-left',
                    next: 'mini-cal-right'
                }}

                validRange={dateRange}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                }}
                fixedWeekCount={false}
                height="auto"

                events={events}
                progressiveEventRendering
                eventClick={eventClick}
            />
        </div>
    );

}