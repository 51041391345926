import { motion } from 'framer-motion';
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from 'react-router-dom';
import { AppLink } from "App/AppLink";
import { useAppConfig } from "../AppConfig";
import './app-header.scss';
import { NavConfig } from "./NavConfig";
import { NavHilite } from "./NavHilite";
import { T1 } from "./T1";
import { UtilNav } from "./UtilNav";
import { useWindowEventListener } from 'Util/useEventListener';




export const AppHeader: React.FC = () => {

    const [isOpen, setOpen] = useState(false);
    const [currT1, setCurrT1] = useState<NavConfig>();
    const header = document.getElementById("header");
    const headerRef: React.RefObject<HTMLElement> = useRef<HTMLElement>(header);
    const appConfig = useAppConfig();
    const collapseVariants = {
        closed: { height: 0, overflow: 'hidden' },
        open: { height: "auto", transitionEnd: { overflow: 'auto' } }
    };

    const close = () => {
        setOpen(false);
        setCurrT1(undefined);
        document.body.classList.remove("mega-nav-open");
    };

    React.useEffect(() => {
        const handleMousedown = (e: MouseEvent) => {
            if (headerRef.current && !(e.target || !headerRef.current.contains(e.target))) {
                (isOpen || currT1) && close();
            }
        }
        // add when mounted
        document.addEventListener("mousedown", handleMousedown);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleMousedown);
        };
    });
    // force the nav to close when the location changes
    const location = useLocation();
    React.useEffect(close, [location]);

    useWindowEventListener("resize", close);

    const navProps = (isOpen || currT1) ? { className: 'open' } : {};


    if (isOpen || currT1) {
        document.body.classList.add("mega-nav-open")
    }
    else {
        document.body.classList.remove("mega-nav-open");
    }

    return header ? ReactDOM.createPortal(
        <nav {...navProps}>
            <AppLink to={"/"} className="logo" />

            <button className={isOpen ? 'nav-toggle' : 'nav-toggle collapsed'} onClick={() => setOpen(!isOpen)}><span><i /><i /><i /></span></button>
            <motion.div className="collapse" initial="closed"
                variants={collapseVariants} animate={isOpen ? "open" : "closed"} >
                <ul className="t1">
                    {appConfig.navConfig?.map((t1, index) => <T1 key={t1.id || t1.title} t1={t1} currT1={currT1} setCurrT1={setCurrT1} />)}
                </ul>
                <UtilNav />
            </motion.div>

            <div className="nav-border-bottom"></div>
            <NavHilite />
        </nav>,
        header
    ) : null;
}
