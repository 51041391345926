import { CheckBox } from "./CheckBox";
import { CheckBoxList } from "./CheckBoxList";
import { DataConsent } from "./DataConsent";
import { DatePicker } from "./DatePicker";
import { DropDownList } from "./DropDownList";
import { FileUpload } from "./FileUpload";
import { HiddenField } from "./HiddenField";
import { Recaptcha2 } from "./Recaptcha2";
import { Textarea } from "./Textarea";
import { Textfield } from "./Textfield";
import { Text } from "./Text";
export const fields = {
    CheckBox,
    CheckBoxList,
    DataConsent,
    DatePicker,
    DropDownList,
    FileUpload,
    HiddenField,
    Recaptcha2,
    Textarea,
    Textfield,
    Text
}