import React from "react";

import './img.scss';
import { FigureData, Figure } from "../Figure/Figure";

interface ImgData extends FigureData {
    grid: number;
    link?: string;
}

export default function Img(props: ImgData) {
    return (
        <div className="img">
            {props.link ? <a href={props.link} target="_blank" rel="noopener noreferrer"><Figure {...props}></Figure></a> : <Figure {...props}></Figure>}
        </div>
    );
}
