import React from "react"
import { motion, AnimatePresence } from "framer-motion";

import './popup.scss';
import { PopupService, PopupEntry } from "./Types";

const popupVariants = {
    side: {
        initial: { opacity: 0, left: 10 },
        animate: { opacity: 1, left: 0 },
        exit: {
            opacity: 0
        },
    },
    top: {
        initial: { opacity: 0, top: -10 },
        animate: { opacity: 1, top: 0 },
        exit: {
            opacity: 0
        },
    }
};

interface PropTypes {
    popup?: PopupEntry;
    popupService: PopupService;
}
export const Popup: React.FC<PropTypes> = ({ popup, popupService }) => {
    const dismiss = () => {
        popupService.hide();
    }

    return (
        <AnimatePresence>
            {
                popup && <motion.div className={popup ? 'popup' : ''} {...popupVariants[popup.options?.from || 'side']}>
                    <div key="shade" className="popup-shade" onClick={dismiss} />
                    {popup}
                </motion.div>
            }
        </AnimatePresence>
    );
}