import React from "react";
import { Figure, FigureData } from '../Figure/Figure';

import './page-banner.scss';
import { AppRawHtml } from "App/AppRawHtml";
import { useCmsContext } from "Cms/Data/CmsContext";

interface PropTypes {
    title: string;
    subTitle?: string;
    image: FigureData
}

export default function PageBanner(props: PropTypes) {
    const cmsPageData = useCmsContext();
    let { image, title, subTitle } = props;
    if (!title && cmsPageData) {
        title = cmsPageData.title;
    }
    return (
        <div className="page-banner">
            <Figure {...image}>
                <figcaption>
                    <AppRawHtml tagName="h1">{title}</AppRawHtml>
                    {subTitle && <AppRawHtml tagName="p" className="sub-title">{subTitle}</AppRawHtml>}
                </figcaption>
            </Figure>
        </div>
    );
}
