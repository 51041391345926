import React from "react";

import './related-posts.scss';
import { BlogEntry, BlogEntries } from "../BlogSection/BlogSection";

interface RelatedPostsPropTypes {
    keywords: string[];
    posts: BlogEntry[]
}

export default function RelatedPosts(props: RelatedPostsPropTypes) {

    return (
        <div className="related-posts">
            <BlogEntries entries={props.posts} />
        </div>
    );
}
