export interface AjaxRequest extends Record<string, any> {
    request?: RequestInfo;
    abortController?: AbortController;
    options?: RequestInit;
    data?: any; // will become options.body if there. 
}

export class AjaxException extends Error {
    request: RequestInfo;
    status: number;
    errorMessage: string;
    constructor(request: RequestInfo, response: Response) {
        super(response.statusText ? `${response.status} | ${response.statusText}` : `${response.status}`);
        this.request = request;
        this.status = response.status;
        this.errorMessage = response.statusText;
    }
}

export class AjaxService {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = (baseUrl && baseUrl[baseUrl.length - 1] === '/')
            ? baseUrl.slice(0, baseUrl.length - 1)
            : baseUrl || '';
    }
    private beforeSend: ((input: AjaxRequest) => void)[] = [];

    /** 
     * pass in a callback to to mangle all requests before they are sent
     */
    onBeforeSend(callback?: (input: AjaxRequest) => void) {
        if (callback) {
            this.beforeSend.push(callback);
        }
        return this.beforeSend;
    }

    /*
        does the request; checks if it's ok, and passes on the response or throws a FetchException
        options will override input.options
    */
    buildRequestOptions(input: AjaxRequest, options?: RequestInit): RequestInit {
        input.options = {
            signal: input.abortController?.signal,
            mode: 'cors',
            ...input.options,
            ...options
        }
        if (input.data && input.options.method && input.options.method !== "GET") {
            input.options.body = JSON.stringify(input.data);
        }
        this.beforeSend.forEach(cb => cb(input));
        return input.options;
    }
    /* 
        does the request; checks if it's ok, and passes on the response or throws a FetchException
    */
    async fetchResponse(input: AjaxRequest, options?: RequestInit): Promise<Response> {
        const url = this.baseUrl + (input.request || '');

        input.options = this.buildRequestOptions(input, options);
        // console.log(`fetch ${url}`, input, input.options);

        const response = await fetch(url, input.options);
        if (response.ok) {
            return response;
        }
        else {
            throw new AjaxException(url, response);
        }
    }
    async fetchJson(input: AjaxRequest): Promise<any> {
        return (await this.fetchResponse(input, { headers: { 'content-type': 'application/json; charset=utf-8' } }))
            .json()
            .catch(err => {
                if (err.name === 'AbortError') {
                    // do nothing. 
                }
                else {
                    throw (err);
                }
            })
    }

    async fetchText(input: AjaxRequest): Promise<string> {
        return (await this.fetchResponse(input))
            .text()
            .catch(err => {
                if (err.name === 'AbortError') {
                    // do nothing. 
                    return '';
                }
                else {
                    throw (err);
                }
            });
    }
    async postMultipart(input: AjaxRequest, data: Record<string, any>) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, val]) => {
            if (Array.isArray(val)) {
                val.forEach(entry => formData.append(key, entry));
            }
            else if (val?.constructor === FileList) {
                let files = val as FileList;
                for (let i = 0; i < files.length; i++) {
                    formData.append(key, files[i]);
                }
            }
            else {
                formData.append(key, val);
            }
        });

        return (await this.fetchResponse(input, {
            body: formData,
            method: 'post'
        })).json()
            .catch(err => {
                if (err.name === 'AbortError') {
                    // do nothing. 
                }
                else {
                    throw (err);
                }
            })
    }
    async postJson(input: AjaxRequest): Promise<any> {

        return (await this.fetchResponse(input, {
            headers: { 'content-type': 'application/json; charset=utf-8' },
            method: 'post'
        }))
            .json()
            .catch(err => {
                if (err.name === 'AbortError') {
                    // do nothing. 
                }
                else {
                    throw (err);
                }
            })
    }
}