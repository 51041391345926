import React from 'react';
import { CmsData } from '../Data/CmsData';
import { CmsGrid } from '../Components/Grid/CmsGrid';
import { AppMain } from '../../App/AppMain/AppMain';
import BlogSection from 'Cms/Components/BlogSection/BlogSection';

export const BlogPage: React.FC<CmsData> = (props) => {
    if (props.title) {
        return (
            <AppMain
                contentType={props.contentType}
                hero={props.heroGrid ? <CmsGrid grid={props.heroGrid} /> : null}
                body={<>
                    {props.bodyGrid ? <CmsGrid grid={props.bodyGrid} /> : null}
                    <BlogSection {...props} />

                </>}

            />
        );
    }
    else if (!props.id) {
        // loading... 
    }
    return (
        <div className="err">Content not found</div>
    );
}