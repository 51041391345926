import React, { useCallback, useMemo, useState } from "react";
import { useTessContext } from "Tess/Data/TessContext";

import { Loading } from "App/Loading/Loading";
import { CmsData, GridData } from "Cms/Data/CmsData";
import { NotLoggedIn } from "./NotLoggedIn";

import { CmsGrid } from "../Grid/CmsGrid";
import { useLocation } from "react-router";

export interface ProtectedContentProps extends CmsData {
    authorize: (req?: any) => void;
    error?: string;
}
export interface CustomerOrderNumber {
    orderNumber: number, customerNumber: number
}
interface ContentResponse {
    success?: Boolean,
    content?: any,
    message?: string
}
export const ProtectedContentLoader: React.FC<CmsData> = (props) => {
    const { search } = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { tessSession, tessService } = useTessContext();

    const [error, setError] = useState<string | undefined>();
    const [content, setContent] = useState<any | undefined>();


    const authorize = useCallback(async (req: any) => {
        setIsLoading(true);
        let response: ContentResponse;
        try {
            response = await tessService.postJson({
                request: `/protectedContent/?nodeId=${props.id}`,
                data: req
            });
        } catch (err) {
            response = { success: false, message: err }
        }
        if (response.success) {
            setContent(response.content);
        }
        else {
            setError(response.message);
        }
        setIsLoading(false);
    }, [props.id, tessService])

    // initial request
    useMemo(() => {
        if (tessSession) {
            authorize({ secretCode: search && search.length > 1 ? search.substr(1) : '' });
        }
    }, [authorize, search, tessSession])


    if (!tessSession || isLoading) {
        return <Loading />
    }
    if (content && content.sections) {
        return <CmsGrid grid={content as GridData} />
    }
    else {
        return <NotLoggedIn
            {...props}
            authorize={authorize}
            error={error}
        />
    }
}