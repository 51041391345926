import React from "react";
import { CmsData, GridData } from "Cms/Data/CmsData";
import { CmsGrid } from "../Grid/CmsGrid";

export default function ProductionBottom(props: CmsData) {
    const wideBody: GridData = props.properties && props.properties.wideBody;
    return wideBody ? (
        <CmsGrid grid={wideBody} />
    ) : null;
}
