import { format, utcToZonedTime } from "date-fns-tz";
export const formatPrice = (price: number) => `$${(Math.round(price * 100) / 100).toFixed(2)}`;

export const config = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

type DateFormatOption = 'short' | 'med' | 'long' | 'shortDate' | 'time' | 'date' | 'shortDateWithYear' | undefined;

export const parseDate = (val?: Date | string) => {
    if (!val) {
        return undefined;
    }
    if (typeof val === 'object') {
        return val as Date;
    }
    return utcToZonedTime(new Date(val), config.timeZone);
}

const getDateFormat = (option: DateFormatOption) => {
    switch (option) {
        case 'date': return "eee, MMMM d";
        case 'long': return "eeee, MMMM d, h:mm a";
        case 'med': return "eeee, MMM d, h:mm a";
        case 'short': return "eee, MMM d, h:mm a";
        case 'shortDate': return "MMM d";
        case 'shortDateWithYear': return "MMM d, yyyy";
        case undefined: return "eeee, MMMM d, yyyy \\a\\t h:mm a";
        case 'time': return 'h:mm a'
        default: return option; // use as a date-format
    }
}
export const formatDate = (date?: Date | string, options?: DateFormatOption) => {
    return formatDateTime(date, options || 'date')
}
export const formatTime = (date?: Date | string) => {
    return formatDateTime(date, 'time')
}
export const formatDateTime = (date?: Date | string, options?: DateFormatOption) => {
    const d = parseDate(date);
    return d ? format(d, getDateFormat(options), config) : '';
};
export const formatDateRange = (startDate: string | Date | Record<string, any>, endDate?: string | Date) => {
    let start: Date | undefined,
        end: Date | undefined;
    if (typeof startDate === 'object') {
        if (startDate.hasOwnProperty("startDate")) {
            const sd: any = (startDate as Record<string, any>)["startDate"];
            start = (typeof sd === 'object') ? sd as Date : new Date(String(sd));

            if (startDate.hasOwnProperty("endDate")) {
                const ed: any = (startDate as Record<string, any>)["endDate"];
                end = (typeof ed === 'object') ? ed as Date : new Date(String(ed));
            }
            else {
                end = undefined;
            }
        }
        else {
            start = startDate as Date;
            end = endDate as Date;
        }
    }
    else {
        start = parseDate(startDate);
        end = endDate ? parseDate(endDate) : undefined;
    }
    const formatWithYear = (d?: Date) => d?.getFullYear() === new Date().getFullYear() ? formatDate(d, 'shortDate') : formatDate(d, 'shortDateWithYear');
    const formattedStart = end ? formatDate(start, 'shortDate') : formatWithYear(start);
    const formattedEnd = end ? formatWithYear(end) : undefined;
    return formattedEnd && formattedEnd !== formattedStart
        ? formattedStart + '\u2013' + formattedEnd
        : formattedStart;
}

export const formatAddress = (addr: Record<string, any>) => {
    let result = ''
    let sep = '';
    if (addr.street1) { result += sep + addr.street1; sep = ' ' }
    if (addr.street2) { result += sep + addr.street1; sep = ' ' }
    if (addr.city) { result += sep + addr.city; sep = ', ' }
    if (addr.state) {
        result += sep + ((addr.state.stateCode) ? addr.state.stateCode : '' + addr.state);
        sep = ' '
    }
    if (addr.country && addr.country.id && addr.country.id !== 1) { result += sep + addr.country.description; sep = ' ' }
    if (addr.postalCode) {
        let postalCode: string = addr.postalCode;
        if (postalCode.match(/[0-9]{9}/)) {
            postalCode = postalCode.slice(0, 5) + '-' + postalCode.slice(5);
        }
        result += sep + postalCode;
        sep = ' '
    }
    return result;
}

/**
 * Returns a string similar to c# string.format(format, args);
 * @param format decorated with {0}, {1}, etc  or {animal:'dog', color: 'brown'}
 * @param arg of arguments or argument object. 
 */
export const formatString = (format: string, arg?: any) => {
    let result = format;
    let args: any;
    if (typeof arg === 'object') {
        if (arg[0]) {
            [...args] = arg as Array<any>;
            args.forEach((s: string, index: number) => {
                format = format.replace(new RegExp("\\{" + index + "\\}"), String(s));
            })
        }
        else {
            const getParam = (key: string) => {
                let val = arg[key];
                if (typeof val === 'function') {
                    val = val();
                }
                return val ? String(val) : '';
            }
            Object.keys(arg).forEach(key => {
                result = result.replace(
                    new RegExp(`{${key}}`, "gi"),
                    getParam(key)
                );
            })
            return result;
        }
    }


    return format;
}