import { useCmsContext } from "Cms/Data/CmsContext";
import React from "react"
import Zendesk from "react-zendesk";

interface ZendeskLoaderPropTypes { }
export const ZendeskLoader: React.FC<ZendeskLoaderPropTypes> = (props) => {
    const cmsContext = useCmsContext();

    return (
        cmsContext.properties && cmsContext.properties["zendesk"]
            ?
            <Zendesk zendeskKey="69ae2f77-96e3-49c1-a6ac-b62da355ed82"
                defer
            />
            : null
    );
}