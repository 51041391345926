import React, { useState } from "react"
import { Button } from "Util/Forms/Button"
import { formatDateTime } from "Util/format";

import { usePopup } from "Util/Popup/PopupContext";
import { RawHtml } from "Util/RawHtml";
import './perf-select.scss';
import { TessProduction, TessPerformance } from "Tess/Data/TessInventory";
import { MiniCalendar } from "../Calendar/MiniCalendar";

const findNextPerformance = (prod: TessProduction) => prod.performances.find(p => p.date > new Date()) || prod.performances[0];

interface PropTypes {
    baseUrl: string;
    prod: TessProduction;
    perf?: TessPerformance;
    style?: React.CSSProperties;
    title?: string;


}
export const PerfSelect: React.FC<PropTypes> = (props) => {
    const { baseUrl, prod, style } = props;
    const popupService = usePopup();
    const [selectedPerf] = useState(props.perf || findNextPerformance(prod));

    const [isOpen, setIsOpen] = useState<boolean>(!!!(prod.specialDates && prod.specialDates.length));

    return (
        <div className="perf-select" style={style} onClick={(e) => e.stopPropagation()}>
            <RawHtml tagName="label">{props.title || prod.title}</RawHtml>

            {isOpen ?
                <MiniCalendar
                    perfs={prod.performances}
                    baseUrl={props.baseUrl}

                />
                : (
                    <>
                        <div className="selected-row">
                            <div className="selected-perf" onClick={() => setIsOpen(!isOpen)}>
                                <span>{formatDateTime(selectedPerf.date, 'short')}</span>
                                <i className="app-icon-arrow-down" />
                            </div>
                            <div className="go-button">
                                <Button onClick={() => { popupService.hide(); return true; }} url={`${baseUrl}/${selectedPerf.perfCode}`}
                                    className="btn" nextArrow></Button>
                            </div>
                        </div>
                        {prod.specialDates && (
                            <div>
                                <RawHtml className="special-dates">{prod.specialDates}</RawHtml>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
}