import React, { useEffect, useState } from "react";
import './nav-hilite.scss';

const defaultValues = { left: -100, width: 50 };

export const NavHilite: React.FC = () => {
	const [state, setState] = useState('away');
	const [style, setStyle] = useState(defaultValues);

	useEffect(() => {
		var interval = window.setInterval(function () {
			try {
				const atT1 = document.querySelector('a.t1.at');
				const openT1 = document.querySelector('a.t1.open');
				if (openT1) {
					const rect = openT1.getBoundingClientRect();
					if (rect && style.left !== rect.left) {
						setState('open');
						setStyle(rect);
					}
				}
				else if (state === 'open') {
					setState('wait1');
					setStyle(defaultValues);
				}
				else if (state === 'wait1') {
					setState('wait2');
				}
				else if (atT1) {
					const rect = atT1.getBoundingClientRect();
					if (rect && style.left !== rect.left) {
						if (rect && style.left !== rect.left) {
							setState('at');
							setStyle(rect);
						}
					}
				}
				else {
					setStyle(defaultValues);
				}
			}
			catch (_) {
				// ignore errors
			}
		}, 300);
		return () => clearInterval(interval);
	}, [style, setStyle, state, setState]);
	return <div className={`nav-hilite ${state}`} style={{ left: style.left + 'px', width: style.width + 'px' }} ></div>
}