import React from "react";

import { AccordionEntry as BaseAccordionEntry } from "Util/Accordion/Accordion";

interface Props {
    title: string;
    text: string;
}

export const AccordionEntry: React.FC<Props> = (props) => {
    return (
        <div className="accordion">
            <BaseAccordionEntry {...props} />
        </div>
    );
}
