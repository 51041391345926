import { PerfDetail, Zone } from "./TessInventory";
import { SeatType, SeatSelection } from "./SeatSelection";

export interface WheelchairSeat {
    wheelchair: number;
    companions: number[];
    season?: number
}
export interface WheelchairSeatSelection {
    wheelchairs: number[];
    companions: number[];
}
export class AllWheelchairSeats {
    private bySeason: Record<number, WheelchairSeat[]>;

    constructor(wheelchairSeats: WheelchairSeat[]) {
        this.bySeason = {};
        wheelchairSeats?.forEach(seat => {
            let season = seat.season || 0;
            let seats = this.bySeason[season] || (this.bySeason[season] = []);
            seats.push(seat);
        });
    }
    forSeason(season: number): WheelchairSeat[] {
        return this.bySeason[season] || this.bySeason[0]
    }
    forPerformance(perf: PerfDetail): PerfWheelchairSeats {
        return new PerfWheelchairSeats(perf, this.forSeason(perf.production.seasonNumber));
    }
}

export class PerfWheelchairSeats {
    seatDict: Record<number, WheelchairSeat>;
    zoneDict: Record<string, WheelchairSeat[]>;
    seatsList: WheelchairSeat[];
    constructor(perf: PerfDetail, seatList: WheelchairSeat[]) {
        this.seatsList = [];
        this.seatDict = {} as Record<number, WheelchairSeat>;
        this.zoneDict = { "0": [] } as Record<string, WheelchairSeat[]>;
        seatList.forEach(seat => {
            let zone = perf.zones.find(z => z.seats && z.seats.find(zs => zs === seat.wheelchair));
            if (zone && zone.seats) {
                let zoneKey = this.getZoneKey(zone);

                let companions = zone.seats.filter(zs => seat.companions.includes(zs));
                seat = { ...seat, companions };
                this.seatsList.push(seat)
                this.seatDict[seat.wheelchair] = seat;

                if (!!this.zoneDict[zoneKey]) {
                    this.zoneDict[zoneKey] = [];
                }
                this.zoneDict[zoneKey]?.push(seat);
                this.zoneDict["0"]?.push(seat);
            }
        });
    }
    public filter(zones: Zone[]): Zone[] {
        var keys = Object.keys(this.zoneDict);
        return zones.filter(z => keys.includes(this.getZoneKey(z)));
    }
    private getZoneKey(zone: Zone) {
        return zone.zoneNumbers.join(',');
    }

    // gets an array of wheelchair seats in the zone.
    getWheelchairSeats(seats: number[]) {
        return seats.map(s => this.seatDict[s]).filter(s => s);
    }
    // counts the number of seats for a given seat type
    getMaxSeats(seatType: SeatType, selection: SeatSelection) {
        let { zone } = selection;
        let zoneKey = this.getZoneKey(zone);

        switch (seatType) {
            case SeatType.Standard: return 99999;
            case SeatType.Wheelchair:
                return this.zoneDict[zoneKey]?.length ?? 0;
            case SeatType.Companion:
                // this is the number of wc seats requested
                let reqCount = Object.values(selection.priceTypes[SeatType.Wheelchair]).reduce((a, b) => a + b, 0);

                let wcSeats = [...this.zoneDict[zoneKey] || []].sort((x, y) => -(x.companions.length - y.companions.length));

                console.log()
                let max = 0;
                let seat: WheelchairSeat | undefined;
                while (reqCount-- > 0 && (seat = wcSeats.pop())) {
                    if (seat && seat.companions) {
                        max += seat.companions.length;
                    }
                }
                return max;
        }
    }
    // look throught the wheelchair seats for a matching combo for the selection.
    // return the result, or null if not possible. 
    findSeats(sel: SeatSelection): WheelchairSeatSelection | null {

        let result: WheelchairSeatSelection | null = null;
        let attemptNumber: number = 0;

        let reqWheelchairs: number = Object.values(sel.priceTypes[SeatType.Wheelchair]).reduce((a, b) => a + b, 0);
        let reqCompanions: number = Object.values(sel.priceTypes[SeatType.Companion]).reduce((a, b) => a + b, 0);

        tryPermutations(this.seatsList, permutation => {
            console.log(`attempt # ${++attemptNumber}`);
            result = findSeatsForPermutation(permutation.slice(), reqWheelchairs, reqCompanions);
            return !!result;
        })
        return result;
    }
}
// look through one permutation of the wheelchair seats array and try to find a matching WheelchairSeatSelection
function findSeatsForPermutation(wcSeats: WheelchairSeat[], reqWheelchairs: number, reqCompanions: number): WheelchairSeatSelection | null {

    let nextSeat: WheelchairSeat | undefined;
    let result: WheelchairSeatSelection = { wheelchairs: [], companions: [] };
    let skipped: WheelchairSeatSelection = { wheelchairs: [], companions: [] };
    let getNextSeat = () => (nextSeat = wcSeats.shift());

    while (result.wheelchairs.length < reqWheelchairs && getNextSeat() && nextSeat) {
        let w = reqWheelchairs - result.wheelchairs.length;
        let c = reqCompanions - result.companions.length;
        // console.log(`w = ${w} & c = ${c}`);
        if (nextSeat.companions.length === 0) {
            if (c < w) {
                console.log(`taking lone wheelchair ${nextSeat.wheelchair}`);
                result.wheelchairs.push(nextSeat.wheelchair);
            } else {
                console.log(`skipping lone wheelchair  ${nextSeat.wheelchair}`);
                skipped.wheelchairs.push(nextSeat.wheelchair);
            }
        } else {
            let companionCount = Math.min(
                c,
                Math.max(1, 1 + c - w),
                nextSeat.companions.length
            );
            let companions = nextSeat.companions.slice(0, companionCount);
            let skippedCompanions = nextSeat.companions.slice(companionCount);
            console.log(
                `taking wheelchair  ${nextSeat.wheelchair
                } with ${companionCount} companions ${companions}`
            );
            result.wheelchairs.push(nextSeat.wheelchair);
            result.companions = result.companions.concat(companions);
            skipped.companions = skipped.companions.concat(skippedCompanions);
        }
    }
    let missing = Math.max(reqWheelchairs - result.wheelchairs.length, 0);
    if (missing > 0 && missing < skipped.wheelchairs.length) {
        result.wheelchairs = result.wheelchairs.concat(
            skipped.wheelchairs.slice(0, missing)
        );
    }
    missing = Math.max(reqCompanions - result.companions.length, 0);
    if (missing > 0 && missing < skipped.companions.length) {
        result.companions = result.companions.concat(
            skipped.companions.slice(0, missing)
        );
    }
    return (result.wheelchairs.length === reqWheelchairs && result.companions.length === reqCompanions) ? result : null;
}

// utility function to pass in an array, and loop through all permutations until test succeeds.
function tryPermutations<T>(
    arr: Array<T>,
    test: (a: Array<T>) => boolean
): Array<T> | null {
    let length = arr.length,
        c = new Array<number>(length).fill(0),
        i: number = 1,
        k: number;
    while (i < length) {
        if (c[i] < i) {
            if (test(arr)) {
                return arr;
            }
            k = i % 2 && c[i];
            [arr[i], arr[k]] = [arr[k], arr[i]];
            ++c[i];
            i = 1;
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return null;
}
