import { FieldValues, FieldElement, Control } from "react-hook-form";

import { formatString } from "Util/format";

export interface UmbracoFormContext extends FieldValues {
    form: UmbracoForm;
    formPage: UmbracoFormPage;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    formResponses: Record<string, string>;
    errors: Record<string, any>;
    savePageResponses: (partialResults: Record<string, string>) => void;
    registerField: (field: UmbracoFormField) => ((ref: FieldElement | null) => void);
    control: Control<Record<string, any>>;
}

export interface UmbracoForm {
    formId: string;
    name?: string;
    pages: UmbracoFormPage[];
    prevLabel?: string;
    nextLabel?: string;
    submitLabel?: string;
    invalidErrorMessage: string;
    requiredErrorMessage: string;
    recaptchaPublicKey: string;
}

export interface UmbracoFormPage {
    fieldSets?: UmbracoFormFieldset[];
}

export interface UmbracoFormFieldset {
    id?: string;
    containers?: UmbracoFormContainer[];
    caption?: string;
}

export interface UmbracoFormContainer {
    width?: number;
    fields?: UmbracoFormField[];
    caption?: string;
}

export interface UmbracoFormField {
    required?: boolean;
    isSensitive?: boolean;
    settings?: FieldSettings;
    id: string;
    alias?: string;
    toolTip?: string;
    caption?: string;
    fieldType?: FieldType;
    preValues?: string[];
    regEx?: string;
    requiredErrorMessage?: string;
    invalidErrorMessage?: string;
}
export interface FormFieldPropTypes {
    formContext: UmbracoFormContext;
    field: UmbracoFormField;
}
export const getFieldCaption = (field: UmbracoFormField, formContext: UmbracoFormContext) =>
    formContext.errors[field.id] ?
        formatString(
            field.requiredErrorMessage || formContext.form.invalidErrorMessage, field.caption)
        : `${field.caption}${field.toolTip ? ': ' + field.toolTip : ''}`;


export interface FieldType {
    name: string;
    dataType: string;
}

export interface FieldSettings extends Record<string, any> {
    placeholder?: string;
    defaultValue?: string;
    acceptCopy?: string;
}
export interface FormResult {
    success: boolean;
    message?: string;
    url?: string;
}