import { CmsData, CmsPageData, ContentConfig, SearchData } from "./CmsData";
import { AjaxService } from "Util/Ajax";
import { useAppConfig } from "App/AppConfig";

let cmsService: CmsService | undefined;

export const useCmsService = () => {
    const { contentConfig } = useAppConfig();
    return cmsService || new CmsService(contentConfig);
}

export class CmsService extends AjaxService {
    constructor(contentConfig: ContentConfig) {
        super(contentConfig.url + "/api")
    }
    async fetchPageData(path: string, abortController: AbortController): Promise<CmsData> {
        return this.fetchJson({
            request: '/content' + path,
            options: {
                credentials: 'omit'
            }
        }).then((json: any) => new CmsPageData(path, json))
            .catch(err => {
                if (err.name !== 'AbortError') {
                    console.error('Problem fetching content', err);
                    return {
                        url: path,
                        isLoading: true,
                        id: 0,
                        contentType: '',
                        title: 'Missing Page',
                        path: []
                    }
                }
                throw (err);
            });
    }
    async search(searchData: SearchData, abortController: AbortController): Promise<SearchData> {
        return this.fetchJson({
            request: `/search/?query=${searchData.query}&pageSize=${searchData.pageSize}&page=${searchData.page}`,
            options: {
                credentials: 'omit'
            }
        }).then((json: SearchData) => json);
    }
}