import React from 'react';
import { CmsData } from '../Data/CmsData';
import { ProtectedContentLoader } from '../Components/ProtectedPage/ProtectedContentLoader';
import { AppMain } from '../../App/AppMain/AppMain';
import { CmsGrid } from 'Cms/Components/Grid/CmsGrid';

export const ProtectedPage: React.FC<CmsData> = (props) => {
    return (
        <AppMain
            contentType={props.contentType}
            hero={props.heroGrid ? <CmsGrid grid={props.heroGrid} /> : null}
            body={<ProtectedContentLoader {...props} />}
        />
    );
}