import React from "react";


interface EmbedCodeData {
    code: string;
    height?: string;
}

export default function EmbedCode(props: EmbedCodeData) {
    const html = `<body>${props.code}</body>`;
    return (
        <iframe
            style={{
                border: 'none',
                width: '100%',
                height: (props.height === '' ? '100%' : `${props.height}px`)
            }}
            src={`data:text/html;charset=utf-8,${encodeURI(html)}`} title="embed"></iframe>
    );
}
