import React from "react"
import { FormFieldPropTypes } from "../Types";

export const CheckBox: React.FC<FormFieldPropTypes> = (props) => {
    const { field } = props;
    const err = props.formContext.errors[field.id];
    return (

        <div className={`form-group checkbox${err ? ' invalid' : ''}`}>
            <label>{field.caption}</label>
            <label><input
                type="checkbox"
                name={field.id}
                id={field.id}
                ref={props.formContext.registerField(field)}
            /><span>{field.toolTip}</span></label>
        </div>
    );
}
