export type TContentTemplate = (content: CmsData) => JSX.Element;
export type Dict = { [name: string]: any };

export class CmsPageData implements CmsData {
    static WIDE_ROW: string = 'Wide'; // indicator that the 
    static HERO_GRID: string = 'hero-grid';
    static BODY_GRID: string = 'body-grid';

    url: string;
    isLoading: boolean = false;
    id: number;
    contentType: string;
    title: string;
    path: PageStub[];
    heroGrid: GridData;
    bodyGrid: GridData;

    properties: any;
    childNodes?: any[];

    errorMessage?: string;

    constructor(url: string, data: any) {
        this.url = url;
        this.id = parseInt(data.id);
        this.contentType = data.contentType;
        this.title = data.title;
        this.path = data.path;

        let { heroGrid, bodyGrid, properties } = CmsPageData.splitProperties(data.properties);
        this.heroGrid = heroGrid;
        this.bodyGrid = bodyGrid;

        this.properties = properties;
        this.childNodes = data.childNodes
    }


    private static splitProperties({ bodyGrid, bannerImage, ...properties }: any): any {
        // if the page has a bannerImage, use that & create a fake grid row
        if (bannerImage) {
            try {
                return {
                    heroGrid: {
                        name: CmsPageData.HERO_GRID,
                        sections: [{
                            grid: 12,
                            rows: [
                                {
                                    name: "Wide",
                                    id: 'bannerImageGridRow',
                                    areas: [
                                        {
                                            grid: 12,
                                            controls: [
                                                {
                                                    alias: 'pageHero',
                                                    value: {
                                                        title: properties.title,
                                                        subTitle: null,
                                                        image: bannerImage
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                    // put the row here
                                }
                            ]
                        }]
                    },
                    bodyGrid,
                    properties
                };
            } catch (_) {
                // ignored
            }
        }
        // otherwise, if we have a bodyGrid, 
        // and it has a single section, 
        // and the section has more than one row, 
        // and the row has a heroXXXXX as its only area, 
        // split into separate sections
        if (bodyGrid && bodyGrid.sections && bodyGrid.sections.length === 1) {
            try {
                let section = bodyGrid.sections[0];
                if (section.rows.length >= 1 && section.rows[0].areas.length === 1 && section.rows[0].areas[0].controls.length === 1
                    && section.rows[0].areas[0].controls[0].alias.indexOf('Hero') !== -1) {
                    return {
                        heroGrid: {
                            name: CmsPageData.HERO_GRID,
                            sections: [{
                                grid: section.grid,
                                rows: [section.rows[0]]
                            }]
                        },
                        bodyGrid: {
                            name: CmsPageData.BODY_GRID,
                            sections: [{
                                grid: section.grid,
                                rows: section.rows.slice(1)
                            }]

                        },
                        properties
                    }
                }
            }
            catch (_) {
                // ignored
            }
        }
        return { bodyGrid: bodyGrid, properties };
    }
}
export interface ContentConfig {
    url: string;
    sponsors?: Image[];
    footer?: GridData;
}

// Generated by https://quicktype.io
export interface Image {
    src: string;
    height: number;
    width: number;
    title: string;
    link: string;
}


// https://app.quicktype.io/

export interface PageStub {
    id: number;
    contentType: string;
    url: string;
    title: string;
    properties?: any;
    childNodes?: PageStub[];
}
export interface CmsData extends PageStub {
    path?: PageStub[];
    isLoading: boolean;

    heroGrid?: GridData;
    bodyGrid?: GridData;


    errorMessage?: string;
    onContentClick?: (e: React.MouseEvent) => void;
}


export interface GridData {
    name: any;
    sections: SectionData[];
}

export interface SectionData {
    grid: number;
    rows: RowData[];
}

export interface RowData {
    name: string;
    areas: AreaData[];
    hasConfig: boolean;
    config?: Dict;
    styles?: Dict;
    id: string;
}

export interface AreaData {
    grid: number;
    allowAll: boolean;
    allowed: string[];
    controls: ControlData[];
}
export interface ControlData {
    alias: string;

    grid: number;
    value: any;
    index: number;
}

export interface SearchData {
    pageSize: number;
    page: number;
    query: string;
    totalCount?: number;
    results?: {
        title: string;
        url: string;
        description: string;
    }[]
}