import React, { useState } from "react";
import './responsive-indicator.scss';
import { useWindowEventListener } from "Util/useEventListener";
interface ResponsiveIndicatorPropTypes {
    toggle: () => void;
}
export const ResponsiveIndicator: React.FC<ResponsiveIndicatorPropTypes> = ({ toggle }) => {
    const [{ width, height }, setSize] = useState<{ width: number, height: number }>({ width: window.innerWidth, height: window.innerHeight });
    const updateWindowDimensions = () => {
        if (width !== window.innerWidth || height !== window.innerHeight) {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
    }

    updateWindowDimensions();
    useWindowEventListener('resize', updateWindowDimensions);
    return (
        <div className="responsive-indicator" onDoubleClick={toggle}>
            <span className="xs-only">XS: </span>
            <span className="sm-only">SM: </span>
            <span className="md-only">MD: </span>
            <span className="lg-only">LG: </span>
            <span className="xl-only">XL: </span>
            {width} ✕ {height}
        </div>
    )
}
