import { GlobalMessageEntry, GlobalMessageList } from "./Types";
import React, { useContext, useState } from "react";
import { useAppConfig } from "App/AppConfig";


const initialAlert = { entries: [], append: (a: GlobalMessageEntry) => { }, remove: (id: number) => { } }

export const GlobalMessageContext = React.createContext<GlobalMessageList>(initialAlert);
export const useGlobalMessages = () => useContext<GlobalMessageList>(GlobalMessageContext);

const key = 'global-alert-dismissed';
const getDismissed = () => new Set(JSON.parse(localStorage.getItem(key) || '[]') as Array<number>);
const dismiss = (id: number) => {
    try {
        var dismissed = getDismissed();
        dismissed.add(id);
        localStorage.setItem(key, JSON.stringify(Array.from(dismissed.keys())))
    }
    catch {

    }
}


export const GlobalMessageProvider: React.FC = (props) => {
    const dismissed = getDismissed();
    const appConfig = useAppConfig();

    const [entries, setEntries] = useState<GlobalMessageEntry[]>(appConfig.globalMessages || []);
    initialAlert.append = (entry: GlobalMessageEntry) => {
        setEntries([...entries.filter(e => e.id !== entry.id), entry]);
    }
    initialAlert.remove = (id: number) => {
        dismiss(id);
        setEntries(entries.filter(e => e.id !== id));
    }

    return (
        <GlobalMessageContext.Provider value={{ ...initialAlert, entries: entries.filter(entry => !dismissed.has(entry.id)) }}>{props.children}</GlobalMessageContext.Provider>
    );
}