import React from "react";
import { BuyTicketsButton } from "Tess/Tickets/BuyTicketsButton";
import { AppLink } from "App/AppLink";
import { formatDateRange } from "Util/format";
import { AppRawHtml } from "App/AppRawHtml";
import { Figure } from "../Figure/Figure";
import { ShowLinkData } from "../ShowLink/ShowLink";
import './show-hero.scss';





interface ShowHeroProps extends ShowLinkData {
    title?: string;
    url?: string;
    portraitImage?: {
        src: string;
        width: number;
        height: number;
    }
}

export default function ShowHero(props: ShowHeroProps) {
    const dateRange = formatDateRange(props)
        .replace(/([a-z]{3})\s*([0-9]{1,2})/gi, '<span>$1&nbsp;$2</span>')
        .replace(/\s?–\s?/gi, '–');

    return (
        <div className="show-hero">
            <Figure {...props.image}
                grid={props.grid}
            >
                <figcaption>
                    <div>
                        <AppRawHtml className="show-hero-title">{props.title}</AppRawHtml>
                        <AppRawHtml className="show-hero-date-range">{dateRange}</AppRawHtml>
                        <div className="show-hero-buttons">
                            <BuyTicketsButton {...props} />
                            {props.url && <AppLink to={props.url} className="btn btn-clear">Learn More</AppLink>}
                        </div>
                    </div>
                </figcaption>
            </Figure>
        </div>
    );
}
