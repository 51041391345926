import React, { useState } from "react";
import { labels } from "Util/Labels";
import { useHistory } from "react-router";


interface PropTypes extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    className?: 'btn' | 'btn-clear' | 'btn-dark' | string;

    /**
     * if true or undefined, then we'll disable the button on click
     */
    disableOnClick?: boolean;

    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | boolean | Promise<void | boolean>;

    url?: string;

    validationErrors?: Record<string, any>;
    backArrow?: boolean;
    nextArrow?: boolean;
}
export const Button: React.FC<PropTypes> = ({ disableOnClick, onClick, url, className, children, validationErrors, ...props }) => {
    disableOnClick = disableOnClick !== false; // undefined => true
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isInvalidForm: boolean = !!(validationErrors && Object.keys(validationErrors).length);

    if (isInvalidForm && isSubmitting) {
        setIsSubmitting(false);
    }

    const isDisabled = (disableOnClick && isSubmitting) || isInvalidForm;

    className = (className || 'btn')
        + (isSubmitting && disableOnClick ? ' disable-on-click' : '');
    const text = isSubmitting && disableOnClick ? labels.getString("forms.pleaseWait") : children;
    const history = useHistory();
    const buttonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disableOnClick && isSubmitting) {
            // do nothing.
            return;
        }
        else {
            // woah. if we set this immediately, then the form doesn't submit. 
            setTimeout(() => setIsSubmitting(true), 0);
            if (onClick) {
                e.persist(); // we need to do this so the isPropagationStopped will pass through :( http://reactjs.org/docs/events.html#event-pooling
                const handleClickResult = (result: boolean | void) => {
                    if (!e.isPropagationStopped() && url) {
                        history.push(url);
                    }
                    if (result !== false) {
                        setIsSubmitting(false);
                    }
                }
                const clickResult = onClick(e);
                if (typeof clickResult == 'boolean') {
                    handleClickResult(clickResult);
                }
                else {
                    var promise = clickResult as Promise<void | boolean>;
                    if (promise) {
                        promise
                            .then(handleClickResult)
                            .finally(() => setTimeout(() => setIsSubmitting(false), 1));
                    }
                    else {
                        setTimeout(() => setIsSubmitting(false), 1);
                    }
                }
            }
            else if (!e.isPropagationStopped() && url) {
                history.push(url);
            }
        }
    }

    return (
        <button className={className} disabled={isDisabled} onClick={buttonClick} {...props}>
            <>
                {props.backArrow && <i className="app-icon-caret-left" />}
                <span>{text}</span>
                {props.nextArrow && <i className="app-icon-caret-right" />}
            </>
        </button>
    );
}