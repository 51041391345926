import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Breadcrumbs } from "../../Cms/Components/Breadcrumbs/breadcrumbs";
import { ZendeskLoader } from "Cms/Components/Zendesk/ZendeskLoader";
import './app-main.scss';
import { useWindowEventListener } from "Util/useEventListener";

interface LayoutPageData {
    contentType: string;
    hero?: any;
    body: any;
    sidebar?: any;
    wideBottom?: any;
    hideBreadcrumbs?: boolean;
}

export const AppMain: React.FC<LayoutPageData> = (props: LayoutPageData) => {

    const SideBar: React.FC = (props) => {
        const [y, setY] = React.useState(window.innerHeight);

        const updateY = React.useMemo(() => (() => {
            var newY = Math.min(150 + Math.pow(window.scrollY, 1.1), window.innerHeight - 150);
            setY(newY);
        }), []);
        useWindowEventListener('scroll', updateY);
        React.useMemo(() => setTimeout(updateY, 1), [updateY]);
        return (
            <motion.div
                key="sidebar"
                style={{ backgroundPositionY: `${y}px` }}
                className="main-sidebar">
                {props.children}
            </motion.div>);
    }
    return (
        <>
            <div className={`app-main ${props.contentType}${props.sidebar ? ' with-sidebar' : ''}`}>
                <AnimatePresence>
                    <motion.div className="main-body" key="main-body" animate={{ transition: { when: "beforeChildren", staggerChildren: 1 } }} exit={{ opacity: 0 }} >
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key="hero" className="hero">{props.hero ? props.hero : <div className="no-hero" />}</motion.div>
                        {!!!props.hideBreadcrumbs && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: props.hero ? 0.5 : 0 } }} exit={{ opacity: 0 }} key="breadcrumbs"><Breadcrumbs /></motion.div>}
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: props.hero ? 1 : 0 } }} exit={{ opacity: 0 }} key="body">{props.body}</motion.div>
                    </motion.div>
                    {props.sidebar && <SideBar key="sidebar">{props.sidebar}</SideBar>}
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2 } }} exit={{ opacity: 0 }} key="footer" className="main-footer">
                        {props.wideBottom}
                    </motion.div>
                    <ZendeskLoader />
                </AnimatePresence>
            </div>
        </>
    )
}