import { AjaxService } from "Util/Ajax";

import { useCmsContext } from "Cms/Data/CmsContext";
import { useAppConfig } from "App/AppConfig";
import { FormResult } from "./Types";

export const useUmbracoFormsService = () => {
    const { contentConfig } = useAppConfig();
    const { id } = useCmsContext();
    return new UmbracoFormsService(`${contentConfig.url}/api/forms/?id=${id}`);
}

export class UmbracoFormsService extends AjaxService {
    async submitForm(formData: any): Promise<FormResult> {
        var result = await this.postMultipart({
            abortController: new window.AbortController(),
        }, formData);
        return result as FormResult;
    }
}
