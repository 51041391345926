import React from "react";
import { Loading } from "App/Loading/Loading";
import { useParams } from "react-router";
import { Prod } from "./Prod/Prod";
import { useTessContext } from "../Data/TessContext";
import { AppMain } from "App/AppMain/AppMain";
import { CalendarPage } from "./Calendar/CalendarPage";
import { PerfLoader } from "./Perf/PerfLoader";
import './tickets.scss';

export const ticketsBaseUrl = "/tickets";

export const TicketsApp: React.FC = () => {
    const { inventory } = useTessContext();
    const { id } = useParams<{ id: string }>();



    if (!inventory || !inventory.prods) {
        return <Loading />;
    }
    else {
        const currRequest = inventory.find(id);
        let component: any = (currRequest.perf)
            ? <PerfLoader perf={currRequest.perf} />
            : (currRequest.prod)
                ? <Prod prod={currRequest.prod} />
                : <CalendarPage perfs={inventory.getAllPerformances()} />
        return (
            <AppMain
                contentType="tickets"
                hero={null}
                body={component}
            />
        );
    }
}
