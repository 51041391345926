import React, { useEffect, useState } from "react"
import { useCmsService } from "Cms/Data/CmsService";
import { SearchData } from "Cms/Data/CmsData";
import { Loading } from "App/Loading/Loading";

import { RawHtml } from "Util/RawHtml";
import { Button } from "Util/Forms/Button";
import { AppLink } from "App/AppLink";
import * as ReactScroll from 'react-scroll';
interface SearchResultPropTypes {
    query: string;
    page: number;
}
export const SearchResult: React.FC<SearchResultPropTypes> = ({ query, page }) => {
    const [searchData, setSearchData] = useState<SearchData | null>(null);
    const cmsService = useCmsService();


    useEffect(() => {
        let abortController: AbortController;
        if (query) {
            if (!searchData || searchData.query !== query || searchData.page !== page) {
                if (searchData?.page !== page) {
                    ReactScroll.animateScroll.scrollToTop({ duration: 800, smooth: 'easeInOutCubic', delay: 100 });
                }
                setSearchData({
                    query,
                    pageSize: 10,
                    page: page,
                    results: undefined,
                    totalCount: undefined
                });

            }
            else if (searchData.totalCount === undefined) {
                searchData.totalCount = 0;
                abortController = new AbortController();
                cmsService
                    .search(searchData, abortController)
                    .then(results => setSearchData(results));
            }
        }
    }, [cmsService, page, query, searchData]);

    if (!query) {
        return null;
    }
    else if (!searchData || !searchData.results) {
        return <Loading />
    }
    else if (searchData.totalCount === 0) {
        return (
            <div className="search-results">
                No results found for {searchData.query}
            </div>
        );
    }
    else {
        var buttons = (
            <div className="buttons">
                {searchData.page > 0 ? <Button url={`/search/?query=${searchData.query}&page=${searchData.page - 1}`}><i className="fifth-icon-left-arrow-small" /> Prev</Button> : <span></span>}
                {(searchData.pageSize * (searchData.page + 1)) < (searchData.totalCount || 9999999) ? <Button url={`/search/?query=${searchData.query}&page=${searchData.page + 1}`}>Next <i className="fifth-icon-right-arrow-small" /></Button> : <span></span>}
            </div>
        );
        return (
            <div className="search-results">

                <h3>Search Results {searchData.pageSize * searchData.page + 1}  - {Math.min(searchData.pageSize * (searchData.page + 1), searchData.totalCount || 9999999)} of {searchData.totalCount || "many"} for:</h3>
                <h2>"{searchData.query}"</h2>
                {buttons}
                <div className="results">
                    {
                        searchData.results.map(result => (
                            <div className="search-result" key={result.url}>
                                <AppLink to={result.url}>{result.title}</AppLink>
                                <RawHtml tagName="div" className="desc">{'...' + result.description}</RawHtml>
                            </div>)
                        )
                    }
                </div>
                {buttons}
            </div>
        );

    }
}