import React from "react";

import { labels } from "Util/Labels";
import { RawHtml } from "Util/RawHtml";

import { usePerfSelectPopup } from "./PerfSelect/usePerfSelectPopup";

import { Link } from "react-router-dom";
import { TessProduction } from "Tess/Data/TessInventory";
import { useTessContext } from "Tess/Data/TessContext";
import { ticketsBaseUrl } from "./TicketsApp";

export interface BuyTicketsButtonProps {
    baseUrl?: string,
    production?: TessProduction;
    className?: string;
}
/**
 * <BuyTicketsButton production={prod} />
 * or
 * <BuyTicketsButton prodNumber={prodNumber} />
 */
export const BuyTicketsButton: React.FC<BuyTicketsButtonProps> = (props) => {
    const { baseUrl = ticketsBaseUrl } = props;
    const { className = 'btn buy-tickets-button' } = props;
    const tessContext = useTessContext();

    const inventory = tessContext.inventory;

    const baseUrlForPopup = baseUrl;

    const perfSelectPopupHandler = usePerfSelectPopup(inventory, baseUrlForPopup, props.production);


    if (!inventory) {
        return <RawHtml className={`btn ${className} ${className}-loading`} tagName="span">{labels.getLabel('tickets.loadingButton')}</RawHtml>
    }
    else {
        const prod = props.production;

        if (prod && prod.isOnSale) {
            const text: any = props.children || labels.getLabel('tickets.buyTickets');
            const perfs = prod.performances.filter(p => p.isOnSale);

            const url = (perfs.length === 1 ? `${baseUrl}/${perfs[0].perfCode}` : `${baseUrl}/${prod.prodNumber}`)

            return (
                <Link to={url}
                    className={className}
                    onClick={perfs.length === 1 ? undefined : perfSelectPopupHandler}>{
                        typeof (text) === 'string' ? <RawHtml tagName="span">{text}</RawHtml> : text
                    }
                </Link>
            )
        }
    }
    return null;
}