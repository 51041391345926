import { useAppConfig } from "App/AppConfig";
import { Loading } from "App/Loading/Loading";
import React from "react"
import { AjaxService } from "Util/Ajax";
import './json-table-data.scss';

interface JsonTableDataPropTypes { dataUrl: string }

const getFieldColumns = (entries: any[]) => {
    const cols: Record<string, number> = {};
    const columns: string[] = [];
    let widths: number[] = [];
    entries.forEach(entry => {
        Object.keys(entry).forEach(key => {
            if (cols[key] === undefined) {
                cols[key] = columns.length;
                columns.push(key);
            }
            widths[cols[key]] = Math.min(20, Math.max(widths[cols[key]] || 0, String(entry[key]).length));
        })
    });
    const totalWidth = widths.reduce((x, y) => x + y, 0);
    widths = widths.map(n => n / totalWidth * 100);
    return { entries, columns, widths };
}

export const JsonTableData: React.FC<JsonTableDataPropTypes> = (props) => {
    const appConfig = useAppConfig();
    const ajaxService = React.useMemo(() => new AjaxService(appConfig.currUrl), [appConfig.currUrl]);
    const [tableData, setTableData] = React.useState<{ entries: any[], columns: string[], widths: number[] }>();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    React.useEffect(() => {
        ajaxService.fetchJson({ request: props.dataUrl, options: { credentials: 'omit' } }).then(
            entries => setTableData(getFieldColumns(entries))
        );
    }, [props.dataUrl, ajaxService])
    if (!tableData) {
        return <Loading />
    }
    const { entries, columns } = tableData;
    const setSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value);
    }
    const isSearchMatch = (entry: any) => {
        return !searchTerm.length || Object.values(entry).find((s: any) => String(s).match(new RegExp(searchTerm, "gi")));
    }
    const matchingEntries = entries.filter(isSearchMatch);
    return (
        <div className="json-table-data">
            <div className="row row-standard">
                <input type="text" placeholder="Search" onChange={setSearch} />
                {searchTerm && <span>&nbsp;({matchingEntries.length} matching entries)</span>}
            </div>
            <div className="table">
                <div className="table-head">
                    <div className="table-entry">
                        {columns.map((key, index) =>
                            <div key={key} className={`col col-${index}`} style={{ flexBasis: tableData.widths[index] + '%' }}>{key}</div>
                        )}
                    </div>
                </div>
                <div className="table-body">
                    {
                        entries.map((entry, rowIndex) =>
                            isSearchMatch(entry) ? (
                                <div key={rowIndex} className="table-entry">
                                    {
                                        columns.map((key, index) =>
                                            <div key={key} className={`col col-${index}`} style={{ flexBasis: tableData.widths[index] + '%' }}
                                                dangerouslySetInnerHTML={{ __html: (String(entry[key] || '')).replace('\n', '<br />') }}
                                            />
                                        )
                                    }
                                </div>)
                                : null
                        )
                    }
                </div>
            </div>
        </div>
    );
}