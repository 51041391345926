import { CmsPage } from "Cms/CmsPage";
import { AnimatePresence, motion } from "framer-motion";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as ReactScroll from 'react-scroll';
import { PopupProvider } from "Util//Popup/PopupContext";
import { GlobalMessages } from "Util/GlobalMessage/GlobalMessage";
import { Loading } from "App/Loading/Loading";
import { useGoogleAnalytics } from 'Util/useGoogleAnalytics';

const TestApp = lazy(() => import("Test/TestApp"));

// import { TicketsApp } from "../Tess/Tickets/TicketsApp";
// import { TessCartTimer } from "Tess/Cart/TessCartTimer";
// const UserApp = lazy(() => import("Tess/User/UserApp"));
// const CartApp = lazy(() => import("Tess/Cart/CartApp"));
// const PromoApp = lazy(() => import("Tess/Promo/PromoApp"));


export function AppRouter(props: any) {
    const mainFrame = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { listen } = useHistory();
    const [lastPath, setLastPath] = useState<string>(location.pathname);

    useGoogleAnalytics();
    useEffect(() => {
        return listen((location) => {
            if (location.pathname !== lastPath) {
                // console.log("scrolling", location.pathname, lastPath)
                ReactScroll.animateScroll.scrollToTop({ duration: 800, smooth: 'easeInOutCubic' });
            }
            setLastPath(location.pathname);
        });
    }, [listen, lastPath, location.pathname])


    // const loadComponent = (Component: any, props: Readonly<RouteProps>) => <Component {...props} />

    return (
        <div id="mainFrame" className="main-frame" ref={mainFrame}>
            <PopupProvider>
                {/* <TessCartTimer /> */}
                <GlobalMessages />

                <AnimatePresence>
                    <motion.div key={location.pathname} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <Suspense fallback={<Loading />}>
                            <Switch location={location}>
                                {/* <Route path="/cart/:action?" component={CartApp} key={location.pathname} />
                                <Route path="/user/:action?" component={UserApp} key={location.pathname} />
                                <Route path="/tickets/:id?" component={TicketsApp} key={location.pathname} />
                                <Route path="/promo/:promoCode?" component={PromoApp} key={location.pathname} /> */}
                                <Route path="/_test/:action?" component={TestApp} key={location.pathname} />
                                <Route path="*" component={CmsPage} key={location.pathname} />
                            </Switch>
                        </Suspense>
                    </motion.div>
                </AnimatePresence>
            </PopupProvider>
        </div>
    );
}