import React from 'react';

import { ContentTemplates, TContentTemplate } from './Pages/Index';
import { Loading } from '../App/Loading/Loading';
import { useCmsContext } from './Data/CmsContext';
import { CmsData } from './Data/CmsData';

type contentTemplatesType = typeof ContentTemplates;


interface CmsPageProps {
    pageData?: CmsData;
}

/*
    this fetches the content from the cms, by prepending /json/ to the page
    sets this.state = {id, url, title, contentType, properties, children}
    it then pulls the 'type' from this.state.type
 */
export const CmsPage: React.FC<CmsPageProps> = (props) => {
    const cmsContext = useCmsContext();
    const pageData = props.pageData || cmsContext;
    if (pageData.isLoading || !pageData.url) {
        return <Loading />;
    }
    else if (pageData.errorMessage) {
        return <div className="err">{pageData.errorMessage}</div>;
    }
    else {
        if (pageData.title) {
            if (pageData.title === 'TNEW') {
                document.title = document.title.replace('|', '\\');
            }
            else {
                document.title = `${pageData.title} \\ 5th Avenue Theatre`;
            }

        }
        const name = pageData.contentType as keyof contentTemplatesType;
        const Template: TContentTemplate = ContentTemplates[name] || ContentTemplates.defaultPage;
        try {
            return <Template {...pageData} />;
        }
        catch (e) {
            return (
                <>
                    <div className="err">There was an error displaying the content. </div>
                </>
            );
        }
    }
}