import React, { useState } from "react";

import './json-debug-info.scss';

import { useCmsContext } from "Cms/Data/CmsContext";
import { useTessContext } from "Tess/Data/TessContext";
import { AppRawHtml } from "App/AppRawHtml";

export const stringifyWithoutCircularReferences = (data: any) => {
  const usedValues: Array<any> = [];
  return data ? JSON.stringify(data, (key, value) => {
    if (typeof value === 'object') {
      if (usedValues.indexOf(value) !== -1) {
        return '{...}';
      }
      usedValues.push(value);
    }
    return value;
  }, '  ').replace(/</gi, '&lt;') : null;
}

export const JsonDebugInfo: React.FC = () => {

  const pageData = useCmsContext();
  const tessData = useTessContext();
  var entries = {
    'CMS': pageData,
    'INV': tessData.inventory,
    'SESS': tessData.tessSession,
    'CART': tessData.cart
  } as Record<string, any>;

  const [curr, setCurr] = useState<string | null>(null);
  const toggle = (key: string) => setCurr(curr === key ? null : key);

  return (
    <div className={`json-debug-info { curr ? ' open' : ''}`}>
      {curr && <pre><AppRawHtml>{stringifyWithoutCircularReferences(entries[curr])}</AppRawHtml></pre>}
      {Object.keys(entries).map(key => (
        <button key={key} onClick={() => toggle(key)}>{key}</button>)
      )}
    </div>
  );
};
