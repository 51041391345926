import React from 'react';
import { CmsData } from '../Data/CmsData';
import { Redirect as ReactRedirect } from 'react-router-dom';
import { useAppConfig } from 'App/AppConfig';
import { AppMain } from 'App/AppMain/AppMain';
import { GridSection } from 'Util/Grid/GridSection';
import { GridRow } from 'Util/Grid/GridRow';
import { GridArea } from 'Util/Grid/GridArea';
import { labels } from 'Util/Labels';
import { RawHtml } from 'Util/RawHtml';

export const Redirect: React.FC<CmsData> = (props) => {
    const { title, properties } = props;
    const appConfig = useAppConfig();
    let url = appConfig.mangleUrl(properties.redirectUrl?.url ?? '/');
    React.useState(setTimeout(() => document.getElementById("externalLinkIcon")?.click(), 2000));
    const { target } = properties.redirectUrl
    if (url.startsWith("/")) {
        return <ReactRedirect to={url} />
    }
    else if (target) {
        return (

            <AppMain
                hideBreadcrumbs={true}
                contentType={props.contentType}
                body={
                    <GridSection>
                        <GridRow className="row-standard">
                            <GridArea size={12}>
                                <RawHtml>{labels.getString('global.redirectNewWindow', {
                                    title,
                                    url,
                                    target
                                }).replace(/\/http/gi, 'http')}</RawHtml>
                            </GridArea>
                        </GridRow>
                    </GridSection>
                }
            />
        )
    }
    else {
        window.location.href = url;
        return null;
    }
}