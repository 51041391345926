import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router";

import { useAppConfig } from "App/AppConfig";
import { TessInventory, TessProduction } from "./TessInventory";
import { TessSession } from "./User";
import { TessService } from "./TessService";
import { AllWheelchairSeats } from "./WheelchairSeats";
import { Cart, LastViewedCartItem } from "./Cart";


import { FigureData } from "Util/FigureData";

export interface TessContext {
    tessService: TessService;
    tessSession?: TessSession;
    reloadSession: (arg?: { tessSession?: TessSession, cart?: Cart }) => void;

    inventory?: TessInventory;
    allWheelchairSeats?: AllWheelchairSeats;
    cart?: Cart;
    lastViewed?: LastViewedCartItem;
    setLastViewed: (item: LastViewedCartItem) => void;
}
export interface ITessContextCurrent {
    prod?: TessProduction;
    perf?: Performance;
}
export const ReactTessContext = React.createContext<TessContext>({
    tessService: new TessService({ url: '', isDisabled: false }),
    reloadSession: (arg?: { tessSession?: TessSession, cart?: Cart }) => { },
    setLastViewed: (item) => { }
});
export const useTessContext = () => useContext<TessContext>(ReactTessContext);

/// Does the work of loading the user tessSession and then the inventory. 
export const TessContextProvider: React.FC = (props) => {
    const { tessConfig } = useAppConfig();

    const tessService = useMemo(() => new TessService(tessConfig), [tessConfig]);


    const [tessSession, setTessSession] = useState<TessSession | undefined>(undefined);
    const [inventory, setInventory] = useState<TessInventory | undefined>(undefined);
    const [allWheelchairSeats, setAllWheelchairSeats] = useState<AllWheelchairSeats | undefined>(undefined);
    const [cart, setCart] = useState<Cart | undefined>(undefined);
    const [lastViewed, setLastViewedState] = useState<LastViewedCartItem | undefined>(JSON.parse(sessionStorage.getItem('lastViewed') || '{}'));
    const [invalidateSessionFlag, setInvalidateSessionFlag] = useState(false); // set to true to re-read Session


    const reloadSession = useCallback((arg?: { tessSession?: TessSession, cart?: Cart }) => {
        var newSession = arg?.tessSession;
        if (newSession && newSession !== tessSession) {
            sessionStorage.setItem("jwt", newSession?.jwt);
            if (JSON.stringify(newSession) !== JSON.stringify(tessSession)) {
                setTessSession(newSession);
            }
            setInvalidateSessionFlag(false);
            setCart(arg?.cart);
        }
        else if (!invalidateSessionFlag) {
            setInvalidateSessionFlag(true);
        }
    }, [invalidateSessionFlag, tessSession])

    const setLastViewed = (item: LastViewedCartItem) => {
        if (item.url !== lastViewed?.url) {
            console.log('lastCartItem', item);
            sessionStorage.setItem('lastViewed', JSON.stringify(item));
            setLastViewedState(item);
        }
    }
    useEffect(() => {
        let abortController: AbortController = new window.AbortController();
        if (tessConfig.isDisabled) {
            abortController.abort();
        }
        else if (!tessSession || invalidateSessionFlag) {
            tessService
                .fetchTessSession(abortController)
                .then(tessSession => {
                    tessSession && reloadSession({ tessSession });
                });
        }
        else if (!inventory || inventory.modeOfSale !== tessSession.modeOfSale) {
            if (tessSession.modeOfSale !== 0) {
                tessService.fetchTessInventory(tessSession)
                    .then(result => setInventory(result));
            }
        }
        else if (!allWheelchairSeats) {
            if (tessConfig.enableSyos) {
                // we're doing this just to fetch the svgs
                const facilities = new Set(inventory.prods.map(prod => prod.facility));
                facilities.forEach(facility => {
                    tessService.fetchSeatmap(facility)
                });
            }
            if (tessConfig.enableWheelchairSeats) {
                tessService.fetchWheelchairSeats()
                    .then(result => setAllWheelchairSeats(result));
            }
        }

        return () => abortController.abort();
    }, [allWheelchairSeats, cart, invalidateSessionFlag, inventory, reloadSession, tessService, tessSession, tessConfig.isDisabled, tessConfig.enableWheelchairSeats, tessConfig.enableSyos]);

    return (
        <ReactTessContext.Provider
            value={{
                tessService,
                reloadSession: reloadSession,
                tessSession,
                inventory,
                allWheelchairSeats,
                cart,
                lastViewed,
                setLastViewed
            }}>
            {props.children}
        </ReactTessContext.Provider>
    );
}

export function useLastViewedItem() {
    const { pathname } = useLocation();
    const { lastViewed, setLastViewed } = useTessContext();
    return {
        getLastViewed: () => lastViewed,
        setLastViewed: (title: string, text: string, image?: FigureData) => {
            if (pathname !== lastViewed?.url) {
                setLastViewed({
                    title,
                    text,
                    image,
                    url: pathname
                });
            }
        }
    }

}