import { PerfDetail, Zone, SeatRequest, PriceType } from "./TessInventory";
import { PerfWheelchairSeats } from "./WheelchairSeats";
import { labels } from "Util/Labels";

export enum SeatType {
    Standard = "Standard", Wheelchair = "Wheelchair", Companion = "Companion"
}

type PriceTypeSelection = Record<SeatType, Record<number, number>>;
const filterSeatTypes = (isWheelchair: boolean) => {
    return (isWheelchair)
        ? [SeatType.Wheelchair, SeatType.Companion]
        : [SeatType.Standard];
}
export interface ISeatSelection {
    perf?: PerfDetail;
    zone?: Zone;
    isWheelchair?: boolean;
    priceTypes?: PriceTypeSelection;
}
export class SeatSelection implements ISeatSelection {
    perf: PerfDetail;
    zone: Zone;
    isWheelchair: boolean;
    priceTypes: PriceTypeSelection;
    totalCount: number;
    totalPrice: number;

    constructor(perf: PerfDetail, zone: Zone, isWheelchair?: boolean, priceTypes?: PriceTypeSelection) {
        this.perf = perf;
        this.zone = zone;
        this.isWheelchair = isWheelchair === true;
        this.priceTypes = priceTypes
            ? priceTypes
            : {
                [SeatType.Standard]: {},
                [SeatType.Wheelchair]: {},
                [SeatType.Companion]: {}
            }
        this.totalCount = 0;
        this.totalPrice = 0;
        perf.priceTypes.forEach((priceType, index) => {
            filterSeatTypes(this.isWheelchair).forEach(seatType => {
                let num = this.priceTypes[seatType][priceType.id];
                if (num) {
                    this.totalCount += num;
                    this.totalPrice += num * this.zone.prices[index];
                }
            })
        })
    }
    with(replace: ISeatSelection) {
        return new SeatSelection(
            replace.perf || this.perf,
            replace.zone || this.zone,
            replace.isWheelchair !== undefined ? replace.isWheelchair : this.isWheelchair,
            replace.priceTypes || this.priceTypes
        )
    }
    withZone = (zone: Zone) => this.with({ zone: zone });
    withWheelchairSelected = (isWheelchair: boolean) => this.with({ isWheelchair: isWheelchair });
    withPriceTypes(seatType: SeatType, pt: PriceType, count: number) {
        const priceTypes = { ...this.priceTypes };
        priceTypes[seatType][pt.id] = count;
        return this.with({ isWheelchair: seatType !== SeatType.Standard, priceTypes });
    }
    private findBestZoneNumber() {
        let zoneNumber = this.zone.zoneNumbers[0] || 0;
        if (this.perf.availability) {
            var maxAvail = 0;
            var allAvailability = this.perf.availability;
            this.zone.zoneNumbers.forEach(z => {
                var avail = allAvailability[z];
                if (avail > maxAvail) {
                    zoneNumber = z;
                    maxAvail = avail;
                }
            })
        }
        return zoneNumber;
    }
    private buildPriceTypes(seatType: SeatType): number[] {
        return this.perf.priceTypes.flatMap(pt => {
            let num = this.priceTypes[seatType][pt.id];
            return (num) ? new Array<number>(num).fill(pt.id) : [];
        });
    }
    // returns null when there is no matching seatrequest 
    buildSeatRequest(wheelchairSeats?: PerfWheelchairSeats): SeatRequest {
        let priceTypes: number[] = (this.isWheelchair)
            ? this.buildPriceTypes(SeatType.Wheelchair).concat(this.buildPriceTypes(SeatType.Companion))
            : this.buildPriceTypes(SeatType.Standard);
        var request: SeatRequest = {
            perfNo: this.perf.perfNo,
            zone: this.findBestZoneNumber(),
            priceTypes
        }
        if (this.isWheelchair) {
            if (wheelchairSeats) {
                var seats = wheelchairSeats.findSeats(this);
                if (seats == null) {
                    request.errorMessage = labels.getString("performance.wheelchairFail");
                }
                else {
                    request.seats = seats.wheelchairs.concat(seats.companions);
                }
            }
            else {
                request.errorMessage = labels.getString("performance.wheelchairError");
            }
        }
        return request;
    }
}
