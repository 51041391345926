import { TessConfig } from "./TessConfig";
import { TessSession } from "./User";
import { TessInventory, TessPerformance, PerfDetail, SeatRequest, SeatResult, Zone } from "./TessInventory";
import { Convert } from "./Convert";
import { AllWheelchairSeats } from "./WheelchairSeats";
import { TessContext } from "./TessContext";
import { AjaxService, AjaxRequest } from "Util/Ajax";


/** 
 * if we have a tessSession, or if there's a JWT on the input, add the header
 * we'll skip the jwt if we set options.credentials to 'omit'
 */
export class TessService extends AjaxService {
    constructor(tessConfig: TessConfig,) {
        super(tessConfig.url);
        this.onBeforeSend(TessService.appendJwt);
    }
    private static appendJwt(input: AjaxRequest) {
        const options = input.options || {};
        if (input.options?.credentials !== 'omit') {
            const { headers } = options || {};
            const tessSession = input.tessSession as TessSession;
            const jwt = tessSession?.jwt || sessionStorage.getItem("jwt") as string;
            if (jwt) {
                options.headers = {
                    'Authorization': `Bearer ${jwt}`,
                    ...headers
                }
            }
            input.options = options;
        }
    }

    async fetchTessSession(abortController: AbortController): Promise<TessSession | null> {
        try {
            const data = await this.fetchJson({
                request: '/user',
                abortController,
                options: {
                    mode: 'cors',
                    credentials: 'include'
                }
            });
            var newSession = Convert.toTessSession(data);
            sessionStorage.setItem("jwt", newSession.jwt);
            return newSession;
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                throw err;
            } else {
                return null;
            }
        }
    }

    async updatePromoCode(tessContext: TessContext, promoCode: string) {
        const requestBody = JSON.stringify(promoCode);
        const abortController = new AbortController();
        const { tessSession, reloadSession } = tessContext;
        if (tessSession) {
            try {
                const data = await this.fetchJson({
                    request: `/user/promo`,
                    abortController,
                    tessSession,
                    options: {
                        method: 'post',
                        body: requestBody
                    }
                });
                const result = Convert.toTessSession(data);
                reloadSession({ tessSession: result });
                return result;
            }
            catch (e) {
                console.error(e);
            }
        }
        return tessContext.tessSession;
    }
    async fetchTessInventory(tessSession: TessSession): Promise<TessInventory> {
        const mos = tessSession.modeOfSale;
        const data = await this.fetchJson({
            request: `/tickets/productions?mos=${mos}`
        });
        const tessInventory = new TessInventory(mos, Convert.toProductionList(data));
        tessInventory.prods.forEach(prod => {
            prod.performances.forEach(perf => perf.production = prod);
        })
        return tessInventory;
    }
    async fetchPerfDetail(tessSession: TessSession, perf: TessPerformance, abortController: AbortController): Promise<PerfDetail | undefined> {
        let url = `/tickets/performance?perfNo=${perf.perfNo}&mos=${tessSession.modeOfSale}`;
        if (tessSession.promoCode) {
            url += `&sourceNo=${tessSession.promoCode.sourceNumber}`
        }
        const data = await this.fetchJson({
            request: url,
            abortController
        });
        const perfDetail = Convert.toPerfDetail({ ...perf, ...data });
        perfDetail.seatmapData = await this.fetchSeatmap(perfDetail.production.facility, abortController);

        return perfDetail;

    }

    async fetchSeats(tessSession: TessSession, perf: TessPerformance, zone: Zone, abortController: AbortController): Promise<Array<number>> {
        const data = await this.fetchJson({
            request: `/tickets/seats?perfNo=${perf.perfNo}&mos=${tessSession.modeOfSale}&` + zone.zoneNumbers.map(z => `zone=${z}`).join('&'),
            abortController
        });
        return Convert.toIntArray(data);
    }
    async fetchSeatmap(facility: string, abortController?: AbortController): Promise<string> {
        return await this.fetchText({
            request: `/tickets/seatmapData/?facility=${facility}`,
            abortController,
            options: {
                cache: 'default' // it still says no-cache even though this should indicate cach
            }
        });
    }
    private wheelchairSeats?: AllWheelchairSeats
    async fetchWheelchairSeats(): Promise<AllWheelchairSeats> {
        if (this.wheelchairSeats) {
            return Promise.resolve(this.wheelchairSeats);
        }
        const data = await this.fetchJson({
            request: `/tickets/wheelchairSeats`
        });
        return this.wheelchairSeats = new AllWheelchairSeats(Convert.toWheelchairSeats(data));
    }
    async fillSeatRequest(tessSession: TessSession, seatRequest: SeatRequest, abortController: AbortController): Promise<SeatResult> {
        const requestBody = JSON.stringify(seatRequest);
        console.log('fillSeatRequest', requestBody);
        try {
            const data = await this.fetchJson({
                request: `/tickets/reserve`,
                abortController,
                tessSession,
                options: {
                    method: 'post',
                    body: requestBody
                }
            });
            return Convert.toSeatResult(data);
        }
        catch (err) {
            console.error(err);
            return {
                success: false,
                message: err
            };
        }
    }


}
