import React from "react";
import './blockquote.scss';

interface Props {
    text: string;
    cite: string;
}

export const Blockquote: React.FC<Props> = ({ text, cite }) => {
    return (
        <div>
            <div className="bg-black blockquote">
                <blockquote>
                    <i className="fifth-icon-quote"></i>
                    <p>{text}</p>
                    {
                        cite
                            ? cite.indexOf('http') === 0
                                ? <cite><a href={cite} target="_blank" rel="noopener noreferrer">{cite}</a></cite>
                                : <cite>{cite}</cite>
                            : ''
                    }
                </blockquote>
            </div>
        </div>
    );
}
