import React from 'react';
import { CmsGrid } from 'Cms/Components/Grid/CmsGrid';
import { labels } from 'Util/Labels';
import { AppMain } from '../../App/AppMain/AppMain';
import { CmsData, PageStub } from '../Data/CmsData';

import { GridSection } from 'Util/Grid/GridSection';
import { GridRow } from 'Util/Grid/GridRow';
import { GridArea } from 'Util/Grid/GridArea';
import { Figure } from 'Cms/Components/Figure/Figure';
import { AppRawHtml } from 'App/AppRawHtml';


export const PressPhotos: React.FC<CmsData> = (props) => {
    const properties = props.properties;
    if (properties) {
        return (
            <AppMain
                contentType={props.contentType}
                hero={props.heroGrid ? <CmsGrid grid={props.heroGrid} /> : null}
                body={
                    <>
                        {props.bodyGrid ? <CmsGrid grid={props.bodyGrid} /> : null}
                        {props.childNodes
                            ? props.childNodes.map(g => <PressPhotosGroup {...g} key={g.id} properties={g.properties} />)
                            : labels.getHtml("press.noPhotosFound")}
                        {properties.bottomGrid ? <CmsGrid grid={properties.bottomGrid} /> : null}
                    </>
                }
            />
        );
    }
    else if (!props.id) {
        // loading... 
    }
    return (
        <div className="err">Content not found</div>
    );
}


interface PressPhotosGroupPropTypes extends PageStub {
    properties: {
        intro: string;
        pressPhotosImages: {
            key: string;
            contentType: "pressPhoto";
            properties: {
                caption: string;
                credit: string;
                image: {
                    src: string;
                    height: number;
                    width: number;
                    title: string;
                }
            }
        }[]
    };
}
export const PressPhotosGroup: React.FC<PressPhotosGroupPropTypes> = (props) => {
    return (
        <GridSection>
            <GridRow className='row-standard'>
                <GridArea><AppRawHtml tagName="h3">{props.properties.intro}</AppRawHtml></GridArea>
            </GridRow>
            <GridRow className='row-standard'>
                {props.properties.pressPhotosImages.map(img => (
                    <GridArea size={4} key={img.key}>
                        <Figure
                            {...img.properties.image}
                            src={img.properties.image.src + '?width=439&height=264'}
                        >

                            <p style={{ textAlign: 'center', marginTop: '4px' }}>
                                <a href={img.properties.image.src}
                                    style={{ width: '49%', marginRight: '1%' }}
                                    className="btn" target="_blank" rel="noopener noreferrer"><i className="fifth-icon-download"></i> Print</a>
                                <a href={`${img.properties.image.src}?width=1200`} className="btn"
                                    style={{ width: '49%', marginLeft: '1%' }}
                                    target="_blank" rel="noopener noreferrer"><i className="fifth-icon-download"></i> Web</a></p>
                            <p>{img.properties.caption}
                                {img.properties.credit ? <span><br />Photo Credit: {img.properties.credit}</span> : ''}</p>


                        </Figure>
                    </GridArea>
                ))}
            </GridRow>
        </GridSection>
    );
}