import React, { useContext } from "react";

import { AppRawHtml } from "App/AppRawHtml";
import './global-message.scss';
import { GlobalMessageList, GlobalMessageEntry } from "./Types";
import { GlobalMessageContext } from "./GlobalMessageContext";
import { AppLink } from "App/AppLink";



export const GlobalMessages: React.FC = (props) => {
    let { entries, remove } = useContext<GlobalMessageList>(GlobalMessageContext);
    return (
        <div className="global-messages">
            {entries.map(entry => (
                <GlobalMessage entry={entry} remove={remove} key={entry.id} />
            ))}
        </div>
    );
}

export const GlobalMessage: React.FC<{ entry: GlobalMessageEntry, remove?: (id: number) => void }> = ({ entry, remove }) => {
    return <div className={`message ${entry.messageType.toString()} ${entry.className ? entry.className : ''}`}>
        <div>
            {!!entry.allowClose && remove && <button className="close" onClick={_ => remove(entry.id)}><i className="fifth-icon-cancel" /></button>}
            {entry.children ? entry.children : (<>
                {entry.title && <AppRawHtml tagName="h3" className="full title">{entry.title}</AppRawHtml>}
                {entry.subTitle && <AppRawHtml tagName="h4" className="half sub-title">{entry.subTitle}</AppRawHtml>}
                {entry.body && <AppRawHtml className="half body">{entry.body}</AppRawHtml>}
                {entry.url && <AppLink to={entry.url}></AppLink>}
            </>)}
        </div>
    </div>;
}
