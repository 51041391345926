import React, { useState } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import { useTessContext } from "Tess/Data/TessContext";
import { Loading } from "App/Loading/Loading";
import { useModalService } from "Util/Modal/Types";
import { labels } from "Util/Labels";
import { DetailForm } from "./DetailsForm";
import { AddressForm } from "./AddressForm";
import { Button } from "Util/Forms/Button";
import { useTessUserService } from "./TessUserService";

interface RegisterFormPropTypes {
    email?: string;
    editEmail: () => void;
}
export const RegisterForm: React.FC<RegisterFormPropTypes & any> = (props) => {
    const methods = useForm();
    const tessContext = useTessContext();
    // const { bodyGrid } = useCmsContext();
    const modalService = useModalService();
    const tessUserService = useTessUserService();

    const [showDetailsForm, setShowDetailsForm] = useState(true);
    const [formData, setFormData] = useState({ email: props.email, country: '1', state: 'WA' });

    const handleBackClick = () => {
        setFormData({ ...formData, ...methods.getValues() });
        setShowDetailsForm(true);
    };

    const handleNextClick = methods.handleSubmit(async (data: any) => {
        setFormData({ ...formData, ...data });
        setShowDetailsForm(false);
    });

    const handleSubmitClick = methods.handleSubmit(async (data: any) => {
        const result = await tessUserService.register(tessContext, { ...formData, ...data });
        modalService.show(result?.isLoggedIn
            ? result.cartSummary.count > 0
                ? labels.getString("user.loginSuccess_cart")
                : labels.getString("user.loginSuccess_noCart")
            : labels.getString("user.registerFailed"));
    });

    return tessUserService ?
        <>
            {/* {bodyGrid && <CmsGrid grid={bodyGrid} />} */}
            <FormProvider {...methods}>
                {
                    showDetailsForm ?
                        <div className="register sign-up">
                            <DetailForm {...formData} editEmail={props.editEmail} />
                            <div className="buttons">
                                <Button className="btn" onClick={handleNextClick} validationErrors={methods.errors}>Next <i className="fifth-icon-right-arrow-small" /></Button>
                                <Button className="btn-clear" onClick={props.editEmail}><i className="fifth-icon-left-arrow-small" /> Back</Button>

                            </div>
                        </div> :
                        <div className="register address">
                            <AddressForm {...formData}>
                                <div className="buttons">
                                    <Button className="btn" onClick={handleSubmitClick} validationErrors={methods.errors}>{labels.getString("user.registerButton")} <i className="fifth-icon-right-arrow-filled" /></Button>
                                    <Button className="btn-clear" onClick={handleBackClick}><i className="fifth-icon-left-arrow-small" /> Back</Button>
                                </div>
                            </AddressForm>
                        </div>
                }
            </FormProvider>
        </> :
        <Loading />;
}