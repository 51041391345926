import React from "react";

function getClassName(size: number) {
    switch (size) {
        case 0: return 'col';
        case 3: return 'col-xs-6 col-md-3';
        default: return `col-xs-12 col-md-${size}`;
    }
}


interface AreaProps {
    size?: number; // number of twelfths to consume
    children: any;
}
export const GridArea: React.FC<AreaProps> = ({ size = 0, children }) =>
    <div className={getClassName(size)}>{children}</div>
