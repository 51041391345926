import React from "react"
import { GridSection } from "Util/Grid/GridSection";
import { GridRow } from "Util/Grid/GridRow";
import { GridArea } from "Util/Grid/GridArea";
import { useLocation } from "react-router";

import { SearchField } from "./SearchField";
import { SearchResult } from "./SearchResults";
import './search-results.scss';

interface SearchPropTypes { }
export const Search: React.FC<SearchPropTypes> = (props) => {
    const { search } = useLocation();
    const qs = new URLSearchParams(search);
    const query = qs.get("query") || '';

    const page = parseInt(qs.get("page") || '', 10) || 0;
    return (
        <GridSection>
            <GridRow className="row-standard">
                <GridArea size={12}>
                    <div>
                        <SearchField query={query} />
                    </div>
                </GridArea>
            </GridRow>
            <GridRow className="row-standard">
                <GridArea size={12}>
                    <div>
                        <SearchResult query={query} page={page} />
                    </div>
                </GridArea>
            </GridRow>
        </GridSection>
    );
}