import React from "react"
import { LoginForm } from "Tess/User/LoginForm";
import { GridArea } from "Util/Grid/GridArea";
import { GridRow } from "Util/Grid/GridRow";
import { GridSection } from "Util/Grid/GridSection";
import { RawHtml } from "Util/RawHtml";
import { ProtectedContentProps } from "./ProtectedContentLoader";
import { SecretCodeForm } from "./SecretCodeForm";
import { OrderNumberForm } from "./OrderNumberForm";

export const NotLoggedIn: React.FC<ProtectedContentProps> = (props) => {
    const { secretCode, tessituraConstituency } = props.properties;
    return (
        <GridSection className="protected-page not-logged-in">
            <GridRow className="row-standard">
                <GridArea size={12}>
                    <div>
                        <h1>{props.properties.notLoggedInTitle}</h1>
                        {
                            props.error
                                ? <RawHtml className="err">{props.error}</RawHtml>
                                : <RawHtml>{props.properties.intro}</RawHtml>
                        }
                    </div>
                </GridArea>
            </GridRow>
            <GridRow className="row-standard with-padding">
                {tessituraConstituency &&
                    <GridArea size={6}>
                        <div>
                            <LoginForm allowRegister={false} showModal={false} />
                        </div>
                    </GridArea>
                }
                {secretCode &&
                    <GridArea size={6}>
                        <div>
                            <SecretCodeForm {...props} />
                        </div>
                    </GridArea>
                }
            </GridRow>
            {tessituraConstituency &&
                <OrderNumberForm {...props} />
            }
        </GridSection>
    );
}
