import React from "react"
import { AppRawHtml } from "App/AppRawHtml";
import { Button } from "Util/Forms/Button";
import { useModalService } from "Util/Modal/Types";
import { SeatRequest } from "Tess/Data/TessInventory";
import { labels } from "Util/Labels";
import { ISyosContext } from "./Types";
interface SeatTypeValidationPropTypes {
    children: string;
}
const SeatTypeValidation: React.FC<SeatTypeValidationPropTypes> = (props) => {
    const modalService = useModalService();
    return (
        <div className="seat-type-validation">
            <AppRawHtml>{props.children}</AppRawHtml>
            <p className="modal-buttons">
                <Button className="btn btn-clear" onClick={() => { modalService.setResult(false); return false; }}>Cancel</Button>
                <Button className="btn" onClick={() => { modalService.setResult(true); return false; }}>Continue</Button>
            </p>
        </div>
    );
}

// this returns a function that returns a promise
// the promise returns the request if the seat types are validated
// the promise returns null if the seat types are not validated
export const useSeatTypeValidation = (syos: ISyosContext) => {
    const modalService = useModalService();
    return (request: SeatRequest) => {
        const seatTypeLookup: Record<string, string> = labels.getObject("performance.seatTypeValidation");
        // capture any seat types
        const seatTypes = Array.from(
            new Set<string>(
                syos.selectedSeats
                    .map(seat => seat.seatType.split('_').pop())
                    .filter(s => typeof s === 'string')
                    .map(s => seatTypeLookup[String(s)])

            ).values()).join('');

        if (seatTypes.length) {
            return modalService.show({
                key: 'seatTypeValidation',
                body: <SeatTypeValidation>{seatTypes}</SeatTypeValidation>
            }).then(resp => {
                if (resp.result) {
                    return request;
                }
                syos.clearSelectedSeats();
                return null;
            })
        }
        return Promise.resolve(request);
    }
}