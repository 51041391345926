import React, { useRef, useEffect, useState } from "react";
import { Figure, FigureData } from '../Figure/Figure';

import './page-hero.scss';
import { AppRawHtml } from "App/AppRawHtml";
import { useCmsContext } from "Cms/Data/CmsContext";
import { useWindowEventListener } from "Util/useEventListener";

interface PropTypes {
    title: string;
    subTitle?: string;
    image: FigureData
}

export default function PageHero(props: PropTypes) {
    const cmsPageData = useCmsContext();
    let { image, title, subTitle } = props;
    let figureRef = useRef<HTMLElement>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    let [graphicWidth, setGraphicWidth] = useState<number>(0);
    const setPosition = () => {
        const imgNode = figureRef.current?.querySelector("img");

        if (figureRef.current && imgNode && image) {
            if (!isLoaded) {
                if (!imgNode.clientHeight) {
                    imgNode.onload = () => setPosition();
                }
                else {
                    setIsLoaded(true);
                }
            }
            else {
                if (graphicWidth === 0) {
                    setGraphicWidth(imgNode.clientWidth);
                }
                const focalPoint = image.focalPoint?.left || 0.5;
                let imgWidth = graphicWidth;
                let screenWidth = figureRef.current.clientWidth;

                if (screenWidth < 1200) {
                    imgWidth = graphicWidth * screenWidth / 1200;
                    imgNode.style.width = `${imgWidth}px`;
                }
                else {
                    imgNode.style.width = 'initial';
                }

                // this centers the graphic in the screen
                let gl = (screenWidth - imgWidth) / 2;

                // this centers the focal area in the screen
                let fl = (screenWidth / 2 - focalPoint * imgWidth);

                let left = gl + ((imgWidth - screenWidth) / imgWidth) * fl;
                imgNode.style.left = `${left}px`;
            }
        }
    }

    useWindowEventListener('resize', setPosition);
    useEffect(setPosition, [isLoaded, image, setPosition]);
    return (
        <div className="page-hero">
            <Figure {...image} ref={figureRef}>
                <figcaption>
                    <AppRawHtml tagName="h1">{title || cmsPageData?.title}</AppRawHtml>
                    {subTitle && <AppRawHtml tagName="p" className="sub-title">{subTitle}</AppRawHtml>}
                </figcaption>
            </Figure>
        </div >
    );
}
